import "./loadingSpinner.scss";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <span />
    </div>
  );
};
export default LoadingSpinner;
