import { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import classNames from "classnames";
import DatePicker from "react-datepicker";
import { Editor } from "@toast-ui/react-editor";
import { ko } from "date-fns/esm/locale";
import { PageTop, CheckBox, SelectBox, InputBox, EditorComponent, NormalButton } from "components/index";
import { RootState, PathState } from "store/index";
import { boardTitle } from "shared/utils";
import { useSelect, useCheckbox } from "hooks/index";
import useAddNotice from "hooks/board/notice/useAddNotice";

const MINIMUM_TITLE_LENGTH = 2;

const NOTICE_CATEGORY = [
  { id: "NOTICE", name: "공지" },
  { id: "MAINTENANCE", name: "점검" },
  { id: "MARKET", name: "상점" },
];

const NoticeWrite = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { addNotice } = useAddNotice();
  const editorRef = useRef<Editor>(null);
  const path: PathState = useSelector((state: RootState) => state.path);
  const [title, setTitle] = useState("");

  const isFixed = useCheckbox(false);
  const isExposed = useCheckbox(false);
  const [assignedDate, setAssignedDate] = useState(new Date());

  const selectData = useSelect(NOTICE_CATEGORY[0]);

  const handleOnSubmit = () => {
    if (title.length < MINIMUM_TITLE_LENGTH) {
      alert("제목은 두글자 이상이어야 합니다.");
      return;
    }
    const content = editorRef.current?.getInstance().getHTML();
    if (!content) {
      alert("내용을 입력하세요.");
      return;
    }
    addNotice({
      title: title,
      content,
      isFixed: isFixed.value,
      assignedAt: assignedDate.getTime(),
      category: selectData.value.id,
      isExposed: !isExposed.value,
    });
    navigate(-1);
  };

  return (
    <div className={classNames("write", path.nowPath)}>
      <div className="write__inner">
        <PageTop title={boardTitle(path.nowPath) + (location?.state ? " 수정" : " 등록")} />
        <div className="board">
          <div className="board__title">
            {path.nowPath === "notice" && (
              <SelectBox name="category" data={NOTICE_CATEGORY} selectData={selectData} classification />
            )}
            <InputBox
              type="text"
              name="title"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(e.target.value);
              }}
              placeholder="제목을 입력해주세요. (최대  50자 이내)"
            />
          </div>
          <div className="board__title--bottom">
            <DatePicker
              selected={assignedDate}
              onChange={(date: any) => setAssignedDate(date)}
              locale={ko} // 한글로 변경
              dateFormat="yyyy-MM-dd aa hh:mm" // 시간 포맷 변경
              showPopperArrow={false} // 화살표 변경
              showTimeInput
            />
            <div className="right">
              <div className="check_box">
                <CheckBox
                  name="noti_list"
                  value={isFixed.value}
                  onClick={isFixed.onClick}
                  size="sm"
                  label="리스트 상단 고정"
                />
                <p className="title">공지 (리스트 상단 고정)</p>
              </div>
              <div className="check_box">
                <CheckBox
                  name="show_list"
                  value={isExposed.value}
                  onClick={isExposed.onClick}
                  size="sm"
                  label="미노출"
                />
                <p className="title">미노출</p>
              </div>
            </div>
          </div>
          <div className="board__body--editor">
            <EditorComponent editorRef={editorRef} />
          </div>
        </div>
        <div className="write__bottom">
          <NormalButton
            title="취소"
            hierarchy="secondary_gray"
            onClick={() => {
              navigate(-1);
            }}
            buttonStyle="box"
            size="md"
          />
          <NormalButton
            title={location?.state ? "수정" : "등록"}
            hierarchy="primary"
            buttonStyle="box"
            size="md"
            onClick={() => {
              handleOnSubmit();
              if (location.state?.id) {
                // dispatch(
                //   modalSetting({
                //     open: !modal.open,
                //     type: "success",
                //     title: "수정하기",
                //     description: "정말로 수정 하시겠습니까?",
                //     confirmButton: "수정하기",
                //     confirmStyle: "primary",
                //     onConfirm: EmptyValueWarning,
                //   })
                // );
              } else {
                // dispatch(
                //   modalSetting({
                //     open: !modal.open,
                //     type: "success",
                //     title: "등록하기",
                //     description: "정말로 등록 하시겠습니까?",
                //     confirmButton: "등록하기",
                //     confirmStyle: "primary",
                //     onConfirm: EmptyValueWarning,
                //   })
                // );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NoticeWrite;
