import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { queryKeys } from "react-query/querykey";
import { Event } from "types/board";

// 이벤트 게시판 디테일
function getEventDetail(eventId: string) {
  return axios.get<{ data: Event }>(`/event/detail?eventId=${eventId}`);
}

const useEventDetail = () => {
  const location = useLocation();
  const pageId = location?.state.content?.id;
  const queryKey = [queryKeys.eventList, pageId];

  const { data: eventContent, error } = useQuery({
    queryKey,
    queryFn: () => getEventDetail(pageId),
    select: (data) => data.data.data,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("관리자 계정 리스트를 가져오는데 에러가 발생하였습니다.");
  }

  return { eventContent, error };
};

export default useEventDetail;
