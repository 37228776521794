import { NormalButton } from "components/index";

type FileUploadButtonType = {
  saveFileImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  uploading: boolean;
};

const FileUploadButton = ({ saveFileImage, name, uploading }: FileUploadButtonType) => {
  return (
    <div className="demo-file-list">
      <label htmlFor="file">
        <NormalButton title={name} size="md" hierarchy="primary" buttonStyle="box" width="120px" />
      </label>
      <input className="hide" type="file" onChange={saveFileImage} id="file" />
      {uploading && <span className="uploading">업로드 중입니다.</span>}
    </div>
  );
};

export default FileUploadButton;
