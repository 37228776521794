import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";
import { NoticeCategory, Notice, SearchCategory } from "types/board";

type Param = {
  page: number;
  limit: number;
  category: NoticeCategory;
  keyword: string;
  searchCategory: SearchCategory;
};

type GetNotice = {
  data: { list: Notice[]; totalCount: number };
};

// 공지사항 게시판 목록
function getNotice(queryString: string) {
  return axios.get<GetNotice>(`/notice?${queryString}`);
}

const getQueryString = ({ page, limit, category, keyword, searchCategory }: Param) => {
  let queryString = `page=${page}&limit=${limit}`;

  if (category !== "ALL") {
    queryString += `&category=${category}`;
  }

  if (keyword) {
    switch (searchCategory) {
      case "TITLE":
        queryString += `&title=${keyword}`;
        break;
      case "CONTENT":
        queryString += `&content=${keyword}`;
        break;
      case "TITLE+CONTENT":
        queryString += `&title=${keyword}&content=${keyword}`;
        break;
    }
  }
  return queryString;
};

const useNotice = ({ page, limit, category, keyword, searchCategory }: Param) => {
  let queryKey = [queryKeys.noticeList, category, page];
  const queryString = getQueryString({ page, limit, category, keyword, searchCategory });

  if (keyword) {
    queryKey = [queryKeys.noticeList, category, page, `${searchCategory}=${keyword}`];
  }

  const { data, error, refetch } = useQuery({
    queryKey,
    queryFn: () => getNotice(queryString),
    enabled: false,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("공지사항 리스트를 가져오는데 에러가 발생하였습니다.");
  }

  const noticeList = data?.data.data.list;
  const totalPost = data?.data.data.totalCount;

  return { noticeList, totalPost, error, refetch };
};

export default useNotice;
