import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NormalButton } from "components/common";
import { dateToString } from "shared/index";
import Pagination from "components/common/pagination/Pagination";
import EmptyList from "./EmptyList";
import { isNewPost } from "../utils";
import { Update } from "types/board";
import "./BoardList.scss";

type UpdateListType = {
  updateList: Update[];
  totalPost: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const PAGINATION = {
  pageRange: 5,
  btnRange: 10,
};

const UpdateList = ({ updateList, totalPost, page, setPage }: UpdateListType) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {updateList?.length > 0 &&
        updateList.map((post) => (
          <div
            key={post?.id}
            className={classNames("board_list", "update", { detail: post?.detail })}
            onClick={() => {
              navigate(`/news/update/detail`, { state: { id: post?.id } });
            }}
          >
            {post?.thumbnailImageUrl && <img src={`${post?.thumbnailImageUrl}`} alt="img" />}

            <div className="board_list__box">
              <div className="top">
                <p className="title">{post?.title}</p>
                {isNewPost(post?.assignedAt) && <span className="new-post" />}
              </div>

              <div className="box">
                <p className="description">{post?.summary}</p>
              </div>

              <div className="bottom">
                <p className="update_date update-badge">
                  <span>업데이트</span>
                  {dateToString(post?.createdAt, "yyyy.MM.dd", i18n.language)}
                </p>
                <p className="date">
                  <span>작성일</span>
                  {dateToString(post?.assignedAt, "yyyy.MM.dd", i18n.language)}
                </p>
                <p className="view">
                  <span>조회수</span>
                  {post?.views}
                </p>
              </div>
            </div>
            <div
              className="buttons_box"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <NormalButton
                title="수정"
                hierarchy="primary_line"
                size="xs"
                buttonStyle="box"
                onClick={() => {
                  navigate(`/news/update/update`, { state: { id: post?.id } });
                }}
              />
              <NormalButton title="삭제" hierarchy="secondary_gray" size="xs" buttonStyle="box" onClick={() => {}} />
            </div>
          </div>
        ))}
      {updateList?.length > 0 && (
        <div className="paging-wrap">
          <Pagination
            page={page}
            setPage={setPage}
            totalPost={totalPost}
            btnRange={PAGINATION.btnRange}
            pageRange={PAGINATION.pageRange}
          />
        </div>
      )}
      {updateList?.length === 0 && <EmptyList />}
    </>
  );
};

export default UpdateList;
