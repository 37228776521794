import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";
import { User } from "types/account";

// 계정 정보
export function getUser() {
  return axios.get<{ data: User }>("/adminUser/myInfo");
}

const useUser = () => {
  const queryClient = useQueryClient();
  const queryKey = [queryKeys.user];

  const { data: user, error } = useQuery({
    queryKey,
    queryFn: () => getUser(),
    select: (user) => user?.data.data,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("user 데이터를 가져오는데 에러가 발생하였습니다.");
  }

  function clearUser() {
    queryClient.removeQueries();
  }

  return { user, clearUser };
};

export default useUser;
