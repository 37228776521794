import { axios, ResponseDataType } from "apis/http";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { queryKeys } from "react-query/querykey";
import { LinkItem } from "types/demo";

const putDemoList = (list: LinkItem) => {
  console.log(list);
  return axios.put<any, null>("/demo/update/video", list);
};

const useUpdateDemoList = () => {
  const queryKey = [queryKeys.demo, "VIDEO"];
  const queryClient = useQueryClient();

  const { mutate: updateList } = useMutation({
    mutationFn: (list: any) => putDemoList(list),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        const errorType = error.response?.data.code;
        console.error(errorType, error);
      }
    },
  });

  return { updateList };
};

export default useUpdateDemoList;
