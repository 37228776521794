import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { axios as axiosInstance } from "apis/http";
import { ConfirmModal, AlertModal, LoadingModal } from "components/index";
import ModalPortal from "portals/ModalPortal";
import { FileUploadButton, FileList } from "components/demo";
import useDemoList from "hooks/demo/useDemoList";
import useDeleteList from "hooks/demo/useDeleteList";
import { fileUuid } from "shared/utils/uuid";
import "./demo.scss";

const MAX_FILE_LENGTH = 10;

const DemoFilePage = () => {
  const [uploading, setUploading] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { links: list = [], refetch: refetchList } = useDemoList("APK");
  const [confirmModal, setConfirmModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [modalText, setModalText] = useState<string[]>([""]);
  const { deleteLink } = useDeleteList({ refetchList, setAlertModal, setConfirmModal, setModalText });

  const handleOnDelete = (id: string) => {
    deleteLink({ linkId: id });
  };

  useEffect(() => {
    refetchList();
  }, [list]);

  // 순차적으로 동기처럼 react query를 다루기엔 아직 무리가 있나보다.. 나중에 리펙토링 해보기
  const saveFileImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (list.length > MAX_FILE_LENGTH) {
      setConfirmModal(true);
      setModalText(["등록 실패", "최대 등록할 수 있는 파일은 10개입니다."]);
      return;
    }

    const files = (e.target as HTMLInputElement).files;
    if (!files) return;
    const file = files[0];
    if (file.name.length > 30) {
      alert("파일명은 30글자를 넘지 말아야 합니다.");
      return;
    }
    setUploading(true);

    const id = `${fileUuid()}.apk`;
    await axiosInstance.get(`/demo/upload/url/APK?fileName=${id}`).then(({ data }) => {
      const pdfUrl = data?.data;
      axios
        .put(pdfUrl, file, { headers: { "Content-Type": "application/vnd.android.package-archive" } })
        .then(() => {
          const title = file?.name;
          const url = pdfUrl.substring(0, pdfUrl.indexOf("?"));
          axiosInstance.post(`/demo/upload/complete/APK`, { title, url }).then(() => {
            refetchList();
            setAlertModal(true);
            setModalText(["업로드가 완료 되었습니다"]);
          });
        })
        .catch((e) => {
          console.error("Image upload failed:", e);
          setConfirmModal(true);
          setModalText(["등록 실패", "DEMO 파일을 등록하지 못했습니다."]);
        })
        .finally(() => {
          setUploading(false);
        });
    });
  };

  return (
    <div className="demo">
      <h1 className="title">Demo 파일 관리</h1>
      <FileUploadButton saveFileImage={saveFileImage} name="APK 파일 등록하기" uploading={uploading} />
      <FileList list={list} handleOnDelete={handleOnDelete} />
      {alertModal && (
        <ModalPortal>
          <AlertModal modalRef={modalRef} setIsModalOpen={setAlertModal} text={modalText[0]} />
        </ModalPortal>
      )}

      {uploading && (
        <ModalPortal>
          <LoadingModal text="파일을 저장 중입니다. 잠시만 기다려주세요." />
        </ModalPortal>
      )}

      {confirmModal && (
        <ModalPortal>
          <ConfirmModal
            modalRef={modalRef}
            type="info"
            title={modalText[0]}
            description={modalText[1]}
            confirmButton="확인"
            setIsModalOpen={setConfirmModal}
            confirmStyle="primary"
            handleOnConfirm={() => setConfirmModal(false)}
          />
        </ModalPortal>
      )}
    </div>
  );
};

export default DemoFilePage;
