import Icon from "shared/Icon";
import "./alertModal.scss";

type AlertModalType = {
  modalRef: React.ForwardedRef<HTMLDivElement>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
};

const AlertModal = ({ modalRef, setIsModalOpen, text }: AlertModalType) => {
  return (
    <div
      className="backdrop dark-backdrop"
      ref={modalRef}
      onClick={() => {
        setIsModalOpen(false);
      }}
    >
      <div
        className="demo-alert-modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Icon className="icon-check" icon="check" color="#12B76A" size={20} />
        <span>{text}</span>
        <div className="icon-x" onClick={() => setIsModalOpen(false)}>
          <Icon icon="x" color="#344054" size={20} />
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
