import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { UpdateList } from "components/board/index";
import { PageTop, NormalButton } from "components/index";
import useUpdate from "hooks/board/update/useUpdate";
import Search from "components/board/Search";
import { useSelect } from "hooks/index";
import useDebounce from "hooks/useDebounce";

// TODO: 나중에 api 보내달라고 요청하기
const SEARCH_CATEGORY = [
  { id: 2023, name: "2023" },
  { id: 2022, name: "2022" },
  { id: 2021, name: "2021" },
];

const DEBOUNCE_DELAY = 500;

const PAGINATION = {
  pageRange: 5,
  btnRange: 10,
};

const UpdateListPage = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1); // 현재 페이지
  const keywordType = useSelect(SEARCH_CATEGORY[0]);
  const {
    updateList = [],
    totalPost = 0,
    refetch,
  } = useUpdate({
    page,
    limit: PAGINATION.pageRange,
    year: keywordType.value.id,
    keyword,
  });

  const submitKeywords = () => {
    setPage(1);
    refetch();
  };

  useEffect(() => {
    refetch();
  }, [page, keywordType.value]);

  useDebounce(keyword, DEBOUNCE_DELAY, submitKeywords);

  return (
    <div className={classNames("content", "update")}>
      <div className="content__inner">
        <PageTop title="업데이트" />
        <div className="board">
          <div className="board__top update">
            <div className="right">
              <Search categoryList={SEARCH_CATEGORY} keywordType={keywordType} setKeyword={setKeyword} />
            </div>
          </div>
          <div className="board__body">
            <UpdateList updateList={updateList} totalPost={totalPost} page={page} setPage={setPage} />
          </div>
          <div className="board__bottom">
            <NormalButton
              title="등록"
              size="md"
              hierarchy="primary"
              icon="false"
              buttonStyle="box"
              onClick={() => {
                navigate(`/news/update/write`);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateListPage;
