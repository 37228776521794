import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { RootState } from "store/index";
import { zemiMenu } from "shared/config/router/path";
import Icon from "shared/Icon";
import "./Sidebar.scss";
import { NavMenus } from "types/path";

// TODO: Link to 로 바꿔야 하지 않을까?

const getIconType = (type: number): string => {
  const ICON_TYPE: { [key: number]: string } = {
    1: "brush-fill", // 스튜디오 관리
    3: "globe2-fill", // 월드 관리
    4: "file-text-fill", // 게시판 관리
    5: "coins-fill", // 매출 정산 관리
    6: "people-fill", // 계정 관리
    8: "file-text", // 계정 관리
  };
  return ICON_TYPE[type] || "people-fill"; // default icon 임시 지정
};

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItems, setActiveItems] = useState<number[]>([]);
  const gnb = useSelector((state: RootState) => state.gnb);
  const [navMenus] = zemiMenu.filter((menu) => menu.id === gnb.nowPage);
  const ref = useRef<number[]>([]);

  const getSelectedId = (menus: NavMenus[]) => {
    menus.forEach((menu) => {
      if (menu.link === location.pathname) {
        ref.current.push(menu.id);
        return;
      }
      if (menu?.children) {
        getSelectedId(menu.children);
      }
    });
  };

  const setActiveNav = () => {
    getSelectedId(zemiMenu);
    const selectedId = ref.current.toString();
    let result: (number | string)[] = [];

    switch (selectedId.length) {
      case 1:
        result = [`${selectedId}0`, `${selectedId}00`];
        break;
      case 2:
        result = [selectedId, `${selectedId}0`];
        break;
      case 3:
        result = [selectedId.slice(0, 2), selectedId];
        break;
      case 4:
        result = [selectedId.slice(0, 2), selectedId.slice(0, 3)];
        break;
    }
    setActiveItems(result.map(Number));
    ref.current = [];
  };

  useEffect(() => {
    if (location.pathname === "/") return;
    setActiveNav();
  }, [location]);

  const handleNavClick = ({ link }: { link: string }) => {
    setActiveNav();
    navigate(link);
  };

  const navContent = navMenus?.children.map((navMenu: NavMenus) => {
    return (
      <ul className="side-list" key={navMenu.id}>
        <li
          className={`title-list ${activeItems?.includes(navMenu.id) && "active"}`}
          onClick={() => {
            // if (navMenu.id === 41) {
            // navigate(`/cs/faq`);
            // setActiveItems([navMenu.id]);
            // } else {
            handleNavClick({ link: navMenu.link });
            // }
          }}
        >
          <span>{navMenu.title}</span>
        </li>
        <ul className="subtitle-list">
          {navMenu?.children &&
            navMenu?.children.map((subtitle) => (
              <li
                className={activeItems?.includes(subtitle.id) ? "active" : ""}
                onClick={() => {
                  if (subtitle.id === 411 || subtitle.id === 420 || subtitle.id === 421 || subtitle.id === 422) {
                    alert("개발중입니다.");
                  } else {
                    handleNavClick({ link: subtitle.link });
                  }
                }}
                key={subtitle.id}
              >
                <Icon className="corner-down-right" icon="corner-down-right" size={10} color="#667085" />
                <span>{subtitle.title}</span>
              </li>
            ))}
        </ul>
      </ul>
    );
  });

  return (
    <div className="sidebar">
      <div className="top">
        <div className="icon-wrapper">
          <Icon className={getIconType(navMenus?.id)} icon={getIconType(navMenus?.id)} size={24} color="#FFFFFF" />
        </div>
        <p className="main-title">{navMenus?.title}</p>
      </div>
      <nav className="side-nav">{navContent}</nav>
    </div>
  );
};

export default Sidebar;
