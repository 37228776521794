import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { queryKeys } from "react-query/querykey";
import { Notice } from "types/board";

// 공지 사항 detail
function getNoticeDetail(noticeId: string) {
  return axios.get<{ data: Notice }>(`/notice/detail?noticeId=${noticeId}`);
}

const useNoticeDetail = () => {
  const location = useLocation();
  const pageId = location?.state?.id;
  const queryKey = [queryKeys.noticeList, pageId];

  const { data: noticeContent, error } = useQuery({
    queryKey,
    queryFn: () => getNoticeDetail(pageId),
    enabled: !!pageId,
    select: (data) => data.data.data,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("공지사항 게시판 디테일을 가져오는데 에러가 발생하였습니다.");
  }

  return { noticeContent, error };
};

export default useNoticeDetail;
