const boardTitle = (nowPath: string) => {
  switch (nowPath) {
    case "notice":
      return "공지사항";
    case "update":
      return "업데이트";
    case "event":
      return "이벤트";
    case "free-board":
      return "자유게시판";
    case "tip-board":
      return "팁 공유 게시판";
    case "screenshot-board":
      return "스크린샷 게시판";
    case "inquiry":
      return "1:1문의";
    case "list":
      return "회원 관리";
    case "report":
      return "신고내역";
    default:
      return "none";
  }
};

const boardCategory = (nowPath: string) => {
  switch (nowPath) {
    case "notice":
      return [
        { id: 0, title: "전체" },
        { id: 1, title: "공지" },
        { id: 2, title: "점검" },
        { id: 3, title: "상점" },
      ];
    case "update":
      return [];
    case "event":
      return [
        { id: 0, title: "전체" },
        { id: 1, title: "진행" },
        { id: 2, title: "예정" },
        { id: 3, title: "종료" },
      ];
    case "free-board":
      return [
        { id: 0, title: "전체" },
        { id: 1, title: "최신순" },
        { id: 2, title: "인기순" },
        // { id: 3, title: "댓글수" },
      ];
    case "tip-board":
      return [
        { id: 0, title: "전체" },
        { id: 1, title: "최신순" },
        { id: 2, title: "인기순" },
        // { id: 3, title: "댓글수" },
      ];
    case "screenshot-board":
      return [
        { id: 0, title: "전체" },
        { id: 1, title: "최신순" },
        { id: 2, title: "인기순" },
        // { id: 3, title: "댓글수" },
      ];
    case "inquiry":
      return [
        { id: 0, title: "전체" },
        { id: 1, title: "문의" },
        { id: 2, title: "버그 제보" },
        { id: 3, title: "신고" },
      ];
    case "faq":
      return [
        { id: 0, title: "전체" },
        { id: 1, title: "ZEMIVERSE" },
        { id: 2, title: "Studio" },
      ];
    case "list":
      return [];
    case "report":
      return [];
    default:
      return [];
  }
};

const boardHeader = (nowPath: string) => {
  switch (nowPath) {
    case "notice":
      return [
        { id: 0, title: "순서" },
        { id: 1, title: "구분" },
        { id: 2, title: "제목" },
        { id: 3, title: "작성일" },
        { id: 4, title: "조회수" },
      ];
    case "update" || "event" || "screenshot-board" || "faq":
      return [];
    case "free-board" || "tip-board":
      return [
        { id: 0, title: "순서" },
        { id: 1, title: "제목" },
        { id: 2, title: "작성자" },
        { id: 3, title: "작성일" },
        { id: 4, title: "댓글수" },
        { id: 5, title: "좋아요" },
        { id: 6, title: "신고" },
        { id: 7, title: "조회수" },
      ];
    case "inquiry":
      return [
        { id: 0, title: "순서" },
        { id: 1, title: "구분" },
        { id: 2, title: "제목" },
        { id: 3, title: "작성자" },
        { id: 4, title: "작성일" },
        { id: 5, title: "처리상태" },
      ];
    case "list":
      return [
        { id: 0, title: "순서" },
        { id: 1, title: "E-mail" },
        { id: 2, title: "소셜" },
        { id: 3, title: "닉네임" },
        { id: 4, title: "계정 연동" },
        { id: 5, title: "연락처" },
        { id: 6, title: "최종 접속일" },
        { id: 7, title: "접속 횟수" },
        { id: 8, title: "상태" },
      ];
    case "report":
      return [
        { id: 0, title: "순서" },
        { id: 1, title: "구분" },
        { id: 2, title: "신고 원문" },
        { id: 3, title: "작성자" },
        { id: 4, title: "신고 횟수" },
        { id: 5, title: "처리상태" },
      ];
    default:
      return [];
  }
};

export { boardTitle, boardCategory, boardHeader };
