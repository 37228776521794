import classNames from "classnames";

import "./ContainerFooter.scss";

type SnsButtonProps = {
  title: string;
  url: string;
};

const SnsButton = ({ title, url }: SnsButtonProps) => {
  return (
    <li
      className={classNames("sns_button", title)}
      onClick={() => {
        window.open(url, "_blank");
      }}
    >
      {title}
    </li>
  );
};

const ContainerFooter = () => {
  return (
    <div className="container_footer">
      <p className="copyright">Copyright© 2023 NEXTOR. All rights reserved.</p>
    </div>
  );
};
export default ContainerFooter;
