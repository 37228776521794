import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { boardTitle } from "shared/utils";
import { NormalButton, PageTop } from "components/index";
import useNoticeDetail from "hooks/board/notice/useNoticeDetail";

import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/react-editor";
import { useTranslation } from "react-i18next";
import { dateToString } from "shared/index";

const NOTICE_TYPE: { [key: string]: string } = {
  NOTICE: "공지",
  MAINTENANCE: "점검",
  MARKET: "상점",
};

const NoticeDetail = () => {
  const { noticeContent: content } = useNoticeDetail();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (
    <div className={classNames("detail", "notice")}>
      <div className="detail__inner">
        <PageTop title={boardTitle("notice")} />
        <div
          className={classNames("board_list", "notice", { fixed: content?.isFixed })}
          onClick={() => {
            navigate(`/news/notice/${content?.id}`, { state: { id: content?.id } });
          }}
        >
          <p className="category__outline">
            <span className={content?.category && NOTICE_TYPE[content?.category]}>
              {content?.category && NOTICE_TYPE[content?.category]}
            </span>
          </p>
          <div className="title_box">
            <p className="title">{content?.title}&nbsp;</p>
            <span className="new"></span>
          </div>
          <p className="date">
            {content?.assignedAt && dateToString(content?.assignedAt, "yyyy.MM.dd", i18n.language)}
          </p>
        </div>

        <div className="board border">
          <div className="board__inner">
            {content?.content && (
              <Viewer
                initialValue={content.content || ""} // 유튜브 삽입 및 미리보기 를 위한 설정(iframe)
                customHTMLRenderer={{
                  htmlBlock: {
                    iframe(node: any) {
                      return [
                        {
                          type: "openTag",
                          tagName: "iframe",
                          outerNewLine: true,
                          attributes: node.attrs,
                        },
                        { type: "html", content: node.childrenHTML },
                        { type: "closeTag", tagName: "iframe", outerNewLine: false },
                      ];
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
        <div className="detail__inner__bottom">
          <NormalButton
            title="삭제"
            buttonStyle="box"
            size="md"
            hierarchy="secondary_gray"
            onClick={() => {
              console.log("삭제 버튼 누름");
            }}
          />
          <div className="right">
            <NormalButton
              title="수정"
              buttonStyle="box"
              size="md"
              hierarchy="primary_line"
              onClick={() => {
                navigate(`/news/notice/update`, { state: { content: content } });
              }}
            />
            <NormalButton
              title="목록"
              buttonStyle="box"
              size="md"
              hierarchy="primary"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeDetail;
