import PlayVideoItem from "./PlayVideoItem";
import { VideoItem } from "types/demo";

type PlayVideoListType = {
  videoList: VideoItem[];
  handleDeleteVideo: (id: string) => void;
  handleOnChange: ({
    type,
    value,
    id,
  }: {
    type: "url" | "title" | "isExposed";
    value: string | boolean;
    id: string;
  }) => void;
};

const PlayVideoList = ({ videoList, handleDeleteVideo, handleOnChange }: PlayVideoListType) => {
  const itemHTML = videoList.map((item: VideoItem, index: number) => (
    <PlayVideoItem
      key={index + 1}
      index={index + 1}
      deleteVideo={handleDeleteVideo}
      handleOnChange={handleOnChange}
      {...item}
    />
  ));
  return <ul className="play-video-list">{itemHTML}</ul>;
};

export default PlayVideoList;
