import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { boardTitle } from "shared/utils";
import { NormalButton, PageTop } from "components/index";
import useEventDetail from "hooks/board/event/useEventDetail";

import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/react-editor";
import { useTranslation } from "react-i18next";
import { dateToString } from "shared/index";
import { getBadge } from "./utils";

const EventDetail = () => {
  const { eventContent } = useEventDetail();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (
    <div className={classNames("detail", "event")}>
      <div className="detail__inner">
        <PageTop title={boardTitle("event")} />
        <div className={classNames("board_list", "event")}>
          <p className="category__fill">
            <span className={getBadge({ startDate: eventContent?.startDate, endDate: eventContent?.endDate })}>
              {getBadge({ startDate: eventContent?.startDate, endDate: eventContent?.endDate })}
            </span>
          </p>
          <div className="title_box">
            <p className="title">{eventContent?.title}&nbsp;</p>
            <span className="new"></span>
          </div>
          <p className="date">
            {eventContent?.createdAt && dateToString(eventContent?.createdAt, "yyyy.MM.dd", i18n.language)}
          </p>
        </div>

        <div className="board border">
          <div className="board__inner">
            {eventContent?.content && (
              <Viewer
                initialValue={eventContent.content || ""} // 유튜브 삽입 및 미리보기 를 위한 설정(iframe)
                customHTMLRenderer={{
                  htmlBlock: {
                    iframe(node: any) {
                      return [
                        {
                          type: "openTag",
                          tagName: "iframe",
                          outerNewLine: true,
                          attributes: node.attrs,
                        },
                        { type: "html", content: node.childrenHTML },
                        { type: "closeTag", tagName: "iframe", outerNewLine: false },
                      ];
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
        <div className="detail__inner__bottom">
          <NormalButton
            title="삭제"
            buttonStyle="box"
            size="md"
            hierarchy="secondary_gray"
            onClick={() => {
              console.log("삭제 버튼 누름");
            }}
          />
          <div className="right">
            <NormalButton
              title="수정"
              buttonStyle="box"
              size="md"
              hierarchy="primary_line"
              onClick={() => {
                navigate(`/news/event/update`, { state: { content: eventContent } });
              }}
            />
            <NormalButton
              title="목록"
              buttonStyle="box"
              size="md"
              hierarchy="primary"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetail;
