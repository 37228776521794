import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { ContainerFooter } from "components/index";

import { RootState, ModalState } from "store/index";

import "./subLayout.scss";

const SubLayout = () => {
  const { t, i18n } = useTranslation();

  const modal: ModalState = useSelector((state: RootState) => state.modal);

  return (
    <div className={classNames(`App`, "sub-layout", i18n.language)}>
      <Outlet />
      <ContainerFooter />
    </div>
  );
};

export default SubLayout;
