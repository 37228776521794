export const queryKeys = {
  user: "user",
  adminList: "adminList",
  groupList: "groupList",
  permission: "permission",

  noticeList: "noticeList",
  updateList: "updateList",
  eventList: "eventList",

  demo: "demo",
};
