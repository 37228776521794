import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";

// 관리 계정 목록
function getImgUrl(data: any) {
  console.log(data);
  return axios.get<any>(`/file/signedUrl/image?files[0]=image`);
}

const useImgUrl = () => {
  const queryKey = [queryKeys.adminList];

  const { data, error, refetch } = useQuery({
    queryKey,
    queryFn: (data): any => getImgUrl(data),
    enabled: false,
    select: (data) => data.data.data.signedUrlMap,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("파일 업로드에 실패했습니다.", error);
  }

  return { data, error, refetch };
};

export default useImgUrl;
