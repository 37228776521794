import { RootState, PathState } from "store/index";
import { useSelector } from "react-redux";

import NoticeList from "./NoticeList";
import UpdateList from "./UpdateList";
import FreeboardList from "./FreeboardList";
import ScreenshotList from "./ScreenshotList";
import ReportList from "./ReportList";
import UserList from "./UserList";
import ManagerList from "./ManagerList";

export type BoardListProps = {
  data: any;
  detail?: boolean;
  background?: string;
  id?: number;
  onClick?: any;
};

const BoardList = ({ data, detail, background, id, onClick }: BoardListProps) => {
  // const path: PathState = useSelector((state: RootState) => state.path);
  return <></>;
  // if (path.nowPath === "notice") {
  //   return <NoticeList data={data} detail={detail} background={background} id={id} />;
  // } else if (path.nowPath === "update" || path.nowPath === "event") {
  //   if (detail) {
  //     return <NoticeList data={data} detail={detail} background={background} id={id} />;
  //   } else {
  //     if (data.fixed) {
  //       console.log(`2`);
  //       return <></>;
  //     } else {
  //       return <UpdateList data={data} detail={detail} background={background} id={id} onClick={onClick} />;
  //     }
  //   }
  // } else if (path.nowPath === "free-board" || path.nowPath === "tip-board") {
  //   return <FreeboardList data={data} detail={detail} background={background} id={id} />;
  // } else if (path.nowPath === "screenshot-board") {
  //   return <ScreenshotList data={data} detail={detail} background={background} id={id} />;
  // } else if (path.nowPath === "report" || path.nowPath === "inquiry") {
  //   return <ReportList data={data} detail={detail} background={background} id={id} />;
  // } else if (path.nowPath === "list") {
  //   return <UserList data={data} detail={detail} background={background} id={id} />;
  // } else if (path.nowPath === "account") {
  //   return <ManagerList data={data} detail={detail} background={background} id={id} />;
  // } else {
  //   return <></>;
  // }
};

export default BoardList;
