import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NormalButton } from "components/common";
import { dateToString } from "shared/index";
import Pagination from "components/common/pagination/Pagination";
import { getBadge } from "pages/Board/Event/utils";
import { isNewPost } from "../utils";
import EmptyList from "./EmptyList";
import { Event } from "types/board";

import "./BoardList.scss";

type EventListType = {
  eventList: Event[];
  totalPost: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const PAGINATION = {
  pageRange: 5,
  btnRange: 10,
};

const EventList = ({ eventList, totalPost, page, setPage }: EventListType) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const CATEGORY_TYPE: { [key: string]: string } = { ALL: "", PRESENT: "진행", FUTURE: "예정", PAST: "종료" };

  return (
    <>
      {eventList?.length > 0 &&
        eventList.map((post) => (
          <div
            key={post?.id}
            className={classNames("board_list", "event")}
            onClick={() => {
              navigate(`/news/event/detail`, { state: { content: post } });
            }}
          >
            {post?.thumbnailImageUrl && <img src={`${post?.thumbnailImageUrl}`} alt="img" />}
            <div className="board_list__box">
              <div className="top">
                <p className="category">
                  {CATEGORY_TYPE[post?.category] === "ALL" ? (
                    <span className={CATEGORY_TYPE[post?.category]}>{CATEGORY_TYPE[post?.category]}</span>
                  ) : (
                    <span className={getBadge({ startDate: post?.startDate, endDate: post?.endDate })}>
                      {getBadge({ startDate: post?.startDate, endDate: post?.endDate })}
                    </span>
                  )}
                </p>
                <p className="title">{post?.title}</p>
                {isNewPost(post?.createdAt) && <span className="new-post" />}
              </div>

              <div className="bottom">
                <p className="update_date update-badge">
                  <span>업데이트</span>
                  {dateToString(post?.createdAt, "yyyy.MM.dd", i18n.language)}
                </p>
                <p className="view">
                  <span>조회수</span>
                  {post?.views}
                </p>
                <p className="date">
                  <span>이벤트 기간</span>
                  {dateToString(post?.startDate, "yyyy.MM.dd", i18n.language)} ~{" "}
                  {dateToString(post?.endDate, "yyyy.MM.dd", i18n.language)}
                </p>
              </div>
            </div>
            <div
              className="buttons_box"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <NormalButton
                title="수정"
                hierarchy="primary_line"
                size="xs"
                buttonStyle="box"
                onClick={() => {
                  navigate(`/news/event/update`, { state: { content: post } });
                }}
              />
              <NormalButton title="삭제" hierarchy="secondary_gray" size="xs" buttonStyle="box" onClick={() => {}} />
            </div>
          </div>
        ))}

      {eventList?.length > 0 && (
        <div className="paging-wrap">
          <Pagination
            page={page}
            setPage={setPage}
            totalPost={totalPost}
            btnRange={PAGINATION.btnRange}
            pageRange={PAGINATION.pageRange}
          />
        </div>
      )}
      {eventList?.length === 0 && <EmptyList />}
    </>
  );
};

export default EventList;
