import { axios, ResponseDataType } from "apis/http";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { queryKeys } from "react-query/querykey";
import { NoticeCategory } from "types/board";

export type Param = {
  title: string;
  content?: string;
  isFixed: boolean;
  isExposed: boolean;
  category: NoticeCategory;
  assignedAt: number;
};

const ERROR_MESSAGE: { [key: string]: string } = {
  // ACCOUNT_ID_ALREADY_EXIST: "아이디가 존재합니다.",
};

const postNotice = (content: Param) => {
  return axios.post<any, null>("/notice", content);
};

const useAddNotice = () => {
  const queryKey = [queryKeys.noticeList];
  const queryClient = useQueryClient();

  const { mutate: addNotice } = useMutation({
    mutationFn: (content: Param) => postNotice(content),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        console.error("공지사항을 등록하는데 오류가 발생하였습니다.", error);
        const errorType = error.response?.data.code;
        if (errorType) alert(ERROR_MESSAGE[errorType]);
      }
    },
  });

  return { addNotice };
};

export default useAddNotice;
