import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import Pagination from "react-js-pagination";
import classNames from "classnames";

import {
  CategoryBox,
  BoardList,
  PageTop,
  InputBox,
  SelectBox,
  NormalButton,
  Loading,
  EmptyList,
} from "components/index";
import { RootState, PathState, LoadingState, loadingSetting, LOGINState } from "store/index";
import { useSelect } from "hooks/index";
import { boardTitle, getCookie, setCookie, boardCategory, boardHeader } from "shared/index";
import { CategoryList } from "shared/config/router/path";
import { REACT_APP_SERVER_URL, REACT_APP_COOKIE } from "shared/utils/config";
import useHttp from "hooks/useHttp";
import { ReactComponent as SearchIcon } from "assets/images/layer/search.svg";
import { getList } from "apis/board";
import { AUTH_COOKIE } from "constants/index";

const Board = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, sendRequest } = useHttp();

  const path: PathState = useSelector((state: RootState) => state.path);

  const loading: LoadingState = useSelector((state: RootState) => state.loading);
  const login: LOGINState = useSelector((state: RootState) => state.login);
  const [category, setCategory] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const selectData = useSelect(CategoryList[0]);
  const [listData, setListData] = useState([]); // Axios로 받아온 데이터 저장

  const handlePageChange = (page: number) => {
    setPage(page);
    _getList();
  };
  const [listPerPage, setListPerPage] = useState(0); // 페이지 당 리스트 개수
  const [page, setPage] = useState(1); // 현재 페이지
  const [totalCount, setTotalCount] = useState(0); // 총 아이템 개수

  // get List
  const _getList = async () => {
    const data = await sendRequest(getList, {
      path:
        location.pathname === "/news/notice" && category === 0
          ? ["NOTICE", "MARKET", "MAINTENANCE"]
          : location.pathname === "/news/notice" && category === 1
          ? ["NOTICE"]
          : location.pathname === "/news/notice" && category === 2
          ? ["MAINTENANCE"]
          : location.pathname === "/news/notice" && category === 3
          ? ["MARKET"]
          : location.pathname === "/news/update"
          ? ["UPDATE"]
          : null,
      comparison:
        location.pathname !== "/news/event"
          ? null
          : category === 0
          ? null
          : category === 1
          ? "PRESENT"
          : category === 2
          ? "FUTURE"
          : category === 3
          ? "PAST"
          : null,

      nowPath: location.pathname,
      category: location.pathname !== "/news/event" ? category : "GENERAL",
      title: selectData.value.id !== 1 && searchValue ? searchValue : null,
      content: selectData.value.id !== 0 && searchValue ? searchValue : null,
      page: page,
      limit: location.pathname === "/news/notice" ? 12 : 5,
    });

    // console.log(data); // {error: true, code 'TOKEN_EXPIRATION'}

    const lists = data?.data?.list;
    const totalCount = data?.data?.totalCount;

    if (data?.data?.error || lists === undefined) {
      if (data?.list?.code === "TOKEN_EXPIRATION") {
        setCookie(AUTH_COOKIE, "", null);
        navigate("/login");
      }
    } else {
      setListData(lists);
      setTotalCount(totalCount);
    }
  };

  // const DeleteApi = async (id: string) => {
  //   await axios
  //     .delete(`${REACT_APP_SERVER_URL}/board/${path.nowPath}/${id}`, {
  //       headers: {
  //         Authorization: sessionStorage.getItem(`accessToken`),
  //       },
  //     })
  //     .then(({ data }) => {
  //       console.log(data.response.data);
  //       setTimeout(() => {
  //         setTimeout(() => {
  //           alert("삭제되었습니다");
  //         }, 2000);
  //       }, 2000);
  //       fetchData();
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       // alert("개발팀에 문의해주세요.");
  //     });
  // };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLImageElement>) => {
    const isEnterKey = e.key === "Enter";
    if (isEnterKey) {
      _getList();
      setPage(1);
    }
  };

  const resetPage = () => {
    setCategory(0);
    setSearchValue("");
    setPage(1);
  };

  useEffect(() => {
    resetPage();
    setListPerPage(location.pathname === "/news/update" || location.pathname === "/news/event" ? 5 : 12);
    _getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    setSearchValue("");
    setListData([]);
    _getList();
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    _getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className={classNames("content", path.nowPath)}>
      <div className="content__inner">
        <PageTop title={boardTitle(path.nowPath)} />
        <div className="board">
          <div className="board__top">
            {/* {(path.nowPath === "notice" ||
              path.nowPath === "event" ||
              path.nowPath === "free-board" ||
              path.nowPath === "tip-board" ||
              path.nowPath === "screenshot-board" ||
              path.nowPath === "inquiry") && (
              <CategoryBox data={boardCategory(path.nowPath)} category={category} setCategory={setCategory} />
            )} */}
            <div className="right">
              {path.nowPath !== "list" && (
                <SelectBox name="category" data={CategoryList} selectData={selectData} classification />
              )}
              <InputBox
                value={searchValue || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchValue(e.target.value);
                }}
                placeholder="검색어를 입력해주세요."
                onKeyPress={handleOnKeyPress}
                icon
                type="text"
              />
              <SearchIcon />
            </div>
          </div>
          <div className="board__body">
            {listData?.length <= 0 ? (
              <EmptyList />
            ) : (
              <>
                {["notice", "free-board", "tip-board", "report", "inquiry", "account"].includes(path.nowPath) && (
                  <div className="board__body__header">
                    {boardHeader(path.nowPath).map((data: any, idx: number) => (
                      <p key={idx}>{data.title}</p>
                    ))}
                  </div>
                )}
                {loading.isLoading ? (
                  <Loading />
                ) : listData?.length <= 0 || listData === null || listData === undefined ? (
                  <EmptyList />
                ) : (
                  listData.map((data, idx) => {
                    return (
                      <BoardList
                        data={data}
                        key={idx}
                        id={page > 1 ? (page - 1) * listPerPage + (idx + 1) : idx + 1}
                        onClick={() => {}}
                      />
                    );
                  })
                )}
              </>
            )}
          </div>
          <div className="board__bottom">
            {listData?.length <= 0 || listData === null || listData === undefined ? null : (
              <Pagination
                activePage={page}
                itemsCountPerPage={listPerPage}
                totalItemsCount={totalCount}
                pageRangeDisplayed={10}
                prevPageText={"‹"}
                nextPageText={"›"}
                onChange={handlePageChange}
                innerClass="board__pagination"
                activeClass="selected"
                itemClassFirst="normal_button box sm secondary_gray"
                itemClassPrev="normal_button box sm secondary_gray"
                itemClassNext="normal_button box sm secondary_gray"
                itemClassLast="normal_button box sm secondary_gray"
              />
            )}
            {path.nowPath !== "screenshot-board" && login.login && (
              <NormalButton
                title={path.nowPath === "notice" ? "글쓰기" : "등록"}
                size="md"
                hierarchy="primary"
                icon="false"
                buttonStyle="box"
                onClick={() => {
                  navigate(`/news/${path.nowPath}/write`);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Board;
