import Axios from "axios";
import { getCookie, setCookie } from "shared";
import { AUTH_COOKIE } from "constants/index";
import type { AxiosResponse } from "axios";

const baseURL = "https://dev-api.zemiverse.com/admin";

export type ResponseDataType = {
  success: boolean;
  code: string;
};

export const axios = Axios.create({
  baseURL,
  timeout: 4000,
});

axios.interceptors.request.use(
  (config) => {
    const token = getCookie(AUTH_COOKIE);
    if (token) config.headers.token = token;
    console.log("❗️", config.url);
    return config;
  },
  (error) => {
    console.log("request error", error);
  }
);

let isExpired = false;

axios.interceptors.response.use(
  (response: AxiosResponse): any => {
    return response;
  },
  async (error: any) => {
    console.log(error);
    const originalResponse = error.config;
    const errorStatus = error?.response?.data?.code;
    if (originalResponse && errorStatus === "TOKEN_EXPIRATION" && !isExpired) {
      console.log(error);
      alert("토큰이 만료되었습니다. 다시 로그인하세요.");
      setCookie(AUTH_COOKIE, "", null);
      window.location.href = "/login";
      isExpired = true;
    }
    return Promise.reject(error);
  }
);

// 사용 안하고 있음
export const http = {
  get: function get<Response = unknown>(url: string) {
    return axios.get<Response>(url).then((res) => res.data);
  },
  post: function post<Request = any, Response = unknown>(url: string, data?: Request) {
    return axios.post<Response>(url, data).then((res) => res.data);
  },
  put: function put<Request = any, Response = unknown>(url: string, data?: Request) {
    return axios.put<Response>(url, data).then((res) => res.data);
  },
  patch: function patch<Request = any, Response = unknown>(url: string, data?: Request) {
    return axios.patch<Response>(url, data).then((res) => res.data);
  },
};
