import ReactDOM from "react-dom/client";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// pages
import App from "./App";
// router
import { BrowserRouter as Router } from "react-router-dom";
//seo
// redux
import rootReducer from "./store/index";
import { legacy_createStore as createStore } from "redux";
import { Provider } from "react-redux";
// import { persistStore } from "redux-persist";
// import { PersistGate } from "redux-persist/integration/react";
// css
import "assets/css/index.scss";

const store = createStore(rootReducer);
// export const persistor = persistStore(store);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // throwOnError: true,
      retry: 0,
      // suspense: true,
    },
  },
});

// const { reset } = useQueryErrorResetBoundary();
const element = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(element);
