import { axios, ResponseDataType } from "apis/http";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { queryKeys } from "react-query/querykey";
import { useNavigate } from "react-router-dom";
import { NoticeCategory } from "types/board";

export type Param = {
  noticeId: string;
  title: string;
  content?: string;
  isFixed: boolean;
  isExposed: boolean;
  category: NoticeCategory;
  assignedAt: number;
};

const ERROR_MESSAGE: { [key: string]: string } = {
  // ACCOUNT_ID_ALREADY_EXIST: "아이디가 존재합니다.",
  // NICKNAME_ALREADY_EXIST: "닉네임이 존재합니다.",
};

const putNotice = (noticeContent: Param) => {
  return axios.put<any, null>("/notice", noticeContent);
};

const useUpdateNotice = () => {
  const navigate = useNavigate();
  const queryKey = [queryKeys.noticeList];
  const queryClient = useQueryClient();

  const { mutate: updateNotice } = useMutation({
    mutationFn: (noticeContent: Param) => putNotice(noticeContent),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      navigate("/news/notice");
    },
    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        const errorType = error.response?.data.code;
        console.error(errorType, error);
        if (errorType) alert(ERROR_MESSAGE[errorType]);
      }
    },
  });

  return { updateNotice };
};

export default useUpdateNotice;
