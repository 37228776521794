import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";
import { Category } from "types/demo";

type Item = {
  category?: string;
  createdAt?: string;
  id: string;
  title: string;
  updatedAt?: string;
  url: string;
  isExposed: boolean;
};

export type GetDemoList = {
  data: { links: Item[]; count: number };
};

// 리스트 가져오기
function getDemoList(category: string) {
  return axios.get<GetDemoList>(`/demo/link/list/${category}`);
}

const useDemoList = (category: Category) => {
  let queryKey = [queryKeys.demo, category];

  const { data, error, refetch } = useQuery({
    queryKey,
    queryFn: () => getDemoList(category),
    enabled: false,
  });

  const links = data?.data?.data.links;
  const existIds = links?.map((item: Item) => item.id);
  if (isAxiosError<ResponseDataType>(error)) {
    console.error("데모용 IR 파일을 가져오는데 에러가 발생하였습니다.");
  }
  return { links, existIds, error, refetch };
};

export default useDemoList;
