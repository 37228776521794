import { Editor } from "@toast-ui/react-editor";
import { REACT_APP_SERVER_URL } from "shared/utils";
import { axios as axiosInstance } from "apis/http";
import axios from "axios";
import useHttp from "hooks/useHttp";
import { getImgUrl, putImgToS3 } from "apis/board";

import "@toast-ui/editor/dist/toastui-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import "@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css";
import tableMergedCell from "@toast-ui/editor-plugin-table-merged-cell";
import useImgUrl from "hooks/board/useImgUrl";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";

interface EditorComponentProps {
  editorRef?: any;
  onChange?: any;
}

const EditorComponent = ({ editorRef, onChange }: EditorComponentProps) => {
  const { refetch: getImgUrl, data } = useImgUrl();
  const { isLoading, sendRequest } = useHttp();

  // 유튜브 삽입을 위한 커스텀 툴바 아이템 생성
  const myCustomEl = document.createElement("span");

  // 팝업 바디 생성
  const container = document.createElement("div");
  const description = document.createElement("p");
  description.textContent = "Youtube 주소를 입력하고 Enter를 누르세요!";

  const urlInput = document.createElement("input");
  urlInput.style.width = "100%";

  // 팝업 input 창에 내용 입력 시 호출됨
  urlInput.addEventListener("keyup", (e: any) => {
    // 엔터를 누르면, 입력값이 Youtube 주소인지 정규식으로 검사
    if (e.key === "Enter") {
      if (
        /https:\/\/youtu.be\/.{11,}/.test(e.target.value) ||
        /https:\/\/www.youtube.com\/watch\?v=.{11,}/.test(e.target.value)
      ) {
        let str =
          '<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/' +
          e.target.value.slice(-11) +
          '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';

        // 마크다운 모드에서 iframe 태그 삽입 후, 팝업을 닫고 위지윅 모드로 변환
        editorRef.current.getInstance().changeMode("markdown");
        editorRef.current.getInstance().insertText(str);
        editorRef.current.getInstance().eventEmitter.emit("closePopup");
        editorRef.current.getInstance().changeMode("wysiwyg");
      }
    }
  });

  container.appendChild(description);
  container.appendChild(urlInput);

  return (
    <Editor
      ref={editorRef}
      previewStyle="vertical"
      initialEditType="wysiwyg"
      onChange={onChange}
      toolbarItems={[
        ["heading", "bold", "italic", "strike"],
        ["hr", "quote"],
        ["ul", "ol", "task", "indent", "outdent"],
        ["table", "image"],
        ["code", "codeblock"],
        [
          {
            name: "Youtube",
            tooltip: "Youtube",
            el: myCustomEl,
            popup: {
              body: container,
              style: { width: "auto" },
            },
          },
        ],
      ]}
      plugins={[colorSyntax, tableMergedCell]}
      customHTMLRenderer={{
        htmlBlock: {
          iframe(node: any) {
            return [
              {
                type: "openTag",
                tagName: "iframe",
                outerNewLine: true,
                attributes: node.attrs,
              },
              { type: "html", content: node.childrenHTML },
              { type: "closeTag", tagName: "iframe", outerNewLine: true },
            ];
          },
        },
      }}
      useCommandShortcut={true}
      hooks={{
        addImageBlobHook: async (blob: Blob | File, callback) => {
          await axiosInstance.get("/file/signedUrl/image?files[0]=image").then(({ data }) => {
            const imageUrl = data?.data?.signedUrlMap.image;
            axios
              .put(imageUrl, blob)
              .then(() => {
                callback(imageUrl.substring(0, imageUrl.indexOf("?")), "첨부 이미지");
              })
              .catch((e) => {
                console.error("Image upload failed:", e);
              });
          });
        },
      }}
    />
  );
};

export default EditorComponent;
