const ContentsWorld = () => {
  return (
    <div className="content contents-world">
      <div className="content__inner">
        <p>개발중입니다.</p>
      </div>
    </div>
  );
};

export default ContentsWorld;
