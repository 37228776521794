import { MainLayout, NotFound, SubLayout } from "components/index";
import {
  Board,
  Faq,
  Login,
  Detail,
  Write,
  UserDetail,
  ContentsItem,
  ContentsAni,
  ContentsWorld,
  InspectItem,
  InspectAni,
  Template,
  Create,
  Delete,
  AdminAccountDelete,
  PaymentDetail,
  PaymentStatistics,
  UserSecession,
  UserDormant,
  AdminGroupList,
  AdminAccountList,
  AdminAccountSave,
  AdminGroupSave,
  Popup,
  Banner,
  NoticeDetail,
  NoticeWrite,
  NoticeUpdate,
  NoticeListPage,
  UpdateListPage,
  UpdateDetail,
  UpdateWrite,
  UpdateUpdate,
  EventListPage,
  EventWrite,
  EventUpdate,
  EventDetail,
} from "pages/index";
import { RouteObject, Navigate } from "react-router-dom";
import { DemoFilePage, IRFilePage, PlayVideoPage, PasswordPage } from "pages/Demo";

const path: RouteObject[] = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      { path: "/", element: <Navigate replace to="/management/contents/item" /> },
      {
        path: "management",
        children: [
          // Page > Management 폴더
          { path: "contents/item", element: <ContentsItem /> }, // 콘텐츠 관리 > 아이템 관리
          { path: "contents/ani", element: <ContentsAni /> }, // 콘텐츠 관리 > 애니 관리
          { path: "contents/world", element: <ContentsWorld /> }, // 콘텐츠 관리 > 월드 관리
          { path: "inspect/item", element: <InspectItem /> }, // 콘텐츠 검수 > 아이템
          { path: "inspect/ani", element: <InspectAni /> }, // 콘텐츠 검수 > 애니
          { path: "template", element: <Template /> }, // 템플릿 관리
          { path: "create", element: <Create /> }, // 크리에이터 관리
        ],
      },
      {
        path: "market",
        children: [
          // Page > Market 폴더
          // { path: "transaction", element: <Transaction /> }, // 마켓 거래 내역
          // { path: "sales", element: <Sales /> }, // 판매 콘텐츠 관리
          // { path: "rental", element: <Rental /> }, // 렌탈 콘텐츠 관리
          // { path: "creatorshop", element: <CreatorShop /> }, // 크리에이터 샵 관리
        ],
      },
      {
        path: "world",
        children: [
          // Page > World 폴더
          // { path: "management", element: <WorldManagement /> }, // 월드 관리
          // { path: "statistics", element: <WorldStatistics /> }, // 월드 통계
        ],
      },
      {
        path: "",
        children: [
          // Page > Board 폴더
          { path: "news/notice", element: <NoticeListPage /> }, // 새소식 > 공지사항
          { path: "news/notice/write", element: <NoticeWrite /> }, // 새소식 > 공지사항 > 글쓰기/수정
          { path: "news/notice/update", element: <NoticeUpdate /> }, // 새소식 > 공지사항 > 글쓰기/수정
          { path: "news/notice/detail", element: <NoticeDetail /> }, // 새소식 > 공지사항 > 글쓰기/수정

          { path: "news/update", element: <UpdateListPage /> }, // 업데이트 > 공지사항
          { path: "news/update/write", element: <UpdateWrite /> }, // 업데이트 > 공지사항 > 글쓰기/수정
          { path: "news/update/update", element: <UpdateUpdate /> }, // 새소식 > 공지사항 > 글쓰기/수정
          { path: "news/update/detail", element: <UpdateDetail /> }, // 새소식 > 공지사항 > 글쓰기/수정

          { path: "news/event", element: <EventListPage /> }, // 이벤트 > 공지사항
          { path: "news/event/*", element: <Detail /> }, // 이벤트 > 공지사항 > 상세
          { path: "news/event/write", element: <EventWrite /> }, // 이벤트 > 공지사항 > 글쓰기/수정
          { path: "news/event/detail", element: <EventDetail /> }, // 새소식 > 공지사항 > 글쓰기/수정
          { path: "news/event/update", element: <EventUpdate /> }, // 새소식 > 공지사항 > 글쓰기/수정

          { path: "cs/inquiry", element: <Board /> }, // 고객센터 > 1:1 문의
          { path: "cs/inquiry/*", element: <Detail /> }, // 고객센터 > 1:1 문의 > 상세
          { path: "cs/faq", element: <Faq /> }, // 고객센터 > faq
          { path: "cs/faq/write", element: <Write /> }, // 고객센터 > faq > 글쓰기/수정
          { path: "community/free-board", element: <Board /> }, // 커뮤니티 > 자유게시판
          { path: "community/free-board/*", element: <Detail /> }, // 커뮤니티 > 자유게시판 > 상세
          { path: "community/free-board/write", element: <Write /> }, // 커뮤니티 > 자유게시판 > 글쓰기/수정
          { path: "community/tip-board", element: <Board /> }, // 커뮤니티 > 팁 공유 게시판
          { path: "community/tip-board/*", element: <Detail /> }, // 커뮤니티 > 팁 공유 게시판 > 상세
          { path: "community/tip-board/write", element: <Write /> }, // 커뮤니티 > 팁 공유 게시판 > 글쓰기/수정
          { path: "community/screenshot-board", element: <Board /> }, // 커뮤니티 > 스크린샷 게시판
          { path: "community/screenshot-board/*", element: <Detail /> }, // 커뮤니티 > 스크린샷 게시판 > 상세
          { path: "community/screenshot-board/write", element: <Write /> }, // 커뮤니티 > 스크린샷 게시판 > 글쓰기/수정
          { path: "report", element: <Board /> }, // 신고내역 관리
          { path: "report/*", element: <Detail /> }, // 신고내역 관리 > 상세
          { path: "delete", element: <Delete /> }, // 삭제 예정 게시물
        ],
      },
      {
        path: "settlement",
        children: [
          // Page > Settlement 폴더
          // { path: "sales/statistics", element: <SalesStatistics/> }, // 매출 통계
          // { path: "refund/application", element: <Application/> }, // 환급 요청 관리 > 환급 신청 내역
          // { path: "refund/statistics", element: <RefundStatistics/> }, // 환급 요청 관리 > 환급 통계
          { path: "payment/detail", element: <PaymentDetail /> }, // 결제 관리 > 결제 내역
          { path: "payment/statistics", element: <PaymentStatistics /> }, // 결제 관리 > 결제 통계
        ],
      },
      {
        path: "account", // 계정 관리
        children: [
          { path: "user", element: <Board /> }, // 일반 회원 관리 > 회원 관리
          { path: "user/*", element: <UserDetail /> }, // 일반 회원 관리 > 회원 관리 > 상세
          { path: "user/secession", element: <UserSecession /> }, // 일반 회원 관리 > 탈퇴 회원
          { path: "user/dormant", element: <UserDormant /> }, // 일반 회원 관리 > 휴면 회원
          { path: "admin", element: <AdminAccountList /> }, // 관리자 관리 > 관리자 계정 관리
          { path: "admin/save", element: <AdminAccountSave /> }, // 관리자 관리 > 관리자 계정 관리 > 관리자 계정 생성/수정
          { path: "admin/delete", element: <AdminAccountDelete /> }, // 관리자 관리 > 관리자 계정 관리 > 관리자 계정 삭제
          { path: "admin/group", element: <AdminGroupList /> }, // 관리자 관리 > 관리 그룹 관리
          { path: "admin/group/save", element: <AdminGroupSave /> }, // 관리자 관리 > 관리 그룹 관리 > 만들기
          // { path: "visitor", element: <UserStatistics /> }, // 접속자 통계
        ],
      },
      {
        path: "demo",
        children: [
          { path: "password", element: <PasswordPage /> }, // 접속 PW 관리
          { path: "ir", element: <IRFilePage /> }, // IR 파일 관리
          { path: "play-video", element: <PlayVideoPage /> }, // 플레이 영상 관리
          { path: "demo-file", element: <DemoFilePage /> }, // Demo 파일 관리
        ],
      },
      {
        path: "design",
        children: [
          { path: "popup", element: <Popup /> }, // 팝업 관리
          { path: "banner", element: <Banner /> }, // 배너 관리
        ],
      },
    ],
  },
  {
    path: "login",
    element: <SubLayout />,
    children: [
      { path: "", element: <Login /> }, // 로그인 페이지
    ],
  },
  {
    path: "*",
    element: <SubLayout />,
    children: [
      { path: "*", element: <NotFound /> }, // 404page
    ],
  },
];

// sidebar menu
const zemiMenu = [
  {
    id: 1,
    title: "스튜디오 관리",
    link: "/management/contents/item",
    children: [
      {
        id: 10,
        title: "콘텐츠 관리",
        link: "/management/contents/item",
        children: [
          { id: 100, title: "아이템 관리", link: "/management/contents/item" },
          { id: 101, title: "애니 관리", link: "/management/contents/ani" },
          { id: 102, title: "월드 관리", link: "/management/contents/world" },
        ],
      },
      {
        id: 11,
        title: "콘텐츠 검수",
        link: "/management/inspect/item",
        children: [
          { id: 110, title: "아이템 관리", link: "/management/inspect/item" },
          { id: 111, title: "애니 관리", link: "/management/inspect/item" },
        ],
      },
      { id: 12, title: "템플릿 관리", link: "/management/template" },
      { id: 13, title: "크리에이터 관리", link: "/management/create" },
    ],
  },
  // {
  //   id: 2, title: "마켓 관리", link: "/market/transaction", children: [
  //     { id: 20, title: "마켓 거래 내역", link: "/market/transaction" },
  //     { id: 21, title: "판매 콘텐츠 관리", link: "/market/sales" },
  //     { id: 22, title: "렌탈 콘텐츠 관리", link: "/market/rental" },
  //     { id: 23, title: "크리에이터 샵 관리", link: "/market/creatorshop" },
  //   ]
  // },
  // {
  //   id: 3, title: "월드 관리", link: "/world/management", children: [
  //     { id: 20, title: "월드 관리", link: "/world/management" },
  //     { id: 21, title: "월드 통계", link: "/world/statistics" },
  //   ]
  // },
  {
    id: 4,
    title: "게시판 관리",
    link: "/news/notice",
    children: [
      {
        id: 40,
        title: "새 소식 관리",
        link: "/news/notice",
        children: [
          {
            id: 400,
            title: "공지사항",
            link: "/news/notice",
            children: [
              { id: 4000, title: "공지사항 작성", link: "/news/notice/write" },
              { id: 4001, title: "공지사항 수정", link: "/news/notice/update" },
              { id: 4002, title: "공지사항 보기", link: "/news/notice/detail" },
            ],
          },
          {
            id: 401,
            title: "업데이트",
            link: "/news/update",
            children: [
              { id: 4010, title: "업데이트 작성", link: "/news/update/write" },
              { id: 4011, title: "업데이트 수정", link: "/news/update/update" },
              { id: 4012, title: "업데이트 보기", link: "/news/update/detail" },
            ],
          },
          {
            id: 402,
            title: "이벤트",
            link: "/news/event",
            children: [
              { id: 4020, title: "이벤트 작성", link: "/news/event/write" },
              { id: 4021, title: "이벤트 수정", link: "/news/event/update" },
              { id: 4022, title: "이벤트 보기", link: "/news/event/detail" },
            ],
          },
        ],
      },
      {
        id: 41,
        title: "고객센터 관리",
        link: "/cs/inquiry",
        children: [
          { id: 411, title: "1:1 문의", link: "/cs/inquiry" },
          { id: 410, title: "FAQ", link: "/cs/faq" },
        ],
      },
      {
        id: 42,
        title: "커뮤니티 관리",
        link: "/community/free-board",
        children: [
          { id: 420, title: "자유게시판", link: "/community/free-board" },
          { id: 421, title: "팁 공유 게시판", link: "/community/tip-board" },
          { id: 422, title: "스크린샷 게시판", link: "/community/screenshot-board" },
        ],
      },
      { id: 43, title: "신고내역 관리", link: "/report" },
      { id: 44, title: "삭제 예정 게시물", link: "/delete" },
    ],
  },
  {
    id: 5,
    title: "매출정산 관리",
    link: "/settlement/payment/detail",
    children: [
      // { id: 51, title: "매출 통계", link: "/settlement/sales/statistics" },
      // {
      //   id: 52, title: "환급 요청 관리", link: "/settlement/refund/application", children: [
      //     { id: 520, title: "환급 신청 내역", link: "/settlement/refund/application" },
      //     { id: 521, title: "환급 통계", link: "/settlement/refund/statistics" },
      //   ]
      // },
      {
        id: 50,
        title: "결제관리",
        link: "/settlement/payment/detail",
        children: [
          { id: 500, title: "결제 내역", link: "/settlement/payment/detail" },
          { id: 501, title: "결제 통계", link: "/settlement/payment/statistics" },
        ],
      },
    ],
  },
  {
    id: 6,
    title: "계정 관리",
    link: "/account/user",
    children: [
      {
        id: 60,
        title: "일반 회원 관리",
        link: "/account/user",
        children: [
          { id: 600, title: "회원 관리", link: "/account/user" },
          { id: 602, title: "탈퇴 회원", link: "/account/user/secession" },
          { id: 603, title: "휴면 회원", link: "/account/user/dormant" },
        ],
      },
      {
        id: 61,
        title: "관리자 관리",
        link: "/account/admin",
        children: [
          {
            id: 610,
            title: "관리자 계정 관리",
            link: "/account/admin",
            children: [{ id: 6101, title: "관리자 계정 수정", link: "/account/admin/save" }],
          },
          {
            id: 611,
            title: "관리 그룹 관리",
            link: "/account/admin/group",
            children: [{ id: 6111, title: "관리 그룹 생성", link: "/account/admin/group/save" }],
          },
        ],
      },
      // { id: 62, title: "접속자 통계", link: "account/visitor" },
    ],
  },
  {
    id: 7,
    title: "디자인 관리",
    link: "/design/popup",
    children: [
      { id: 70, title: "팝업 관리", link: "/design/popup" },
      { id: 71, title: "배너 관리", link: "/design/banner" },
    ],
  },
  {
    id: 8,
    title: "Demo Web 관리",
    link: "/demo/password",
    children: [
      { id: 80, title: "접속 PW 관리", link: "/demo/password" },
      { id: 81, title: "IR 파일 관리", link: "/demo/ir" },
      { id: 82, title: "플레이 영상관리", link: "/demo/play-video" },
      { id: 83, title: "Demo 파일 관리", link: "/demo/demo-file" },
    ],
  },
];

// const boardHeader = [
//   {
//     path: "notice",
//     boardTitle: [
//       { id: 0, title: "순서" },
//       { id: 1, title: "구분" },
//       { id: 2, title: "제목" },
//       { id: 3, title: "작성일" },
//       { id: 4, title: "조회수" },
//     ],
//     boardCategory: [
//       { id: 0, title: "전체" },
//       { id: 1, title: "공지" },
//       { id: 2, title: "점검" },
//       { id: 3, title: "상점" },
//     ],
//   },
//   {
//     path: "update",
//     boardTitle: [],
//     boardCategory: [],
//   },
//   {
//     path: "event",
//     boardTitle: [],
//     boardCategory: [
//       { id: 0, title: "전체" },
//       { id: 1, title: "진행" },
//       { id: 2, title: "예정" },
//       { id: 3, title: "종료" },
//     ],
//   },
//   {
//     path: "free-board",
//     boardTitle: [
//       { id: 0, title: "순서" },
//       { id: 1, title: "제목" },
//       { id: 2, title: "작성자" },
//       { id: 3, title: "작성일" },
//       { id: 4, title: "댓글수" },
//       { id: 5, title: "좋아요" },
//       { id: 6, title: "신고" },
//       { id: 7, title: "조회수" },
//     ],
//     boardCategory: [
//       { id: 0, title: "전체" },
//       { id: 1, title: "최신순" },
//       { id: 2, title: "인기순" },
//       // { id: 3, title: "댓글수" },
//     ],
//   },
//   {
//     path: "tip-board",
//     boardTitle: [
//       { id: 0, title: "순서" },
//       { id: 1, title: "제목" },
//       { id: 2, title: "작성자" },
//       { id: 3, title: "작성일" },
//       { id: 4, title: "댓글수" },
//       { id: 5, title: "좋아요" },
//       { id: 6, title: "신고" },
//       { id: 7, title: "조회수" },
//     ],
//     boardCategory: [
//       { id: 0, title: "전체" },
//       { id: 1, title: "최신순" },
//       { id: 2, title: "인기순" },
//       // { id: 3, title: "댓글수" },
//     ],
//   },
//   {
//     path: "screenshot-board",
//     boardTitle: [],
//     boardCategory: [
//       { id: 0, title: "전체" },
//       { id: 1, title: "최신순" },
//       { id: 2, title: "인기순" },
//       // { id: 3, title: "댓글수" },
//     ],
//   },
//   {
//     path: "report",
//     boardTitle: [
//       { id: 0, title: "순서" },
//       { id: 1, title: "구분" },
//       { id: 2, title: "신고 원문" },
//       { id: 3, title: "작성자" },
//       { id: 4, title: "신고 횟수" },
//       { id: 5, title: "처리상태" },
//     ],
//     boardCategory: [],
//   },
//   {
//     path: "faq",
//     boardTitle: [],
//     boardCategory: [
//       { id: 0, title: "전체" },
//       { id: 1, title: "ZEMIVERSE" },
//       { id: 2, title: "Studio" },
//     ],
//   },
//   {
//     path: "inquiry",
//     boardTitle: [
//       { id: 0, title: "순서" },
//       { id: 1, title: "구분" },
//       { id: 2, title: "제목" },
//       { id: 3, title: "작성자" },
//       { id: 4, title: "작성일" },
//       { id: 5, title: "처리상태" },
//     ],
//     boardCategory: [
//       { id: 0, title: "전체" },
//       { id: 1, title: "문의" },
//       { id: 2, title: "버그 제보" },
//       { id: 3, title: "신고" },
//     ],
//   },
//   {
//     path: "list",
//     boardTitle: [
//       { id: 0, title: "순서" },
//       { id: 1, title: "E-mail" },
//       { id: 2, title: "소셜" },
//       { id: 3, title: "닉네임" },
//       { id: 4, title: "계정 연동" },
//       { id: 5, title: "연락처" },
//       { id: 6, title: "최종 접속일" },
//       { id: 7, title: "접속 횟수" },
//       { id: 8, title: "상태" },
//     ],
//     boardCategory: [],
//   },
//   {
//     path: "account",
//     boardTitle: [
//       { id: 0, title: "구분" },
//       { id: 1, title: "ID" },
//       { id: 2, title: "담당자명" },
//       { id: 3, title: "최근접속일" },
//       { id: 4, title: "관리" },
//     ],
//     boardCategory: [],
//   },
// ];

const CategoryList = [
  { id: 0, title: "제목" },
  { id: 1, title: "내용" },
  { id: 2, title: "제목 + 내용" },
];

const BoardTopTableData = [
  { id: 0, title: "총 회원 수", user: 1234 },
  { id: 1, title: "Facebook", user: 1234 },
  { id: 2, title: "Google", user: 1234 },
  { id: 3, title: "Apple", user: 1234 },
];

export { path, zemiMenu, CategoryList, BoardTopTableData };
