import { http } from "apis/http";
import qs from "qs";
// 사용 안하고 있음

// get news list
export const getList = (data: {
  // notice && update
  path?: string;

  // event
  comparison?: string;

  nowPath: string;
  category: number;
  title: string;
  content: string;
  page: number;
  limit: number;
}) => {
  if (data.nowPath === "/news/notice" || data.nowPath === "/news/update") {
    return http.get<any>(
      `/notice${qs.stringify(
        { categories: data.path },
        { arrayFormat: "indices", skipNulls: true, addQueryPrefix: true }
      )}&${qs.stringify(
        { title: data.title, content: data.content, page: data.page, limit: data.limit },
        { arrayFormat: "repeat", skipNulls: true }
      )}`
    );
  } else if (data.nowPath === "/news/event") {
    return http.get<any>(
      `/event${qs.stringify(
        {
          category: data.category,
          comparison: data.comparison,
          title: data.title,
          content: data.content,
          page: data.page,
          limit: data.limit,
        },
        { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
      )}`
    );
  }
};

// get news detail
export const getDetail = (data: { now: string; id: string }) => {
  return http.get<any>(
    `/${data.now === "update" ? "notice" : data.now}/detail${
      data.now === "notice" || data.now === "update"
        ? qs.stringify({ noticeId: data.id }, { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true })
        : data.now === "event"
        ? qs.stringify({ eventId: data.id }, { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true })
        : qs.stringify({ noticeId: data.id }, { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true })
    }`
  );
};

// post news
export const postNews = (data: {
  now: string;
  category: string;
  title: string;
  content: string;
  thumbnailImageUrl: string;
  summary: string;
  display: boolean;
  fix: boolean;
  assignedAt: Date;
  startDate: Date;
  endDate: Date;
}) => {
  return http.post<any, null>(`/${data.now === "update" ? "notice" : data.now}`, data);
};

// patch news
export const patchNews = (data: {
  now: string;
  category: string;
  title: string;
  content: string;
  thumbnailImageUrl: string;
  summary: string;
  display: boolean;
  fix: boolean;
  assignedAt: Date;
  startDate: Date;
  endDate: Date;
}) => {
  return http.patch<any, null>(`/${data.now === "update" ? "notice" : data.now}`, data);
};

// get image url
export const getImgUrl = () => {
  return http.get<any>(`/file/signedUrl/image?files[0]=image`);
};

// put image to S3
export const putImgToS3 = (data: { url: string; image: Blob }) => {
  return http.put<any>(`${data.url}`, data.image);
};
