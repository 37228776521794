import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GNBState, LOGINState, RootState, nowPageSetting } from "store/index";

const ContentsItem = () => {
  const dispatch = useDispatch();

  const gnb: GNBState = useSelector((state: RootState) => state.gnb);

  useEffect(() => {
    // dispatch(nowPageSetting(1)); // 필요한가
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content contents-item">
      <div className="content__inner">
        <p>개발중입니다.</p>
      </div>
    </div>
  );
};

export default ContentsItem;
