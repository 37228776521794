import { NormalButton } from "components/common";
import { useNavigate, useLocation } from "react-router-dom";
import "./AdminAccountDelete.scss";

const AdminAccountDelete = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = location.state;

  const handleOnSubmit = () => {
    // TODO: 삭제 API
    navigate("/account/admin");
  };

  return (
    <div className="content">
      <div className="content__inner admin-delete admin-main">
        <h1 className="admin__title">관리자 계정 삭제</h1>
        <ul className="admin_list">
          <li className="admin_item">
            <div className="label">관리자 그룹</div>
            <div className="content">{admin?.adminPermissionGroup?.name ? admin?.adminPermissionGroup?.name : "-"}</div>
          </li>
          <li className="admin_item">
            <div className="label">아이디</div>
            <div className="content input-wrap">{admin.accountId}</div>
          </li>
          <li className="admin_item">
            <div className="label">소속 부서</div>
            <div className="content input-wrap">{admin.department ? admin.department : "-"}</div>
          </li>
          <li className="admin_item">
            <div className="label">이름</div>
            <div className="content input-wrap">{admin.name}</div>
          </li>
        </ul>
        <div className="warning_phrase">
          <p>삭제된 관리자 계정은 복구가 되지 않습니다.</p>
        </div>
        <div className="btn_wrap">
          <NormalButton
            title="취소"
            size="md"
            hierarchy="secondary_gray"
            buttonStyle="box"
            onClick={() => navigate("/account/admin")}
          />
          <NormalButton
            title="저장"
            size="md"
            hierarchy="red"
            buttonStyle="box"
            onClick={handleOnSubmit}
            disable={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminAccountDelete;
