export const getBadge = ({ startDate, endDate }: any) => {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);
  if (start > now) {
    return "예정";
  } else if (start <= now && now <= end) {
    return "진행";
  } else if (end < now) {
    return "종료";
  }
};
