import React, { useEffect, useState } from "react";
import "./Checkbox.scss";

// TODO: 체크박스 common으로 합치기
type Prop = {
  item: any;
  checkedHandler: (id: string, checked: boolean) => void;
  checked?: boolean;
};

const Checkbox = ({ item, checked = false, checkedHandler }: Prop) => {
  const [check, setCheck] = useState<boolean>(false);

  useEffect(() => {
    setCheck(() => checked);
  }, [checked]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setCheck(!check);
    checkedHandler(item, e.target.checked);
  };

  return (
    <div className="checkbox">
      <div className="checkbox__wrapper">
        <input type="checkbox" id={item} value={item} onChange={handleOnChange} checked={check} />
        <label htmlFor={item} className="sm"></label>
      </div>
    </div>
  );
};

export default Checkbox;
