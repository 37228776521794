import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";
import { SearchCategory, Event, Comparison } from "types/board";

type Param = {
  page: number;
  limit: number;
  // category: EventCategory;
  keyword: string;
  comparison: Comparison;
  searchCategory: SearchCategory;
};

type GetEvent = {
  data: { list: Event[]; totalCount: number };
};

function getEvent({ page, limit, queryString }: { page: number; limit: number; queryString: string }) {
  // 현재 참여형인지 일반형인지의 분기처리는 없음
  return axios.get<GetEvent>(`/event?page=${page}&limit=${limit}${queryString}`);
}

const getQueryString = ({ keyword, comparison, searchCategory }: any) => {
  let queryString = "";

  if (comparison !== "ALL") {
    queryString += `&comparison=${comparison}`;
  }

  if (keyword) {
    switch (searchCategory) {
      case "TITLE":
        queryString += `&title=${keyword}`;
        break;
      case "CONTENT":
        queryString += `&content=${keyword}`;
        break;
      case "TITLE+CONTENT":
        queryString += `&title=${keyword}&content=${keyword}`;
        break;
    }
  }
  return queryString;
};

const useEvent = ({ page, limit, keyword, comparison, searchCategory }: Param) => {
  let queryKey = [queryKeys.eventList, page];
  const queryString = getQueryString({ keyword, comparison, searchCategory });

  if (keyword) {
    queryKey = [queryKeys.noticeList, page, comparison, `${searchCategory}=${keyword}`];
  }

  const { data, error, refetch } = useQuery({
    queryKey,
    queryFn: () => getEvent({ page, limit, queryString }),
    enabled: false,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("이벤트 리스트를 가져오는데 에러가 발생하였습니다.");
  }

  const eventList = data?.data.data.list;
  const totalPost = data?.data.data.totalCount;

  return { eventList, totalPost, error, refetch };
};

export default useEvent;
