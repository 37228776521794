import { forwardRef } from "react";
import classNames from "classnames";

import { ReactComponent as SearchIcon } from "assets/images/layer/search.svg";

import "./Inputbox.scss";

export interface InputBoxProps {
  name?: string;
  value?: string;
  onChange?: any;
  onKeyPress?: any;
  onBlur?: any;
  placeholder?: string;
  label?: string;
  icon?: any;
  type?: string;
  logMessage?: string;
  logType?: string;
  disabled?: boolean;
  readOnly?: boolean;
  // passwordRef?: any;
}

/**
 * @param name input에 들어갈 이름, label과 연결하기 위해서 사용
 * @param value 현재 설정되어 있는 기본 값
 * @param onChange onChnage함수로 동작하게 될 함수
 * @param placeholder placeholder값
 * @param icon icon의 여부
 * @param type input 타입 text/password 등
 *
 * input 아래에 경고 메시지가 생겨야 할 경우
 * @param logMessage 경고 메시지 text
 * @param logType 경고의 종류(info, error, warn)
 */
const InputBox = forwardRef(
  (
    {
      type,
      name,
      value,
      onChange,
      onKeyPress,
      placeholder,
      label,
      icon,
      logMessage,
      logType,
      disabled,
      onBlur,
      readOnly,
    }: InputBoxProps,
    ref: any
  ) => {
    const searchInput = () => {
      return (
        <div className="search_input">
          <SearchIcon />
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
      );
    };

    const defaultInput = () => {
      return (
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          onBlur={onBlur}
          disabled={disabled}
          readOnly={readOnly}
        />
      );
    };

    return (
      <div className="input_wrapper" ref={ref}>
        {label && <label htmlFor={name}>{label}</label>}
        {icon ? searchInput() : defaultInput()}
        {logMessage && (
          <p
            className={classNames(`log`, {
              info: logType === "info",
              error: logType === "error",
              warn: logType === "warn",
            })}
          >
            {logMessage}
          </p>
        )}
      </div>
    );
  }
);

export default InputBox;
