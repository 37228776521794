import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axios as axiosInstance } from "apis/http";
import axios from "axios";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import { Editor } from "@toast-ui/react-editor";
import { ko } from "date-fns/esm/locale";
import { PageTop, InputBox, EditorComponent, NormalButton } from "components/index";
import useAddUpdate from "hooks/board/update/useAddUpdate";

const MINIMUM_LENGTH = 2;

const UpdateWrite = () => {
  const navigate = useNavigate();
  const { addUpdate } = useAddUpdate();
  const editorRef = useRef<Editor>(null);

  const [summary, setSummary] = useState("");
  const [title, setTitle] = useState("");
  const [assignedDate, setAssignedDate] = useState(new Date());
  const [file, setFile] = useState<Blob | string>("");
  const [fileUrl, setFileUrl] = useState("");

  // 미리보기 이미지 파일 저장
  const saveFileImage = (e: any) => {
    setFileUrl(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
    e.target.value = "";
  };

  // 미리보기 이미지 파일 삭제
  const deleteFileImage = () => {
    URL.revokeObjectURL(fileUrl);
    setFileUrl("");
  };

  const handleOnSubmit = async (content: string | undefined) => {
    if (!content) {
      alert("내용을 입력하세요.");
      return;
    }
    if (!file) {
      alert("썸네일 이미지를 선택하세요.");
      return;
    }
    if (title.length < MINIMUM_LENGTH) {
      alert("제목은 두글자 이상이어야 합니다.");
      return;
    }
    if (summary.length < MINIMUM_LENGTH) {
      alert("요약은 두글자 이상이어야 합니다.");
      return;
    }

    await axiosInstance.get("/file/signedUrl/image?files[0]=image").then(({ data }) => {
      const imageUrl = data?.data?.signedUrlMap.image;
      axios
        .put(imageUrl, file)
        .then(() => {
          const postContent = {
            summary: summary,
            title: title,
            content,
            thumbnailImageUrl: imageUrl.substring(0, imageUrl.indexOf("?")),
            assignedAt: assignedDate.getTime(),
          };
          addUpdate(postContent);
        })
        .catch((e) => {
          console.error("Image upload failed:", e);
        })
        .finally(() => {
          navigate(-1);
        });
    });
  };

  return (
    <div className={classNames("write", "update")}>
      <div className="write__inner">
        <PageTop title="업데이트 등록" />
        <div className="board">
          <div className="board__title">
            <DatePicker
              selected={assignedDate}
              onChange={(date: any) => setAssignedDate(date)}
              locale={ko} // 한글로 변경
              dateFormat="yyyy-MM-dd aa hh:mm" // 시간 포맷 변경
              showPopperArrow={false} // 화살표 변경
              showTimeInput
            />
            <InputBox
              type="text"
              name="title"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(e.target.value);
              }}
              placeholder="제목을 입력해주세요. (최대  50자 이내)"
            />
          </div>
          <div className="board__title--bottom">
            <InputBox
              type="text"
              name="summary"
              value={summary}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSummary(e.target.value);
              }}
              placeholder="업데이트 요약 소개 (최대 80자 이내)"
            />
          </div>
          <div className="board__body--editor">
            <EditorComponent editorRef={editorRef} />
          </div>
          <div className="board__bottom">
            <div className="top">
              <p className="title">
                썸네일
                <span>이미지는 가로 571px 세로 286px 크기의 JPG, PNG, GIF만 업로드 가능 (최대 1MB)</span>
              </p>

              <div className="button--upload">
                <label className="button" htmlFor="file">
                  이미지 선택
                </label>
                <input className="hide" type="file" accept="image/*" onChange={saveFileImage} id="file" />
                <input className="hide" type="file" accept="image/*" id="file" />
              </div>
            </div>

            {fileUrl !== "" && (
              <div className="img--box">
                <img alt="uploadImg" src={fileUrl} />
                <div
                  className="button--file--delete"
                  onClick={() => {
                    deleteFileImage();
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
        <div className="write__bottom">
          <NormalButton
            title="취소"
            hierarchy="secondary_gray"
            onClick={() => {
              navigate(-1);
            }}
            buttonStyle="box"
            size="md"
          />
          <NormalButton
            title="등록"
            hierarchy="primary"
            buttonStyle="box"
            size="md"
            onClick={() => {
              const content = editorRef.current?.getInstance().getHTML();
              handleOnSubmit(content);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateWrite;
