import { useState, useCallback } from "react";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const sendRequest = useCallback(async (applyData: any, param: any) => {
    setIsLoading(true);
    setError(null);

    try {
      const data = await applyData(param);
      // console.log("param ", param);
      // console.log(JSON.stringify(data, null, `\t`));
      // console.log(data);
      // throw Error(data?.error);
      // if (data?.error) {
      // throw Error(data?.error);
      // TODO: error던져줄때 코드명도 보이게 나중에 수정하기
      // }
      return data;
    } catch (err: unknown) {
      console.error(err);
      setError(err);
      // return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
