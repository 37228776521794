const NOW_PAGE_SETTING = "gnb/NOW_PAGE_SETTING" as const;
type GNBAction = ReturnType<typeof nowPageSetting>;

export const nowPageSetting = (diff: number) => ({
  type: NOW_PAGE_SETTING,
  payload: diff,
});

export type GNBState = {
  nowPage: number | null;
};

const initialState: GNBState = {
  nowPage: null,
};

function gnb(state: GNBState = initialState, action: GNBAction): GNBState {
  switch (action.type) {
    case NOW_PAGE_SETTING:
      return { ...state, nowPage: action.payload };
    default:
      return state;
  }
}

export default gnb;
