import { useState, useRef, useEffect } from "react";
import { InputBox } from "components/common";
import { NormalButton } from "components/index";
import ModalPortal from "portals/ModalPortal";
import { AlertModal, ConfirmModal } from "components/index";
import usePassword from "hooks/demo/usePassword";
import useUpdatePassword from "hooks/demo/useUpdatePassword";
import "./demo.scss";

const PasswordPage = () => {
  const [successModal, setSuccessModal] = useState(false);
  const [isValidModalOpen, setIsValidModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [modalInfo, setModalInfo] = useState<string[]>(["", ""]);
  const { password, error } = usePassword();
  const [inputPassword, setInputPassword] = useState(password);
  const { updatePassword } = useUpdatePassword({ setSuccessModal, setIsValidModalOpen, setModalInfo });

  const validatePassword = () => {
    if (password === inputPassword) {
      setIsValidModalOpen(true);
      setModalInfo(["동일한 비밀번호", "기존과 동일한 비밀번호 입니다."]);
      return;
    }
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{4,10}$/;
    if (passwordRegex.test(inputPassword)) {
      updatePassword({ password: inputPassword });
    } else {
      setIsValidModalOpen(true);
      setModalInfo(["유효하지 않은 비밀번호", "비밀번호는 숫자, 영문, 특수문자 포함 최대 10자 이내로 입력해주세요."]);
    }
  };

  if (error) {
    alert("서버에서 비밀번호를 가져오는데 에러가 발생하였습니다. 잠시 후 다시 시도해주세요.");
  }

  useEffect(() => {
    if (password) {
      setInputPassword(password);
    }
  }, [password]);

  const handleCancel = () => {
    setInputPassword(password);
    passwordRef?.current?.querySelector("input")?.focus();
  };

  const handleSave = () => {
    validatePassword();
    passwordRef?.current?.querySelector("input")?.focus();
  };

  return (
    <div className="demo">
      <h1 className="title">접속 PW 관리</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validatePassword();
        }}
      >
        <div className="password-form">
          <div className="input-label">접속 비밀번호 설정</div>
          <div className="input-wrapper">
            <InputBox
              type="text"
              name="password"
              value={inputPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputPassword(e.target.value);
              }}
              ref={passwordRef}
            />
            <span className="info">비밀번호는 숫자, 영문, 특수문자 포함 최대 10자 이내로 생성 가능합니다.</span>
          </div>
        </div>
        <div className="button-wrapper">
          <NormalButton
            title="취소"
            size="md"
            hierarchy="secondary_gray"
            buttonStyle="box"
            width="120px"
            onClick={handleCancel}
          />
          <NormalButton
            title="저장"
            size="md"
            hierarchy="primary"
            buttonStyle="box"
            width="120px"
            onClick={handleSave}
          />
        </div>
      </form>
      {successModal && (
        <ModalPortal>
          <AlertModal modalRef={modalRef} setIsModalOpen={setSuccessModal} text="저장 완료 되었습니다." />
        </ModalPortal>
      )}

      {isValidModalOpen && (
        <ModalPortal>
          <ConfirmModal
            modalRef={modalRef}
            type="info"
            title={modalInfo[0]}
            description={modalInfo[1]}
            confirmButton="확인"
            setIsModalOpen={setIsValidModalOpen}
            confirmStyle="primary"
            handleOnConfirm={() => setIsValidModalOpen(false)}
          />
        </ModalPortal>
      )}
    </div>
  );
};

export default PasswordPage;
