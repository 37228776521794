import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { CategoryBox, PageTop, NormalButton } from "components/index";
import { useSelect } from "hooks/index";
import Search from "components/board/Search";
import EventList from "components/board/BoardList/EventList";
import useEvent from "hooks/board/event/useEvent";
import useDebounce from "hooks/useDebounce";
import { Comparison } from "types/board";

const SEARCH_CATEGORY = [
  { id: "TITLE", name: "제목" },
  { id: "CONTENT", name: "내용" },
  { id: "TITLE+CONTENT", name: "제목+내용" },
];

export const EVENT_CATEGORY = [
  { id: "ALL", title: "전체" },
  { id: "PRESENT", title: "진행" },
  { id: "FUTURE", title: "예정" },
  { id: "PAST", title: "종료" },
];

const PAGINATION = {
  pageRange: 5,
  btnRange: 10,
};

const DEBOUNCE_DELAY = 500;

const EventPage = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState<Comparison>("ALL");
  const [keyword, setKeyword] = useState(""); // 입력한 검색어 값
  const keywordType = useSelect(SEARCH_CATEGORY[0]);
  const [page, setPage] = useState(1); // 현재 페이지
  const {
    eventList = [],
    totalPost = 0,
    refetch,
  } = useEvent({
    page,
    limit: PAGINATION.pageRange,
    comparison: category,
    keyword,
    searchCategory: keywordType.value.id,
  });

  useEffect(() => {
    refetch();
  }, [page, category, keywordType.value]);

  const submitKeywords = () => {
    setPage(1);
    refetch();
  };

  console.log(eventList);

  useDebounce(keyword, DEBOUNCE_DELAY, submitKeywords);

  return (
    <div className={classNames("content", "event")}>
      <div className="content__inner">
        <PageTop title="이벤트" />
        <div className="board">
          <div className="board__top update">
            <CategoryBox data={EVENT_CATEGORY} category={category} setCategory={setCategory} />
            <div className="right">
              <Search categoryList={SEARCH_CATEGORY} keywordType={keywordType} setKeyword={setKeyword} />
            </div>
          </div>
          <div className="board__body">
            <EventList eventList={eventList} totalPost={totalPost} page={page} setPage={setPage} />
          </div>
          <div className="board__bottom">
            <NormalButton
              title="등록"
              size="md"
              hierarchy="primary"
              icon="false"
              buttonStyle="box"
              onClick={() => {
                navigate(`/news/event/write`);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPage;
