import { useState, useEffect } from "react";

import axios from "axios";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { RootState, PathState, ModalState, modalSetting, modalReportSetting, LOGINState } from "store/index";

import { boardTitle, REACT_APP_SERVER_URL } from "shared/utils";
import { dateToString, setCookie } from "shared/index";

import { NormalButton, PageTop, BoardList } from "components/index";

import { ReactComponent as CornerDownRightIcon } from "assets/images/layer/corner-down-right.svg";
import useHttp from "hooks/useHttp";
import { getDetail } from "apis/board";
import { AUTH_COOKIE } from "constants/index";

import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/react-editor";

interface CommentBoxProps {
  title: string;
  value: string;
  onChange?: any;
  placeHolder: string;
  limit: number;
  reply?: boolean;
}

const CommentBox = ({ title, value, onChange, placeHolder, limit, reply }: CommentBoxProps) => {
  return (
    <div className={classNames("comment__write", { reply: reply })}>
      <p className="comment__write__title">{title}</p>
      <div className="comment__write__box">
        <div className="comment__write__box__inner">
          <textarea
            style={{ resize: "none" }}
            value={value}
            onChange={onChange}
            placeholder={placeHolder}
            maxLength={limit}
          />
          <p className="limit">
            {value.length}/{limit}
          </p>
        </div>

        <NormalButton title="등록" buttonStyle="box" size="txl" hierarchy="secondary_gray" />
      </div>
    </div>
  );
};

interface CommentProps {
  reply?: boolean;
  reReply?: boolean;
  loggedInUser?: string;
}

const Comment = ({ reply, reReply, loggedInUser }: CommentProps) => {
  const [value, setValue] = useState("");
  const [editValue, setEditValue] = useState("댓글입니다.");
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);

  // 로그인 되었는지 체크하기 위한 dummy data -> user ID
  const [loggedIn, setLoggedIn] = useState("testId1");

  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const dispatch = useDispatch();

  const handleDelete = () => {};

  return (
    <>
      <div
        className={classNames(
          "comment__board__list",
          { reply: reply },
          { re_reply: reReply },
          { logged_in: loggedInUser === loggedIn }
        )}
      >
        <div className="top">
          <div className="user">
            <CornerDownRightIcon />
            <img className="user__profile" src="" alt="user profile" />
            <p className="user__id">유저 아이디</p>
          </div>
          <div className="box">
            <p className="date">2023-02-23</p>
            <NormalButton
              title="신고"
              hierarchy="tertiary_gray"
              icon="leading"
              buttonStyle="round"
              size="xs"
              onClick={() => {
                dispatch(modalReportSetting({ open: !modal.open, type: "report" }));
              }}
            />
          </div>
        </div>
        {edit ? (
          <textarea
            style={{ resize: "none" }}
            value={editValue}
            onChange={(e: any) => {
              setEditValue(e.target.value);
            }}
            placeholder={
              "저작권 등 다른 사람의 권리를 침해하거나 명예를 훼손하는 게시물은 이용약관 및 관련 법률에 의해 제재를 받을 수 있습니다.&#13;&#10;건전한 토론 문화와 양질의 댓글 문화를 위해, 타인에게 불쾌감을 주는 욕설 또는 특정 계층/민족, 종교 등을 비하하는 단어들은 표시가 제한 됩니다."
            }
            maxLength={200}
          />
        ) : (
          <p className="description">{editValue}</p>
        )}
        <div className="bottom">
          <div className="bottom__right">
            {!reReply && (
              <NormalButton
                title={open ? "취소" : "답글작성"}
                buttonStyle="round"
                size="xs"
                hierarchy="primary_line"
                onClick={() => {
                  setOpen(!open);
                }}
              />
            )}
            <NormalButton
              title={edit ? "저장" : "수정"}
              buttonStyle="round"
              size="xs"
              hierarchy="secondary_color"
              onClick={() => {
                setEdit(!edit);
              }}
            />
          </div>

          <NormalButton
            title="삭제"
            buttonStyle="round"
            size="xs"
            hierarchy="secondary_gray"
            onClick={() => {
              dispatch(
                modalSetting({
                  open: !modal.open,
                  type: "danger",
                  title: "삭제하기",
                  description: "정말로 글을 삭제하시겠습니까?",
                  confirmButton: "삭제하기",
                  confirmStyle: "red",
                  onConfirm: handleDelete,
                })
              );
            }}
          />
        </div>
      </div>
      {open && (
        <CommentBox
          title="답글 작성"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
          }}
          placeHolder="저작권 등 다른 사람의 권리를 침해하거나 명예를 훼손하는 게시물은 이용약관 및 관련 법률에 의해 제재를 받을 수 있습니다.&#13;&#10;건전한 토론 문화와 양질의 댓글 문화를 위해, 타인에게 불쾌감을 주는 욕설 또는 특정 계층/민족, 종교 등을 비하하는 단어들은 표시가 제한 됩니다."
          limit={200}
          reply={reply}
        />
      )}
    </>
  );
};

interface CommentListProps {
  loggedInUser: string;
}

const CommentList = ({ loggedInUser }: CommentListProps) => {
  return (
    <>
      {/* 댓글 */}
      <Comment loggedInUser={loggedInUser} />
      {/* 대댓글 .map 돌리기 */}
      <Comment reply loggedInUser={loggedInUser} />
      {/* 대대댓글 .map 돌리기 */}
      <Comment reReply loggedInUser={loggedInUser} />
    </>
  );
};

const Detail = () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState("");
  const [likeOn, setLikeOn] = useState(false);

  const path: PathState = useSelector((state: RootState) => state.path);
  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const login: LOGINState = useSelector((state: RootState) => state.login);

  const { isLoading, sendRequest } = useHttp();

  // 현재 로그인 된 유저 아이디 dummy data
  // const [loggedInUser, setLoggedInUser] = useState("testId");

  const pageId = location.state?.id;

  const [detailData, setDetailData] = useState<any>([]);

  const _getDetail = async () => {
    const { data: result } = await sendRequest(getDetail, {
      now: path.nowPath,
      id: pageId,
    });

    console.log(result); // {error: true, code 'TOKEN_EXPIRATION'}

    if (result?.error || result === undefined) {
      if (result?.code === "TOKEN_EXPIRATION") {
        setCookie(AUTH_COOKIE, "", null);
        navigate("/login");
      }
    } else {
      setDetailData(result);
    }

    // console.log(result.list);
    // setListData(result.list);
  };

  // const DeleteApi = async () => {
  //   await axios
  //     .delete(`${REACT_APP_SERVER_URL}/${boardType}/${pathType}/${pageId}`, {
  //       headers: {
  //         Authorization: sessionStorage.getItem(`accessToken`),
  //       },
  //     })
  //     .then(({ data }) => {
  //       console.log(data.response);
  //       setTimeout(() => {
  //         alert("삭제되었습니다");
  //       }, 2000);
  //       navigate(-1);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       // alert("개발팀에 문의해주세요.");
  //     });
  // };

  useEffect(() => {
    _getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div
      className={classNames(
        "detail",
        // { my_post: detailData.name === loggedInUser },
        path.nowPath
      )}
    >
      <div className="detail__inner">
        {/* page title */}
        <PageTop title={boardTitle(path.nowPath)} />

        {/* title */}
        <BoardList data={detailData} key={detailData?.id} background="white" detail />

        {path.nowPath === "event" && (
          <div className="data__box">
            <div className="box">
              <p className="title">이벤트 기간</p>
              <p className="date">
                {dateToString(detailData.startDate, "yyyy.MM.dd hh:mm", i18n.language)} ~{" "}
                {dateToString(detailData.endDate, "yyyy.MM.dd hh:mm", i18n.language)}
              </p>
            </div>
          </div>
        )}

        {/* board */}
        <div className="board border">
          {/* 여기에 에디터에서 받아온 내용 껴넣기 */}
          <div className="board__inner">
            {detailData?.thumbnail !== null && detailData?.thumbnail !== undefined && detailData?.thumbnail !== "" && (
              <img src={`${detailData.thumbnail}`} alt={detailData.title} />
            )}
            {detailData?.file !== null &&
              detailData?.file !== undefined &&
              detailData?.file !== "" &&
              detailData?.file !== "false" &&
              detailData?.file !== false && <img src={`${detailData.file}`} alt={detailData.title} />}
            {detailData.content && (
              <Viewer
                initialValue={detailData.content || ""} // 유튜브 삽입 및 미리보기 를 위한 설정(iframe)
                customHTMLRenderer={{
                  htmlBlock: {
                    iframe(node: any) {
                      return [
                        {
                          type: "openTag",
                          tagName: "iframe",
                          outerNewLine: true,
                          attributes: node.attrs,
                        },
                        { type: "html", content: node.childrenHTML },
                        { type: "closeTag", tagName: "iframe", outerNewLine: false },
                      ];
                    },
                  },
                }}
              />
            )}
            {/* <div dangerouslySetInnerHTML={{__html : detailData.content}}></div> */}
          </div>

          {/* board 하단 like/report 버튼 */}
          {(path.nowPath === "free-board" || path.nowPath === "tip-board" || path.nowPath === "screenshot-board") && (
            <div className="board__buttons">
              {/* like button */}
              {/* <NormalButton
                  title={String(detailData.views)}
                  buttonStyle="round"
                  size="md"
                  hierarchy="secondary_gray"
                  icon="leading"
                  onClick={() => {
                    setLikeOn(!likeOn);
                  }}
                  buttonClassName={classNames({ on: likeOn })}
                /> */}

              {/* report button */}
              {/* <NormalButton
                  title="신고"
                  buttonStyle="round"
                  size="xs"
                  hierarchy="tertiary_gray"
                  icon="leading"
                  onClick={() => {
                    dispatch(modalReportSetting({ open: !modal.open, type: "report" }))
                  }}
                /> */}
            </div>
          )}
        </div>

        {/* comment */}
        {/* {(path.nowPath === "free-board" || path.nowPath === "tip-board" || path.nowPath === "screenshot-board" || path.nowPath === "inquiry") && (
          <div className="comment">

            <CommentBox
              title={path.nowPath === "inquiry" ? "문의 댓글" : detailData.comment === null ? "" : detailData.comment === undefined ? "" : "댓글 " + detailData.comment.length}
              value={inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
              }}
              placeHolder="저작권 등 다른 사람의 권리를 침해하거나 명예를 훼손하는 게시물은 이용약관 및 관련 법률에 의해 제재를 받을 수 있습니다.&#13;&#10;건전한 토론 문화와 양질의 댓글 문화를 위해, 타인에게 불쾌감을 주는 욕설 또는 특정 계층/민족, 종교 등을 비하하는 단어들은 표시가 제한 됩니다."
              limit={200}
            />

            <div className="comment__board">
              <CommentList loggedInUser={loggedInUser} />
            </div>
          </div>
        )} */}

        {/* detail bottom */}
        <div className="detail__inner__bottom">
          {login.login && (
            <NormalButton
              title="삭제"
              buttonStyle="box"
              size="md"
              hierarchy="secondary_gray"
              onClick={() => {
                console.log("삭제 버튼 누름");
                // DeleteApi();
              }}
            />
          )}

          <div className="right">
            {((login.login && (path.nowPath === "notice" || path.nowPath === "update" || path.nowPath === "event")) ||
              detailData.notice) && (
              <NormalButton
                title="수정"
                buttonStyle="box"
                size="md"
                hierarchy="primary_line"
                onClick={() => {
                  navigate(
                    `/${
                      path.nowPath === "notice" || path.nowPath === "update" || path.nowPath === "event"
                        ? "news"
                        : "community"
                    }/${path.nowPath}/write`,
                    { state: { id: detailData.id } }
                  );
                }}
              />
            )}
            <NormalButton
              title="목록"
              buttonStyle="box"
              size="md"
              hierarchy="primary"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
