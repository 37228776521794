import FileItem from "./FileItem";
import { LinkItem, VideoItem } from "types/demo";

type FileListType = {
  list: LinkItem[] | VideoItem[];
  handleOnDelete: (id: string) => void;
};

const FileList = ({ list = [], handleOnDelete }: FileListType) => {
  const emptyContent = <div className="empty-content">내용이 없습니다.</div>;
  const fileList = [...list].sort((a: any, b: any) => {
    return new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime();
  });

  return (
    <>
      <h3 className="saved-file-label">등록된 파일 목록</h3>
      <div className="saved-file-list">
        <div className="head-row row">
          <div className="cell">파일명</div>
          <div className="cell">업로드날짜</div>
          <div className="cell">관리</div>
        </div>
        {fileList?.map((item) => (
          <FileItem key={item.id} item={item} handleOnDelete={handleOnDelete} />
        ))}
      </div>
      {list?.length === 0 && emptyContent}
    </>
  );
};

export default FileList;
