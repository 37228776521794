import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { dateToString } from "shared/index";
import Pagination from "components/common/pagination/Pagination";
import { PAGINATION } from "pages/Board/Notice/NoticeListPage";
import EmptyList from "./EmptyList";
import { Notice } from "types/board";
import { isNewPost } from "../utils";
import "./BoardList.scss";

type NoticeListType = {
  noticeList: Notice[] | [];
  totalPost: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const NOTICE_TYPE: { [key: string]: string } = {
  NOTICE: "공지",
  MAINTENANCE: "점검",
  MARKET: "상점",
};

const NoticeList = ({ noticeList, totalPost, page, setPage }: NoticeListType) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {noticeList?.length > 0 &&
        noticeList?.map(({ id, isFixed, category, title, assignedAt, views }: Notice, index: number) => (
          <div
            key={`${id}-${index}`}
            className={classNames("board_list", "notice", { fixed: isFixed })}
            onClick={() => {
              navigate(`/news/notice/detail`, { state: { id: id } });
            }}
          >
            <p className="id">{index + 1}</p>
            <p className="category__outline">
              <span className={NOTICE_TYPE[category]}>{NOTICE_TYPE[category]}</span>
            </p>
            <div className="title_box">
              <p className="title">{title}&nbsp;</p>
              {isNewPost(assignedAt) && <span className="new-post" />}
            </div>
            <p className="date">{dateToString(assignedAt, "yyyy.MM.dd", i18n.language)}</p>
            <p className="view">{views === null || views === undefined ? "0" : views}</p>
          </div>
        ))}

      {noticeList?.length > 0 && (
        <div className="paging-wrap">
          <Pagination
            page={page}
            setPage={setPage}
            totalPost={totalPost}
            btnRange={PAGINATION.btnRange}
            pageRange={PAGINATION.pageRange}
          />
        </div>
      )}
      {noticeList?.length === 0 && <EmptyList />}
    </>
  );
};

export default NoticeList;
