import { Dispatch, SetStateAction } from "react";
import classNames from "classnames";

import "./CategoryBox.scss";

export interface CategoryBoxProps {
  data: any;
  category: any;
  setCategory: Dispatch<SetStateAction<any>>;
}

/**
 * Board Category Selector Box
 * @data 카테고리 목록
 * @category 현재 카테고리
 * @setCategory 카테고리 설정
 */
const CategoryBox = ({ data, category, setCategory }: CategoryBoxProps) => {
  const CategoryList = (data: any) => {
    return (
      <li
        className={classNames({ on: category === data.data.id })}
        onClick={() => {
          setCategory(data.data.id);
        }}
      >
        {data.data.title}
      </li>
    );
  };

  return (
    <ul className="category_box">
      {data.map((a: any, idx: number) => (
        <CategoryList data={a} key={idx} />
      ))}
    </ul>
  );
};

export default CategoryBox;
