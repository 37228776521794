import { AxiosError } from "axios";
import { axios } from "apis/http";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { useRef } from "react";
import { useSetPermission } from "hooks/account/admin";

const ERROR_MESSAGE: { [key: string]: string } = {
  ALREADY_EXIST: "이미 그룹명이 존재합니다.",
  DEFAULT: "에러가 발생하였습니다.",
};

// 그룹명 수정
export function patchGroupName(data: { adminPermissionGroupId: string; name: string }) {
  return axios.put(`/permission/group`, data);
}

const useUpdateGroup = () => {
  const { setPermission } = useSetPermission();
  const location = useLocation();
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const { group } = location.state;

  const updateGroup = ({ adminPermissionGroupId, name, permissions }: any) => {
    ref.current = { adminPermissionGroupId, name, permissions };
    if (name !== group.name) {
      updateGroupName({ adminPermissionGroupId, name });
    } else {
      setPermission({ adminPermissionGroupId, permissions: ref.current.permissions });
    }
  };

  const { mutate: updateGroupName } = useMutation({
    mutationFn: (data: { adminPermissionGroupId: string; name: string }) => patchGroupName(data),
    onSuccess: () => {
      setPermission({
        adminPermissionGroupId: ref?.current?.adminPermissionGroupId,
        permissions: ref.current.permissions,
      });
      navigate("/account/admin/group");
    },
    onError: (error: unknown) => {
      if (error instanceof AxiosError) {
        console.error(error);
        const errorType = error.response?.data.code || "DEFAULT";
        alert(ERROR_MESSAGE[errorType]);
      }
    },
  });

  return { updateGroup };
};

export default useUpdateGroup;
