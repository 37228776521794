const ContentsAni = () => {
  return (
    <div className="content contents-ani">
      <div className="content__inner">
        <p>개발중입니다.</p>
      </div>
    </div>
  );
};

export default ContentsAni;
