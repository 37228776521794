import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";
import { useLocation } from "react-router-dom";

type AssignedPermission = {
  id: string;
  adminPermissionGroupId: string;
  code: string;
  canEdit: boolean;
};

// 그룹에 할당된 권한 목록
function getAssignedPermission(adminPermissionGroupId: string) {
  return axios.get<{ data: { list: AssignedPermission[] } }>(
    `/permission/group/assign?adminPermissionGroupId=${adminPermissionGroupId}`
  );
}

const useAssignedPermission = () => {
  const location = useLocation();
  const { group } = location.state;

  const queryKey = [queryKeys.groupList, group?.id];
  const { data: selectedPermissions = [], error } = useQuery({
    queryKey,
    queryFn: () => getAssignedPermission(group?.id),
    select: (data) => data.data.data.list,
    enabled: !!group?.id,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("그룹 권한 리스트를 가져오는데 에러가 발생하였습니다.", error);
  }

  return { selectedPermissions };
};

export default useAssignedPermission;
