import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAdmin } from "hooks/account/admin";
import Pagination from "components/common/pagination/Pagination";
import usePagination from "components/common/pagination/usePagination";
import { dateToString } from "shared/index";
import { NormalButton } from "components/index";
import { AdminList } from "types/account";
import "./AdminAccountList.scss";

const EMPTY_MESSAGE = {
  DEPARTMENT: "소속된 부서가 없습니다.",
  ACCESS_DATE: "최근 접속일자가 없습니다.",
  GROUP: "관리 그룹이 없습니다.",
};

const PAGINATION = {
  pageRange: 10,
  btnRange: 10,
};

// TODO: : 관리 그룹 생성의 권한이 있는지 없는지를 파악하여 버튼 막는거 해야함
const AdminAccountList = () => {
  const navigate = useNavigate();
  const [authRegister, setAuthRegister] = useState(true);
  const { adminList, error } = useAdmin();
  const { page, currentList, setPage, totalPost } = usePagination({ pageRange: PAGINATION.pageRange, list: adminList });

  const contentHTML =
    currentList.length > 0 &&
    currentList.map((admin: AdminList) => (
      <tr key={admin.id}>
        <td>{admin.adminPermissionGroup ? admin.adminPermissionGroup.name : EMPTY_MESSAGE.GROUP}</td>
        <td className="id__column">{admin.accountId}</td>
        <td>{admin.department ? admin.department : EMPTY_MESSAGE.DEPARTMENT}</td>
        <td>{admin.name}</td>
        <td>
          {admin.accessedAt ? dateToString(admin.accessedAt, "yyyy.MM.dd hh:mm:ss", "ko") : EMPTY_MESSAGE.ACCESS_DATE}
        </td>
        <td className="btn__wrap">
          <NormalButton
            title="수정"
            size="xs"
            hierarchy="primary_line"
            buttonStyle="box"
            onClick={() => navigate("/account/admin/save", { state: { type: "update", admin } })}
          />
          <NormalButton
            title="삭제"
            size="xs"
            hierarchy="secondary_gray"
            buttonStyle="box"
            onClick={() => navigate("/account/admin/delete", { state: { admin } })}
          />
        </td>
      </tr>
    ));

  const emptyContent = (
    <tr>
      <td colSpan={6}>{error ? "관리자 계정을 가져오지 못했습니다." : "둥록된 관리자가 없습니다."}</td>
    </tr>
  );

  return (
    <div className="content">
      <div className="content__inner admin-main">
        <h1 className="admin__title">관리자 계정 관리</h1>
        <table className="admin__table admin_list_table">
          <thead>
            <tr>
              <th>관리그룹</th>
              <th>ID</th>
              <th>소속 부서</th>
              <th>담당자명</th>
              <th>최근접속일</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>{totalPost === 0 ? emptyContent : contentHTML}</tbody>
        </table>
        <div className="admin__bottom">
          <div className="paging-wrap">
            <Pagination
              page={page}
              setPage={setPage}
              totalPost={totalPost}
              btnRange={PAGINATION.btnRange}
              pageRange={PAGINATION.pageRange}
            />
          </div>
          {authRegister && (
            <NormalButton
              title="관리자 등록"
              size="md"
              hierarchy="primary"
              buttonStyle="box"
              width="120px"
              onClick={() => {
                navigate("/account/admin/save", { state: { type: "create" } });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAccountList;
