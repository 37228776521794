import { axios, ResponseDataType } from "apis/http";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { queryKeys } from "react-query/querykey";

export type PostAdminType = {
  accountId: string;
  password: string;
  name: string;
  nickname?: String;
  adminGroupId?: string;
  department?: string;
};

const ERROR_MESSAGE: { [key: string]: string } = {
  ACCOUNT_ID_ALREADY_EXIST: "아이디가 존재합니다.",
};

const postAdmin = (admin: PostAdminType) => {
  return axios.post<PostAdminType, null>("/adminUser", admin);
};

const useAddAdmin = () => {
  const queryKey = [queryKeys.adminList];
  const queryClient = useQueryClient();

  const { mutate: addAdmin } = useMutation({
    mutationFn: (admin: PostAdminType) => postAdmin(admin),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        console.error("관리자 계정을 등록하는데 오류가 발생하였습니다.", error);
        const errorType = error.response?.data.code;
        if (errorType) alert(ERROR_MESSAGE[errorType]);
      }
    },
  });

  return { addAdmin };
};

export default useAddAdmin;
