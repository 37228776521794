import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import { Editor } from "@toast-ui/react-editor";
import { ko } from "date-fns/esm/locale";
import { PageTop, CheckBox, SelectBox, InputBox, EditorComponent, NormalButton } from "components/index";
import { useSelect, useCheckbox } from "hooks/index";
import useUpdateNotice from "hooks/board/notice/useUpdateNotice";

const MINIMUM_TITLE_LENGTH = 2;
const NOTICE_CATEGORY = [
  { id: "NOTICE", name: "공지" },
  { id: "MAINTENANCE", name: "점검" },
  { id: "MARKET", name: "상점" },
];

const NoticeUpdate = () => {
  const location = useLocation();
  const { content } = location.state;
  const navigate = useNavigate();
  const editorRef = useRef<Editor>(null);
  const [title, setTitle] = useState(content?.title);
  const isFixed = useCheckbox(content?.isFixed);
  const isExposed = useCheckbox(!content?.isExposed);
  const [assignedDate, setAssignedDate] = useState(new Date(content?.assignedAt));
  const { updateNotice } = useUpdateNotice();

  const selectData = useSelect(NOTICE_CATEGORY.find((category) => category.id === content.category));

  useEffect(() => {
    editorRef.current?.getInstance().setHTML(content.content);
  }, []);

  const handleOnSubmit = () => {
    if (title.length < MINIMUM_TITLE_LENGTH) {
      alert("제목은 두글자 이상이어야 합니다.");
      return;
    }
    const newContent = editorRef.current?.getInstance().getHTML();
    if (!newContent) {
      alert("내용을 입력하세요.");
      return;
    }
    updateNotice({
      noticeId: content.id,
      title: title,
      content: newContent,
      isFixed: isFixed.value,
      assignedAt: assignedDate.getTime(),
      category: selectData.value.id,
      isExposed: !isExposed.value,
    });
  };

  return (
    <div className={classNames("write", "notice")}>
      <div className="write__inner">
        <PageTop title="공지사항 수정" />
        <div className="board">
          <div className="board__title">
            <SelectBox name="category" data={NOTICE_CATEGORY} selectData={selectData} classification />
            <InputBox
              type="text"
              name="title"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(e.target.value);
              }}
              placeholder="제목을 입력해주세요. (최대  50자 이내)"
            />
          </div>
          <div className="board__title--bottom">
            <DatePicker
              selected={assignedDate}
              onChange={(date: Date) => setAssignedDate(date)}
              locale={ko} // 한글로 변경
              dateFormat="yyyy-MM-dd aa hh:mm" // 시간 포맷 변경
              showPopperArrow={false} // 화살표 변경
              showTimeInput
            />
            <div className="right">
              <div className="check_box">
                <CheckBox
                  name="noti_list"
                  value={isFixed.value}
                  checked={isFixed.value}
                  onClick={isFixed.onClick}
                  size="sm"
                  label="리스트 상단 고정"
                />
                <p className="title">공지 (리스트 상단 고정)</p>
              </div>
              <div className="check_box">
                <CheckBox
                  name="show_list"
                  value={isExposed.value}
                  onClick={isExposed.onClick}
                  size="sm"
                  label="미노출"
                  checked={isExposed.value}
                />
                <p className="title">미노출</p>
              </div>
            </div>
          </div>
          <div className="board__body--editor">
            <EditorComponent editorRef={editorRef} />
          </div>
        </div>
        <div className="write__bottom">
          <NormalButton
            title="취소"
            hierarchy="secondary_gray"
            onClick={() => {
              navigate(-1);
            }}
            buttonStyle="box"
            size="md"
          />
          <NormalButton
            title={location?.state ? "수정" : "등록"}
            hierarchy="primary"
            buttonStyle="box"
            size="md"
            onClick={() => {
              handleOnSubmit();
              if (location.state?.id) {
                // dispatch(
                //   modalSetting({
                //     open: !modal.open,
                //     type: "success",
                //     title: "수정하기",
                //     description: "정말로 수정 하시겠습니까?",
                //     confirmButton: "수정하기",
                //     confirmStyle: "primary",
                //     onConfirm: EmptyValueWarning,
                //   })
                // );
              } else {
                // dispatch(
                //   modalSetting({
                //     open: !modal.open,
                //     type: "success",
                //     title: "등록하기",
                //     description: "정말로 등록 하시겠습니까?",
                //     confirmButton: "등록하기",
                //     confirmStyle: "primary",
                //     onConfirm: EmptyValueWarning,
                //   })
                // );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NoticeUpdate;
