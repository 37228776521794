import { axios, ResponseDataType } from "apis/http";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { queryKeys } from "react-query/querykey";
import { useNavigate } from "react-router-dom";
import { EventCategory } from "types/board";

export type Param = {
  eventId: string;
  title: string;
  content?: string;
  category: EventCategory;
  isExposed: boolean;
  startDate: number;
  endDate: number;
  thumbnailImageUrl?: string;
};

const ERROR_MESSAGE: { [key: string]: string } = {
  // ACCOUNT_ID_ALREADY_EXIST: "아이디가 존재합니다.",
  // NICKNAME_ALREADY_EXIST: "닉네임이 존재합니다.",
};

const putEvent = (eventContent: Param) => {
  return axios.put<any, null>("/event", eventContent);
};

const useUpdateEvent = () => {
  const navigate = useNavigate();
  const queryKey = [queryKeys.eventList];
  const queryClient = useQueryClient();

  const { mutate: updateEvent } = useMutation({
    mutationFn: (eventContent: Param) => putEvent(eventContent),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      navigate("/news/event");
    },
    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        const errorType = error.response?.data.code;
        console.error(errorType, error);
        if (errorType) alert(ERROR_MESSAGE[errorType]);
      }
    },
  });

  return { updateEvent };
};

export default useUpdateEvent;
