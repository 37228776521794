import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InputBox, NormalButton } from "components/index";
import { getCookie } from "shared/utils";
import useInput from "hooks/useInput";
import { AUTH_COOKIE } from "constants/index";
import useSignIn from "hooks/useSignIn";
import "./Login.scss";

const ERROR_MESSAGE: { [key: string]: string } = {
  NOT_EXIST: "아이디가 존재하지 않습니다.",
  INVALID_PASSWORD: "패스워드가 일치하지 않습니다.",
  EMPTY_VALUE: "아이디 또는 비밀번호를 입력하세요.",
  DEFAULT: "에러가 발생하였습니다.",
  TOKEN_EXPIRATION: "만료된 토큰입니다.", // TODO: redirect 하기
};

const Login = () => {
  const id = useInput("");
  const password = useInput("");
  const navigate = useNavigate();
  const { mutate: signIn } = useSignIn();

  useEffect(() => {
    const token = getCookie(AUTH_COOKIE);
    if (token) navigate(`/`);
  }, [navigate]);

  const handleOnLogin = async () => {
    const isEmptyInput = id.value === "" || password.value === "";
    if (isEmptyInput) {
      alert(ERROR_MESSAGE.EMPTY_VALUE);
      return;
    }

    signIn({ accountId: id.value, password: password.value });
  };

  const handlePressEnter = (event: React.KeyboardEvent<object>) => {
    if (event.code !== "Enter") return;
    handleOnLogin();
  };

  return (
    <div className="content login">
      <div className="login-member">
        <div className="login-member__base">
          <div className="login-member__top">
            <div className="login-member__header">
              <p className="login-member__title">ZEMIWORLD</p>
              <p className="login-member__description">Administrator Login</p>
            </div>
            <form className="login-member__form">
              <InputBox
                name="id"
                value={id.value}
                onChange={id.onChange}
                placeholder="ID"
                type="text"
                onKeyPress={handlePressEnter}
              />
              <InputBox
                name="password"
                value={password.value}
                onChange={password.onChange}
                placeholder="PW"
                type="password"
                onKeyPress={handlePressEnter}
              />
            </form>
            <NormalButton
              title="LOGIN"
              buttonStyle="box"
              hierarchy="primary"
              size="md"
              width={"100%"}
              onClick={handleOnLogin}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
