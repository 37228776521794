import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";
import { AdminList } from "types/account";

// 관리 계정 목록
function getAdmin() {
  return axios.get<{ data: { list: AdminList[] } }>("/adminUser");
}

const useAdmin = () => {
  const queryKey = [queryKeys.adminList];

  const { data: adminList, error } = useQuery({
    queryKey,
    queryFn: () => getAdmin(),
    select: (data) => data.data.data.list,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("관리자 계정 리스트를 가져오는데 에러가 발생하였습니다.");
  }

  return { adminList, error };
};

export default useAdmin;
