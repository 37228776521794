const LOGIN_SETTING = "login/LOGIN_SETTING" as const;

type LOGINAction = ReturnType<typeof loginSetting>;

export const loginSetting = (diff: {
  login: boolean;
  level?: number;
  name?: string;
  nickname?: string;
  userid?: string;
  id?: string;
}) => ({
  type: LOGIN_SETTING,
  payload: diff,
});

export type LOGINState = {
  login: boolean;
  level?: number;
  name?: string;
  nickname?: string;
  userid?: string;
  id?: string;
};

const initialState: LOGINState = {
  login: false,
  level: 0,
  name: "",
  nickname: "",
  userid: "",
  id: "",
};

function login(state: LOGINState = initialState, action: LOGINAction): LOGINState {
  switch (action.type) {
    case LOGIN_SETTING:
      return {
        ...state,
        login: action.payload.login,
        level: action.payload.level,
        name: action.payload.name,
        nickname: action.payload.nickname,
        userid: action.payload.userid,
        id: action.payload.id,
      };
    default:
      return state;
  }
}

export default login;
