import { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import axios from "axios";

import classNames from "classnames";
import DatePicker from "react-datepicker";
import { Editor } from "@toast-ui/react-editor";
// import useHttp from "hooks/useHttp";
// import { postNews, getImgUrl, patchNews, getDetail } from "apis/board";
// import { dateToString, setCookie } from "shared/index";

import { ko } from "date-fns/esm/locale";

import { PageTop, CheckBox, SelectBox, InputBox, EditorComponent, NormalButton } from "components/index";
import { RootState, PathState, ModalState, loadingSetting, modalSetting, LOGINState } from "store/index";
import { boardTitle, REACT_APP_SERVER_URL } from "shared/utils";
import { useSelect, useInput, useCheckbox } from "hooks/index";
// import { AUTH_COOKIE } from "constants/index";

const Write = () => {
  // const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const editorRef = useRef<Editor>(null);
  // var regex = /<img[^>]*src="([^"]*)"/;

  // const modal: ModalState = useSelector((state: RootState) => state.modal);
  // const login: LOGINState = useSelector((state: RootState) => state.login);
  const path: PathState = useSelector((state: RootState) => state.path);

  const CategoryList = [
    { id: 0, title: "공지" },
    { id: 1, title: "점검" },
    { id: 2, title: "상점" },
  ];

  const [title, setTitle] = useState("");
  // const [detailSummary, setDetailSummary] = useState("");
  // const { isLoading, sendRequest } = useHttp();

  const pageId = location.state?.id;

  // 공지(리스트 상단고정)
  const noti = useCheckbox(false);

  // 리스트에 보여줄 것인지 여부
  const display = useCheckbox(false);

  // 날짜 선택
  const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const selectData = useSelect(CategoryList[0]);
  // const [detailData, setDetailData] = useState<any>([]);

  //파일 미리볼 url을 저장해줄 state
  // const [fileImage, setFileImage] = useState("");
  // const [file, setFile] = useState<Blob | string>("");
  // const [fileUrl, setFileUrl] = useState("");

  // useEffect(() => {
  //   // 1. DB에서 가져온 HTML이라고 가정
  //   const htmlString = "";
  //   // 2. Editor DOM 내용에 HTML 주입
  //   editorRef.current?.getInstance().setHTML(htmlString);
  // }, []);

  // useEffect(() => {
  //   console.log("??", location.state);
  //   if (location.state?.id) {
  //     _getDetail();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.state]);

  // useEffect(() => {
  //   setTitle(detailData.title);
  //   setDetailSummary(detailData.summary);
  //   if (path.nowPath === "update") {
  //     if (detailData.updatedAt) {
  //       setStartDate(new Date(detailData.updatedAt));
  //     }
  //   } else if (path.nowPath === "event") {
  //     if (detailData.startDate) {
  //       setStartDate(new Date(detailData.startDate));
  //     }
  //     if (detailData.endDate) {
  //       setEndDate(new Date(detailData.endDate));
  //     }
  //   }
  //   editorRef.current?.getInstance().setHTML(detailData.content);

  //   if (
  //     detailData.thumbnailImageUrl !== undefined ||
  //     detailData.thumbnailImageUrl !== null ||
  //     detailData.thumbnailImageUrl !== ""
  //   ) {
  //     setFileUrl(`${detailData.thumbnailImageUrl}`);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [detailData]);

  // 이미지 파일 저장
  // const saveFileImage = (e: any) => {
  //   setFileUrl(URL.createObjectURL(e.target.files[0]));
  //   setFile(e.target.files[0]);
  //   // console.log(e.target.files[0]);
  //   e.target.value = "";
  // };

  // 이미지 파일 삭제
  // const deleteFileImage = () => {
  //   URL.revokeObjectURL(fileUrl);
  //   setFileUrl("");
  // };

  // const _getDetail = async () => {
  //   const { data: result } = await sendRequest(getDetail, {
  //     now: path.nowPath,
  //     id: pageId,
  //   });

  //   console.log(result); // {error: true, code 'TOKEN_EXPIRATION'}

  //   if (result?.error || result === undefined) {
  //     if (result?.code === "TOKEN_EXPIRATION") {
  //       setCookie(AUTH_COOKIE, "", null);
  //       navigate("/login");
  //     }
  //   } else {
  //     setDetailData(result);
  //     if (path.nowPath === "notice") {
  //       selectData.onClick(CategoryList[result.category === "NOTICE" ? 0 : result.category === "MAINTENANCE" ? 1 : 2]);
  //     }
  //   }

  //   // console.log(result.list);
  //   // setListData(result.list);
  // };

  // 글 작성 API
  // const _postNews = async (imgUrl?: string) => {
  //   console.log(`file url >>>>>`, fileUrl);
  //   const { data: result } = await sendRequest(postNews, {
  //     now: path.nowPath,
  //     category:
  //       path.nowPath === "notice" && selectData.value.id + 1 === 1
  //         ? "NOTICE"
  //         : path.nowPath === "notice" && selectData.value.id + 1 === 2
  //         ? "MAINTENANCE"
  //         : path.nowPath === "notice" && selectData.value.id + 1 === 3
  //         ? "MARKET"
  //         : path.nowPath === "update"
  //         ? "UPDATE"
  //         : path.nowPath === "event"
  //         ? "GENERAL"
  //         : null,
  //     title: title,
  //     content: editorRef.current?.getInstance().getHTML(),
  //     summary: path.nowPath === "update" && detailSummary,
  //     thumbnailImageUrl: (path.nowPath === "update" || path.nowPath === "event") && imgUrl,
  //     display: (path.nowPath === "notice" || path.nowPath === "event") && display.value,
  //     fix: (path.nowPath === "notice" || path.nowPath === "event") && noti.value,
  //     assignedAt: path.nowPath === "update" && startDate,
  //     startDate: path.nowPath === "event" && startDate,
  //     endDate: path.nowPath === "event" && endDate,
  //   });

  //   console.log(result); // {error: true, code 'TOKEN_EXPIRATION'}

  //   if (result?.error) {
  //     alert(result?.code + `개발팀에 문의해 주세요`);
  //     return;
  //   } else {
  //     // alert("정상적으로 등록되었습니다.");
  //   }

  //   navigate(-1);
  // };

  // 글 수정 API
  // const _patchNews = async (imgUrl?: string) => {
  //   console.log(`file url >>>>>`, fileUrl);
  //   const { data: result } = await sendRequest(patchNews, {
  //     noticeId: (path.nowPath === "notice" || path.nowPath === "update") && pageId,
  //     eventId: path.nowPath === "event" && pageId,

  //     now: path.nowPath,
  //     category:
  //       path.nowPath === "notice" && selectData.value.id + 1 === 1
  //         ? "NOTICE"
  //         : path.nowPath === "notice" && selectData.value.id + 1 === 2
  //         ? "MAINTENANCE"
  //         : path.nowPath === "notice" && selectData.value.id + 1 === 3
  //         ? "MARKET"
  //         : path.nowPath === "update"
  //         ? "UPDATE"
  //         : path.nowPath === "event"
  //         ? "GENERAL"
  //         : null,
  //     title: title,
  //     content: editorRef.current?.getInstance().getHTML(),
  //     summary: path.nowPath === "update" && detailSummary,
  //     thumbnailImageUrl: (path.nowPath === "update" || path.nowPath === "event") && imgUrl,
  //     display: (path.nowPath === "notice" || path.nowPath === "event") && display.value,
  //     fix: (path.nowPath === "notice" || path.nowPath === "event") && noti.value,
  //     assignedAt: path.nowPath === "update" && startDate,
  //     startDate: path.nowPath === "event" && startDate,
  //     endDate: path.nowPath === "event" && endDate,
  //   });

  //   console.log(result); // {error: true, code 'TOKEN_EXPIRATION'}

  //   if (result?.error) {
  //     // alert(ERROR_MESSAGE[result?.code] || ERROR_MESSAGE.DEFAULT);
  //     return;
  //   } else {
  //     // alert("정상적으로 등록되었습니다.");
  //   }

  //   navigate(-1);
  // };

  // 글 작성 post API
  // const postApi = async () => {
  //   dispatch(loadingSetting(true));

  //   console.log(`${REACT_APP_SERVER_URL}/${boardType}/${pathType}`);

  //   await axios
  //     .post(
  //       `${REACT_APP_SERVER_URL}/${boardType}/${pathType}`,
  //       {
  //         userid: login.userid,
  //         name: login.name,
  //         language: "ko",
  //         title: title,
  //         content: editorRef.current?.getInstance().getHTML(),
  //         display: !display.value,

  //         // notice
  //         noti: (path.nowPath === "notice" || path.nowPath === "event") && noti.value,
  //         show: (path.nowPath === "notice" || path.nowPath === "event") && display.value,
  //         category:
  //           path.nowPath === "notice"
  //             ? selectData.value.id + 1 === 1
  //               ? "공지"
  //               : selectData.value.id + 1 === 2
  //               ? "점검"
  //               : "상점"
  //             : null,

  //         // update
  //         thumbnail: (path.nowPath === "update" || path.nowPath === "event") && file,
  //         updatetime: path.nowPath === "update" && startDate,
  //         summary: path.nowPath === "update" && detailSummary,

  //         // event
  //         starttime: path.nowPath === "event" && startDate,
  //         endtime: path.nowPath === "event" && endDate,

  //         // community - free-board / tip-board / screenshot-board
  //         notice: (path.nowPath === "free-board" || path.nowPath === "tip-board") && true,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
  //           Authorization: sessionStorage.getItem(`accessToken`),
  //         },
  //       }
  //     )
  //     .then(({ data }) => {
  //       console.log("test 중 >>>>>>>>>>");
  //       console.log("api >>", data);
  //       alert(`등록되었습니다`);
  //       navigate(-1);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //     });
  //   dispatch(loadingSetting(false));
  // };

  // Notice put 글 수정시 API
  // const putApi = async () => {
  //   dispatch(loadingSetting(true));

  //   console.log("userid", login.userid);
  //   console.log("username", login.name);
  //   console.log("username", sessionStorage.getItem(`accessToken`));
  //   console.log(`${REACT_APP_SERVER_URL}/${boardType}/${pathType}/${location.state?.id}`);

  //   await axios
  //     .put(
  //       `${REACT_APP_SERVER_URL}/${boardType}/${pathType}/${location.state?.id}`,
  //       {
  //         userid: login.userid,
  //         name: login.name,
  //         language: "ko",
  //         title: title,
  //         content: editorRef.current?.getInstance().getHTML(),

  //         // notice
  //         category:
  //           path.nowPath === "notice"
  //             ? selectData.value.id + 1 === 1
  //               ? "공지"
  //               : selectData.value.id + 1 === 2
  //               ? "점검"
  //               : "상점"
  //             : null,
  //         noti: (path.nowPath === "notice" || path.nowPath === "event") && noti.value,
  //         show: (path.nowPath === "notice" || path.nowPath === "event") && display.value,

  //         // update
  //         summary: path.nowPath === "update" && detailSummary,
  //         updatetime: path.nowPath === "update" && startDate,

  //         // event
  //         starttime: path.nowPath === "event" && startDate,
  //         endtime: path.nowPath === "event" && endDate,

  //         // community - free-board / tip-board / screenshot-board
  //         notice: (path.nowPath === "free-board" || path.nowPath === "tip-board") && true,

  //         // file
  //         thumbnail: (path.nowPath === "update" || path.nowPath === "event") && file ? file : null,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
  //           Authorization: sessionStorage.getItem(`accessToken`),
  //         },
  //       }
  //     )
  //     .then(({ data }) => {
  //       console.log("api >>", data);
  //       alert(`수정되었습니다`);
  //       navigate(`/${boardType}/${path.nowPath}`);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //     });
  //   dispatch(loadingSetting(false));
  // };

  // const _getThumnailUrl = async () => {
  //   const { data: result } = await sendRequest(getImgUrl, {});

  //   // console.log(result?.signedUrlMap.image);

  //   if (result?.error || result === undefined) {
  //     // if (result?.code === "TOKEN_EXPIRATION") {
  //     //   setCookie(AUTH_COOKIE, "", null);
  //     //   navigate("/login");
  //     // }
  //   } else {
  //     const imageUrl = result?.signedUrlMap.image;
  //     if (result?.code === "TOKEN_EXPIRATION" && result?.error) {
  //       // dispatch(profileSetting({ nickname: "", picture: "", sns: "", email: "" }));
  //       // dispatch(loginSetting(false));
  //       // navigate(`/login`);
  //     } else {
  //       const isCreating = !location.state?.id;
  //       axios
  //         .put(imageUrl, file)
  //         .then((res) => {
  //           var imgUrl = imageUrl.substring(0, imageUrl.indexOf("?"));
  //           setFileUrl(imageUrl.substring(0, imageUrl.indexOf("?")));
  //           if (isCreating) {
  //             _postNews(imgUrl);
  //           } else {
  //             _patchNews(imgUrl);
  //           }
  //         })
  //         .catch((e) => {
  //           console.error("Image upload failed:", e);
  //         });
  //     }
  //   }
  // };

  // const EmptyValueWarning = () => {
  //   // console.log(location?.state.id);
  //   const isTitleEmpty = !title;
  //   const isDetailSummaryEmpty = !detailSummary;
  //   // const isEditorEmpty = !editorRef.current?.getInstance().getMarkdown() || editorRef.current?.getInstance().getMarkdown().length < 100;
  //   const isCreating = !location.state?.id;
  //   const isThumnailEmpty = !file;

  //   if (isTitleEmpty) window.alert("제목을 입력해 주세요.");
  //   else if (path.nowPath === "update" && isDetailSummaryEmpty) window.alert("업데이트 요약을 입력해 주세요.");
  //   else if ((path.nowPath === "update" || path.nowPath === "event") && isThumnailEmpty)
  //     window.alert("썸네일을 첨부해 주세요.");
  //   // else if (isEditorEmpty) window.alert("100자 이상 내용을 입력해 주세요");
  //   else {
  //     if (isCreating) {
  //       console.log("1111");
  //       if (file && (path.nowPath === "update" || path.nowPath === "event")) {
  //         _getThumnailUrl();
  //       } else {
  //         _postNews();
  //       }
  //     } else {
  //       console.log("222222");
  //       if (file && (path.nowPath === "update" || path.nowPath === "event")) {
  //         _getThumnailUrl();
  //       } else {
  //         _patchNews();
  //       }
  //     }
  //   }
  // };

  return (
    <div className={classNames("write", path.nowPath)}>
      <div className="write__inner">
        <PageTop title={boardTitle(path.nowPath) + (location?.state ? " 수정" : " 등록")} />
        <div className="board">
          <div className="board__title">
            {path.nowPath === "notice" && (
              <SelectBox name="category" data={CategoryList} selectData={selectData} classification />
            )}
            {path.nowPath === "update" && (
              <DatePicker
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
                locale={ko} // 한글로 변경
                dateFormat="yyyy-MM-dd aa hh:mm" // 시간 포맷 변경
                showPopperArrow={false} // 화살표 변경
                showTimeInput
              />
            )}
            <InputBox
              type="text"
              name="title"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(e.target.value);
              }}
              placeholder="제목을 입력해주세요. (최대  50자 이내)"
              // icon={true}
            />
          </div>

          {/* notice일 경우 */}
          {path.nowPath === "notice" && (
            <div className="board__title--bottom">
              <div className="right">
                <div className="check_box">
                  <CheckBox
                    name="noti_list"
                    value={noti.value}
                    onClick={noti.onClick}
                    size="sm"
                    label="리스트 상단 고정"
                  />
                  <p className="title">공지 (리스트 상단 고정)</p>
                </div>
                <div className="check_box">
                  <CheckBox name="show_list" value={display.value} onClick={display.onClick} size="sm" label="미노출" />
                  <p className="title">미노출</p>
                </div>
              </div>
            </div>
          )}

          {/* update일 경우 */}
          {/* {path.nowPath === "update" && (
            <InputBox
              type="text"
              name="summary"
              value={detailSummary}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDetailSummary(e.target.value);
              }}
              placeholder="업데이트 요약 소개 (최대 80자 이내)"
            />
          )} */}

          {/* event일 경우 */}
          {/* {path.nowPath === "event" && (
            <div className="board__title--bottom">
              <div className="box--date">
                <DatePicker
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                  locale={ko} // 한글로 변경
                  dateFormat="yyyy-MM-dd aa hh:mm" // 시간 포맷 변경
                  showPopperArrow={false} // 화살표 변경
                  showTimeInput
                />
                <p>~</p>
                <DatePicker
                  selected={endDate}
                  onChange={(date: any) => setEndDate(date)}
                  locale={ko} // 한글로 변경
                  dateFormat="yyyy-MM-dd aa hh:mm" // 시간 포맷 변경
                  showPopperArrow={false} // 화살표 변경
                  showTimeInput
                />
              </div> 

              <div className="right">
                <div className="check_box">
                  <CheckBox
                    name="noti_list"
                    value={noti.value}
                    onClick={noti.onClick}
                    size="sm"
                    label="리스트 상단 고정"
                  />
                  <p className="title">공지 (리스트 상단 고정)</p>
                </div>
                <div className="check_box">
                  <CheckBox
                    name="display_list"
                    value={display.value}
                    onClick={display.onClick}
                    size="sm"
                    label="미노출"
                  />
                  <p className="title">미노출</p>
                </div>
              </div>
            </div>
					)}*/}
          <div className="board__body--editor">
            <EditorComponent editorRef={editorRef} />
            {(path.nowPath === "free-board" || path.nowPath === "tip-board" || path.nowPath === "screenshot-board") && (
              <p className="notice">
                관리자{" "}
                {path.nowPath === "free-board"
                  ? "자유게시판 게시글"
                  : path.nowPath === "tip-board"
                  ? "팁 공유 게시글"
                  : path.nowPath === "screenshot-board"
                  ? "스크린샷 게시판 게시글"
                  : ""}
                등록시 리스트 상단 “공지” 고정 노출 됩니다.
              </p>
            )}
          </div>
          {(path.nowPath === "update" || path.nowPath === "event") && (
            <div className="board__bottom">
              <div className="top">
                <p className="title">
                  썸네일
                  <span>이미지는 가로 571px 세로 286px 크기의 JPG, PNG, GIF만 업로드 가능 (최대 1MB)</span>
                </p>

                <div className="button--upload">
                  <label className="button" htmlFor="file">
                    이미지 선택
                  </label>
                  {/* <input className="hide" type="file" accept="image/*" onChange={saveFileImage} id="file" /> */}
                  <input className="hide" type="file" accept="image/*" id="file" />
                </div>
              </div>

              {/* {fileUrl !== undefined && fileUrl !== null && fileUrl !== `undefined` && fileUrl !== "" && ( */}
              <div className="img--box">
                {/* <img alt="uploadImg" src={fileUrl} /> */}
                <div
                  className="button--file--delete"
                  onClick={() => {
                    // deleteFileImage();
                  }}
                ></div>
              </div>
              {/* )} */}
            </div>
          )}
        </div>
        <div className="write__bottom">
          <NormalButton
            title="취소"
            hierarchy="secondary_gray"
            onClick={() => {
              navigate(-1);
            }}
            buttonStyle="box"
            size="md"
          />
          <NormalButton
            title={location?.state ? "수정" : "등록"}
            hierarchy="primary"
            buttonStyle="box"
            size="md"
            onClick={() => {
              if (location.state?.id) {
                // dispatch(
                //   modalSetting({
                //     open: !modal.open,
                //     type: "success",
                //     title: "수정하기",
                //     description: "정말로 수정 하시겠습니까?",
                //     confirmButton: "수정하기",
                //     confirmStyle: "primary",
                //     onConfirm: EmptyValueWarning,
                //   })
                // );
              } else {
                // dispatch(
                //   modalSetting({
                //     open: !modal.open,
                //     type: "success",
                //     title: "등록하기",
                //     description: "정말로 등록 하시겠습니까?",
                //     confirmButton: "등록하기",
                //     confirmStyle: "primary",
                //     onConfirm: EmptyValueWarning,
                //   })
                // );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Write;
