import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { axios as axiosInstance } from "apis/http";
import axios from "axios";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import { Editor } from "@toast-ui/react-editor";
import { ko } from "date-fns/esm/locale";
import { CheckBox, PageTop, InputBox, EditorComponent, NormalButton, SelectBox } from "components/index";
import { useSelect, useCheckbox } from "hooks/index";
import useEventDetail from "hooks/board/event/useEventDetail";
import useUpdateEvent from "hooks/board/event/useUpdateEvent";

const MINIMUM_TITLE_LENGTH = 2;

const EventUpdate = () => {
  const location = useLocation();
  const { id } = location.state.content;
  const navigate = useNavigate();
  const { updateEvent } = useUpdateEvent();
  const editorRef = useRef<Editor>(null);
  const { eventContent } = useEventDetail();

  const [title, setTitle] = useState("");
  const [file, setFile] = useState<Blob | string>("");
  const [fileUrl, setFileUrl] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const isExposed = useCheckbox(false);
  const categoryList = [
    { id: "GENERAL", name: "일반형" },
    { id: "PARTICIPATORY", name: "참여형" },
  ];
  const selectData = useSelect(categoryList.find((category) => category.id === eventContent?.category));

  useEffect(() => {
    if (!eventContent) return;
    if (eventContent?.content) editorRef.current?.getInstance().setHTML(eventContent?.content);
    setTitle(eventContent?.title);
    setStartDate(new Date(eventContent?.startDate));
    setEndDate(new Date(eventContent?.endDate));
    isExposed.changeValue(!eventContent?.isExposed);
    selectData.setValue(categoryList.find((category) => category.id === eventContent?.category));
    setFileUrl(eventContent?.thumbnailImageUrl);
  }, [eventContent]);

  // 미리보기 이미지 파일 저장
  const saveFileImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;
    if (!files) return;
    setFileUrl(URL.createObjectURL(files[0]));
    setFile(files[0]);
    e.target.value = "";
  };

  // 미리보기 이미지 파일 삭제
  const deleteFileImage = () => {
    URL.revokeObjectURL(fileUrl);
    setFileUrl("");
  };

  const handleOnSubmit = async (content: string | undefined) => {
    if (title.length < MINIMUM_TITLE_LENGTH) {
      alert("제목은 두글자 이상이어야 합니다.");
      return;
    }
    if (!content) {
      alert("내용을 입력하세요.");
      return;
    }
    if (!fileUrl) {
      alert("썸네일 이미지를 선택하세요.");
      return;
    }
    await axiosInstance.get("/file/signedUrl/image?files[0]=image").then(({ data }) => {
      const imageUrl = data?.data?.signedUrlMap.image;
      axios
        .put(imageUrl, file)
        .then(() => {
          updateEvent({
            eventId: id,
            title: title,
            content,
            isExposed: !isExposed.value,
            category: selectData.value.id,
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
            thumbnailImageUrl: imageUrl.substring(0, imageUrl.indexOf("?")),
          });
        })
        .catch((e) => {
          console.error("Image upload failed:", e);
        })
        .finally(() => {
          navigate(-1);
        });
    });
  };

  return (
    <div className={classNames("write", "update")}>
      <div className="write__inner">
        <PageTop title="이벤트 수정" />
        <div className="board">
          <div className="board__title">
            <SelectBox name="category" data={categoryList} selectData={selectData} classification />
            <InputBox
              type="text"
              name="title"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(e.target.value);
              }}
              placeholder="제목을 입력해주세요. (최대  50자 이내)"
            />
          </div>
          <div className="board__title--bottom">
            <div className="box--date">
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                locale={ko} // 한글로 변경
                dateFormat="yyyy-MM-dd aa hh:mm" // 시간 포맷 변경
                showPopperArrow={false} // 화살표 변경
                showTimeInput
              />
              <p>~</p>
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
                locale={ko} // 한글로 변경
                dateFormat="yyyy-MM-dd aa hh:mm" // 시간 포맷 변경
                showPopperArrow={false} // 화살표 변경
                showTimeInput
              />
            </div>

            <div className="right">
              <div className="check_box">
                <CheckBox
                  name="display_list"
                  value={isExposed.value}
                  onClick={isExposed.onClick}
                  size="sm"
                  label="미노출"
                  checked={isExposed.value}
                />
                <p className="title">미노출</p>
              </div>
            </div>
          </div>
          <div className="board__body--editor">
            <EditorComponent editorRef={editorRef} />
          </div>
          <div className="board__bottom">
            <div className="top">
              <p className="title">
                썸네일
                <span>이미지는 가로 571px 세로 286px 크기의 JPG, PNG, GIF만 업로드 가능 (최대 1MB)</span>
              </p>

              <div className="button--upload">
                <label className="button" htmlFor="file">
                  이미지 선택
                </label>
                <input className="hide" type="file" accept="image/*" onChange={saveFileImage} id="file" />
                <input className="hide" type="file" accept="image/*" id="file" />
              </div>
            </div>

            {fileUrl !== "" && (
              <div className="img--box">
                <img alt="uploadImg" src={fileUrl} />
                <div
                  className="button--file--delete"
                  onClick={() => {
                    deleteFileImage();
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
        <div className="write__bottom">
          <NormalButton
            title="취소"
            hierarchy="secondary_gray"
            onClick={() => {
              navigate(-1);
            }}
            buttonStyle="box"
            size="md"
          />
          <NormalButton
            title="등록"
            hierarchy="primary"
            buttonStyle="box"
            size="md"
            onClick={() => {
              const eventContent = editorRef.current?.getInstance().getHTML();
              handleOnSubmit(eventContent);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EventUpdate;
