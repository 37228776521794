import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { queryKeys } from "react-query/querykey";

function getUpdateDetail(noticeId: string) {
  return axios.get<any>(`/update/detail?updateId=${noticeId}`);
}

const useUpdateDetail = () => {
  const location = useLocation();
  const { id } = location?.state;
  const queryKey = [queryKeys.updateList, id];
  const { data: updateContent, error } = useQuery({
    queryKey,
    queryFn: () => getUpdateDetail(id),
    select: (data) => data.data.data,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("업데이트 게시판 디테일을 가져오는데 에러가 발생하였습니다.");
  }

  return { updateContent, error };
};

export default useUpdateDetail;
