import { useState } from "react";

const useCheckbox = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue);

  const _handleChange = (event: any) => {
    console.log("[checkbox] handle", event.target.checked);
    setValue(event.target.checked);
  };

  const _changeValue = (event: any) => {
    setValue(event);
  };

  return {
    value,
    onClick: _handleChange,
    changeValue: _changeValue,
  };
};

export default useCheckbox;
