import { useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ContainerFooter, MainHeader, ConfirmModal, Sidebar } from "components/index";

import { RootState, ModalState, nowPageSetting } from "store/index";
import { zemiMenu } from "shared/config/router/path";
import { AUTH_COOKIE } from "constants/index";
import { getCookie } from "shared/utils";

import "./mainLayout.scss";

const MainLayout = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const getHeaderPath = (path: string) => path.split("/")[1];
    const selectedId = zemiMenu.find((menu) => getHeaderPath(menu.link) === getHeaderPath(location.pathname));
    if (selectedId?.id) dispatch(nowPageSetting(selectedId.id));
  }, [location]);

  const token = getCookie(AUTH_COOKIE);
  if (!token) {
    return <Navigate to="/login" />;
  }
  // const modal: ModalState = useSelector((state: RootState) => state.modal);
  return (
    <div className={classNames(`App`, "main-layout", i18n.language)}>
      <>
        <MainHeader />
        <div className="container">
          <Sidebar />
          <Outlet />
        </div>
        <ContainerFooter />
      </>
    </div>
  );
};

export default MainLayout;
