import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";

// 권한 목록
export function getPermission() {
  return axios.get<{ data: string[] }>("/permission");
}

const usePermission = () => {
  const queryKey = [queryKeys.permission];

  const { data: permissionList = [], error } = useQuery({
    queryKey,
    queryFn: () => getPermission(),
    select: (data) => data.data.data,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("관리자 계정 리스트를 가져오는데 에러가 발생하였습니다.");
  }

  return { permissionList, error };
};

export default usePermission;
