import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { NoticeList } from "components/board/index";
import { CategoryBox, PageTop, NormalButton } from "components/index";
import { useSelect } from "hooks/index";
import { boardHeader } from "shared/index";
import Search from "components/board/Search";
import useNotice from "hooks/board/notice/useNotice";
import useDebounce from "hooks/useDebounce";
import { NoticeCategory } from "types/board";

const NOTICE_CATEGORY = [
  { id: "ALL", title: "전체" },
  { id: "NOTICE", title: "공지" },
  { id: "MAINTENANCE", title: "점검" },
  { id: "MARKET", title: "상점" },
];

const SEARCH_CATEGORY = [
  { id: "TITLE", name: "제목" },
  { id: "CONTENT", name: "내용" },
  { id: "TITLE+CONTENT", name: "제목+내용" },
];

export const PAGINATION = {
  pageRange: 10,
  btnRange: 10,
};

const DEBOUNCE_DELAY = 500;

const NoticeListPage = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState<NoticeCategory>("ALL"); // 공지사항 카테고리
  const [keyword, setKeyword] = useState(""); // 입력한 검색어 값
  const [page, setPage] = useState(1); // 현재 페이지
  const keywordType = useSelect(SEARCH_CATEGORY[0]);
  const {
    totalPost = 0,
    noticeList = [],
    refetch,
  } = useNotice({
    page,
    limit: PAGINATION.pageRange,
    category,
    keyword,
    searchCategory: keywordType.value.id,
  });

  useEffect(() => {
    refetch();
  }, [page, category, keywordType.value]);

  const submitKeywords = () => {
    setPage(1);
    refetch();
  };

  useDebounce(keyword, DEBOUNCE_DELAY, submitKeywords);

  return (
    <div className={classNames("content", "notice")}>
      <div className="content__inner">
        <PageTop title="공지사항" />
        <div className="board">
          <div className="board__top">
            <CategoryBox data={NOTICE_CATEGORY} category={category} setCategory={setCategory} />
            <div className="right">
              <Search categoryList={SEARCH_CATEGORY} keywordType={keywordType} setKeyword={setKeyword} />
            </div>
          </div>
          <div className="board__body">
            <div className="board__body__header">
              {boardHeader("notice").map((data: { id: number; title: string }, idx: number) => (
                <p key={idx}>{data.title}</p>
              ))}
            </div>
            <NoticeList noticeList={noticeList} totalPost={totalPost} page={page} setPage={setPage} />
          </div>
          <div className="board__bottom">
            <NormalButton
              title="글쓰기"
              size="md"
              hierarchy="primary"
              icon="false"
              buttonStyle="box"
              onClick={() => {
                navigate(`/news/notice/write`);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeListPage;
