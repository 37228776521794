import { useState } from "react";
import classNames from "classnames";
import "./selectbox.scss";
import { ReactComponent as CheckIcon } from "assets/images/layer/check.svg";
import { ReactComponent as ChevronUpIcon } from "assets/images/layer/chevron-up.svg";
import { ReactComponent as ChevronDownIcon } from "assets/images/layer/chevron-down.svg";

/**
 * slectbox components
 *
 * @param selectData 현재 선택되어 있는 값(any)
 * @param name class명(string)
 * @param data selectbox의 optionList [{id: string, name: string}
 * @param image image가 있는 selectbox일 경우 (boolean)
 * @param classification default selectbox일 경우 (boolean)
 *
 * @since 2023-02-07
 * @author colen
 */
const SelectBox = (props: any) => {
  const [toggle, setToggle] = useState(false);
  const selectData = props.selectData;

  const imageSelectStyle = () => {
    return (
      <div className={classNames(`select-wrap`, props.name)}>
        <div className={classNames(`select image`)} onClick={() => setToggle(!toggle)}>
          <div className="left">
            {selectData.value.imageUrl ? (
              <img src={selectData.value.imageUrl} alt={selectData.value.name} />
            ) : (
              <img src="/images/avatar.png" alt={selectData.name} />
            )}
            <p>{selectData.value.name}</p>
          </div>
          <i></i>
        </div>
        <ul className={classNames(`select-ul image`, { on: toggle, off: !toggle })}>
          {props.data &&
            props.data.map((d: any) => (
              <li
                key={d.id}
                className={classNames({ disabled: d.disable })}
                onClick={() => {
                  d.disable === false && selectData.onClick(d);
                  setToggle(false);
                }}
              >
                <img src={d.imageUrl} alt={d.name} />
                <p>{d.name}</p>
              </li>
            ))}
        </ul>
      </div>
    );
  };

  const classificationSelectStyle = () => {
    return (
      <div className={classNames(`select-wrap`, props.name)}>
        <div
          className={classNames(`select classification`, { on: toggle, off: !toggle })}
          onClick={() => setToggle(!toggle)}
        >
          <p>{selectData?.value?.name}</p>
          {toggle ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
        <ul className={classNames(`select-ul classification`, { on: toggle, off: !toggle })}>
          {props.data &&
            props.data.map((d: any) => (
              <li
                key={d.id}
                className={classNames({ selected: selectData?.value?.name === d.name })}
                onClick={() => {
                  selectData.onClick(d);
                  setToggle(false);
                }}
              >
                <p>{d.name}</p>
                {selectData?.value?.name === d.name && <CheckIcon />}
              </li>
            ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      {props.image && imageSelectStyle()}
      {props.classification && classificationSelectStyle()}
    </>
  );
};

export default SelectBox;
