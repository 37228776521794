const InspectItem = () => {
  return (
    <div className="content inspect-item">
      <div className="content__inner">
        <p>Inspect item</p>
      </div>
    </div>
  );
};

export default InspectItem;
