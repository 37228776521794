import { useState, useRef } from "react";
import { dateToString } from "shared/index";
import { NormalButton } from "components/index";
import ModalPortal from "portals/ModalPortal";
import { ConfirmModal } from "components/index";
import { LinkItem, VideoItem } from "types/demo";

type FileItemType = {
  item: LinkItem | VideoItem;
  handleOnDelete: (id: string) => void;
};

const FileItem = ({ item, handleOnDelete }: FileItemType) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <div key={item.id} className="body-row row">
      <div className="cell filename">
        <a href={item.url} target="_blank" rel="noreferrer">
          {item?.title}
        </a>
      </div>
      <div className="cell upload-date">
        <p>{item?.createdAt && dateToString(item?.createdAt, "yyyy.MM.dd hh:mm:ss", "ko")}</p>
      </div>
      <div className="cell cancel-btn">
        <NormalButton
          title="삭제"
          size="sm"
          hierarchy="secondary_gray"
          buttonStyle="box"
          width="49px"
          onClick={() => setIsDeleteModalOpen(true)}
        />
      </div>
      {isDeleteModalOpen && (
        <ModalPortal>
          <ConfirmModal
            modalRef={modalRef}
            type="warning"
            title="파일 삭제"
            description="정말로 삭제하시겠습니까?"
            confirmButton="예"
            cancel="아니오"
            setIsModalOpen={setIsDeleteModalOpen}
            confirmStyle="primary"
            handleOnConfirm={() => handleOnDelete(item?.id)}
          />
        </ModalPortal>
      )}
    </div>
  );
};

export default FileItem;
