import { useState, useRef } from "react";
import { InputBox } from "components/common";
import { NormalButton } from "components/index";
import ModalPortal from "portals/ModalPortal";
import { ConfirmModal } from "components/index";
import Icon from "shared/Icon";

type PlayVideoItemType = {
  index: number;
  id: string;
  url: string;
  isExposed: boolean;
  title: string;
  deleteVideo: (id: string) => void;
  handleOnChange: ({
    type,
    id,
    value,
  }: {
    type: "url" | "title" | "isExposed";
    value: string | boolean;
    id: string;
  }) => void;
};

const PlayVideoItem = ({ index, id, url, title, isExposed, deleteVideo, handleOnChange }: PlayVideoItemType) => {
  const [isExposedModalOpen, setIsExposedModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleOnConfirm = () => {
    setIsExposedModalOpen(true);
    handleOnChange({ type: "isExposed", value: false, id });
  };

  const handleOnDelete = (id: string) => {
    deleteVideo(id);
    setIsDeleteModalOpen(false);
  };

  return (
    <li className="play-video-item">
      <span className="video-number">영상 URL. {index}</span>
      <InputBox
        type="text"
        name="video-url"
        value={url}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChange({ type: "url", value: e.target.value, id })
        }
      />
      <InputBox
        type="text"
        name="video-name"
        value={title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChange({ type: "title", value: e.target.value, id })
        }
      />
      <NormalButton
        title="삭제"
        size="sm"
        hierarchy="secondary_gray"
        buttonStyle="box"
        width="64px"
        buttonClassName="cancel-btn"
        onClick={() => setIsDeleteModalOpen(true)}
      />
      {isExposed ? (
        <button
          className="exposed-btn"
          onClick={() => {
            setIsExposedModalOpen(true);
          }}
        >
          <Icon icon="eye" color="#fff" size={24} />
          노출중
        </button>
      ) : (
        <button
          className="not-exposed-btn"
          onClick={() => {
            // setInputIsExposed(true);
            handleOnChange({ type: "isExposed", value: true, id });
          }}
        >
          <Icon icon="eye-off" color="#7F56D9" size={24} />
          미노출
        </button>
      )}
      {isExposedModalOpen && (
        <ModalPortal>
          <ConfirmModal
            modalRef={modalRef}
            type="success"
            title="플레이 영상 노출 헤제"
            description="노출중인 영상을 헤제 하시겠습니까?"
            confirmButton="예"
            cancel="아니오"
            setIsModalOpen={setIsExposedModalOpen}
            confirmStyle="primary"
            handleOnConfirm={handleOnConfirm}
          />
        </ModalPortal>
      )}
      {isDeleteModalOpen && (
        <ModalPortal>
          <ConfirmModal
            modalRef={modalRef}
            type="warning"
            title="파일 삭제"
            description="정말로 영상을 삭제하시겠습니까?"
            confirmButton="예"
            cancel="아니오"
            setIsModalOpen={setIsDeleteModalOpen}
            confirmStyle="primary"
            handleOnConfirm={() => handleOnDelete(id)}
          />
        </ModalPortal>
      )}
    </li>
  );
};
export default PlayVideoItem;
