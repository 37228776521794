import { axios, ResponseDataType } from "apis/http";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { queryKeys } from "react-query/querykey";
import { EventCategory } from "types/board";

export type Param = {
  title: string;
  content?: string;
  category: EventCategory;
  isExposed: boolean;
  startDate: number;
  endDate: number;
  thumbnailImageUrl: string;
};

const ERROR_MESSAGE: { [key: string]: string } = {};

const postEvent = (content: Param) => {
  console.log(content);

  return axios.post<any, null>("/event", content);
};

const useAddEvent = () => {
  const queryKey = [queryKeys.noticeList];
  const queryClient = useQueryClient();

  const { mutate: addEvent } = useMutation({
    mutationFn: (content: Param) => postEvent(content),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        console.error("공지사항을 등록하는데 오류가 발생하였습니다.", error);
        const errorType = error.response?.data.code;
        if (errorType) alert(ERROR_MESSAGE[errorType]);
      }
    },
  });

  return { addEvent };
};

export default useAddEvent;
