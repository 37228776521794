import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";

import { SelectBox, InputBox, NormalButton } from "components/common";
import useSelect from "hooks/useSelect";
import { useGroup, useAddAdmin, useUpdateAdmin } from "hooks/account/admin";
import { PostAdminType } from "hooks/account/admin/useAddAdmin";
import { UpdateAdmin } from "hooks/account/admin/useUpdateAdmin";
import "./AdminAccountSave.scss";

const DEFAULT_SELECT_VALUE = { id: 0, name: "관리자 그룹 선택" };

const AdminAccountSave = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, admin } = location.state;

  const { groupList } = useGroup();
  const { addAdmin } = useAddAdmin();
  const { updateAdmin } = useUpdateAdmin();

  const [accountId, setAccountId] = useState(admin?.accountId ? admin.accountId : "");
  const [department, setDepartment] = useState(admin?.department ? admin.department : "");
  const [name, setName] = useState(admin?.name ? admin.name : "");
  const [nickname, setNickname] = useState(admin?.nickname ? admin.nickname : "");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const setDefaultSelect = () => {
    const hasPermissionGroup = admin?.adminPermissionGroup?.id;
    if (!hasPermissionGroup) return DEFAULT_SELECT_VALUE;
    return groupList?.find((group) => group.id === admin?.adminPermissionGroup.id);
  };

  const authGroup = useSelect(setDefaultSelect());
  const isCorrectPwd = passwordCheck !== password && passwordCheck !== "";
  const hasEmptyInput = accountId === "" || name === "" || passwordCheck === "" || password === "" || nickname === "";

  const handleOnSubmit = () => {
    if (hasEmptyInput) {
      alert("아이디, 비밀번호, 이름, 닉네임 입력은 필수입니다.");
      return;
    }
    const params: { [key: string]: string } = { password, name, nickname };
    if (department) params["department"] = department;
    const hasNextGroup = authGroup?.value?.id !== 0;
    if (hasNextGroup) params["adminPermissionGroupId"] = authGroup?.value?.id;

    if (type === "create") {
      const createParams = { accountId, ...params } as PostAdminType;
      addAdmin(createParams);
    }

    if (type === "update") {
      const updateParams = { adminId: admin?.id } as UpdateAdmin;
      if (admin.name !== name) updateParams["name"] = name;
      if (admin.nickname !== nickname) updateParams["nickname"] = nickname;
      if (admin.password !== password) updateParams["password"] = password;
      updateAdmin(updateParams);
    }
  };

  return (
    <div className="content">
      <div className="content__inner admin-main admin-save">
        <h1 className="admin__title">관리자 계정 {type === "create" ? "생성" : "수정"}</h1>
        <ul className="admin_list">
          <li className="admin_item">
            <div className="label">관리자 그룹</div>
            <div className="admin-save-input">
              <SelectBox name="admin" data={groupList} selectData={authGroup} classification />
            </div>
          </li>
          <li className="admin_item">
            <div className="label">아이디</div>
            <div className="admin-save-input">
              <InputBox
                type="text"
                name="id"
                value={accountId}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAccountId(e.target.value);
                }}
                disabled={type === "update" ? true : false}
              />
            </div>
          </li>
          <li className="admin_item">
            <div className="label">소속 부서</div>
            <div className="admin-save-input">
              <InputBox
                type="text"
                name="department"
                value={department}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDepartment(e.target.value);
                }}
              />
            </div>
          </li>
          <li className="admin_item">
            <div className="label">이름</div>
            <div className="admin-save-input">
              <InputBox
                type="text"
                name="name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </li>
          <li className="admin_item">
            <div className="label">닉네임</div>
            <div className="admin-save-input">
              <InputBox
                type="text"
                name="nickname"
                value={nickname}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNickname(e.target.value);
                }}
              />
            </div>
          </li>
          <li className="admin_item">
            <div className="label">비밀번호</div>
            <div className="admin-save-input">
              <InputBox
                type="password"
                name="password"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </li>
          <li className="admin_item">
            <div className={classNames(`label`, isCorrectPwd && `width_99`)}>비밀번호 확인</div>
            <div className="admin-save-input">
              <InputBox
                type="password"
                name="password-check"
                value={passwordCheck}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPasswordCheck(e.target.value);
                }}
              />
              {isCorrectPwd && <p className="warning-label">비밀번호가 일치하지 않습니다.</p>}
            </div>
          </li>
        </ul>
        <div className="btn_wrap">
          <NormalButton
            title="취소"
            size="md"
            hierarchy="secondary_gray"
            buttonStyle="box"
            onClick={() => navigate("/account/admin")}
          />
          <NormalButton
            title="저장"
            size="md"
            hierarchy="primary_line"
            buttonStyle="box"
            onClick={handleOnSubmit}
            disable={isCorrectPwd || hasEmptyInput}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminAccountSave;
