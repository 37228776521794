import { axios, ResponseDataType } from "apis/http";
import { isAxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Permission } from "types/account";
import { queryKeys } from "react-query/querykey";
import { useSetPermission } from "./useSetPermission";

const ERROR_MESSAGE: { [key: string]: string } = {
  ALREADY_EXIST: "이미 그룹명이 존재합니다.",
  DEFAULT: "에러가 발생하였습니다.",
};

// 그룹명 생성 API
export function setGroupName(data: { name: string }) {
  return axios.post<any, { data: { data: { adminPermissionGroupId: string } } }>(`/permission/group`, data);
}

export const useAddGroup = () => {
  const { setPermission } = useSetPermission();
  const queryKey = [queryKeys.groupList];
  const queryClient = useQueryClient();
  const ref = useRef<{ name: string; permissions: Permission[] } | null>(null);
  const navigate = useNavigate();

  const addGroup = ({ name, permissions }: { name: string; permissions: Permission[] }) => {
    ref.current = { name, permissions };
    addGroupName({ name: ref.current.name });
  };

  const { mutate: addGroupName } = useMutation({
    mutationFn: (data: { name: string }) => setGroupName(data),
    onError: (error) => {
      console.error(error);
      if (isAxiosError<ResponseDataType>(error)) {
        const errorType = error.response?.data.code || "DEFAULT";
        alert(ERROR_MESSAGE[errorType]);
      }
    },
    onSuccess: (data) => {
      const { adminPermissionGroupId } = data?.data?.data;
      if (ref?.current?.permissions) {
        setPermission({ adminPermissionGroupId, permissions: ref.current.permissions });
        navigate("/account/admin/group");
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return { addGroup };
};

export default useAddGroup;
