import classNames from "classnames";
import Icon from "../../../shared/Icon";

import "./normalButton.scss";

export type Hierarchy =
  | "primary"
  | "primary_line"
  | "secondary_color"
  | "third_color"
  | "quaternary"
  | "secondary_gray"
  | "tertiary_color"
  | "tertiary_gray"
  | "link_color"
  | "link_gray"
  | "red"
  | "primary_line_white";
type Size = "xs" | "sm" | "md" | "lg" | "xl" | "txl";
type IconType = "false" | "leading" | "trailing";
type ButtonStyle = "round" | "box";
interface NormalButtonProps {
  title: string | undefined;
  icon?: IconType;
  iconType?: string;
  iconSize?: number;
  iconColor?: string;
  buttonStyle?: ButtonStyle;
  size?: Size;
  hierarchy?: Hierarchy | string;
  disable?: boolean;
  onClick?: Function;
  onKeyUp?: React.KeyboardEventHandler<HTMLElement>;
  width?: string;
  height?: string;
  buttonClassName?: string;
  className?: string;
}

/**
 * @size [버튼 크기] sm(6px 16px), md(10px 18px), lg(10px 20px), xl(12px 20px), 2xl(16px 32px)
 * @hierarchy [버튼 디자인 종류 9가지] = primary, primary_line, secondary_color, quaternary, secondary_gray, tertiary_color, tertiary_gray, link_color, link_gray
 * @icon [아이콘 유무 및 위치] = false(아이콘 없는 것), leading(text 앞에 + icon 있는 경우), trailing(text 뒤에 > icon 있는 경우)
 * @buttonStyle [버튼 border-radius] = round(둥근 것), box(네모난 것)
 * @title [버튼 안에 들어가는 text]
 * @disable [버튼 상태가 disable일 경우] boolean
 * @onClick [onClick function]
 */
const NormalButton = ({
  title,
  icon,
  iconType,
  iconSize,
  iconColor,
  buttonStyle,
  size,
  hierarchy,
  disable,
  onClick,
  width,
  height,
  buttonClassName,
  onKeyUp,
}: NormalButtonProps) => {
  return (
    <div
      className={classNames("normal_button", icon, buttonStyle, size, hierarchy, buttonClassName, {
        disable: disable,
      })}
      onClick={() => {
        if (onClick === undefined || disable) {
          return false;
        } else {
          onClick();
        }
      }}
      onKeyUp={onKeyUp}
      style={{ width: width, height: height }}
    >
      {/* {icon === "leading" && <div className={classNames("icon leading")}></div>} */}
      {icon === "leading" && (
        <Icon
          className={classNames("icon leading", { iconType })}
          icon={iconType === null || iconType === undefined || iconType === "" ? "plus" : iconType}
          size={iconSize === null || iconSize === undefined ? 20 : iconSize}
          color={iconColor === null || iconColor === undefined ? "#344054" : iconColor}
        />
      )}
      <p>{title}</p>
      {icon === "trailing" && (
        <Icon
          className={classNames("icon trailing", { iconType })}
          icon={iconType === null || iconType === undefined || iconType === "" ? "chevron-right" : iconType}
          size={iconSize === null || iconSize === undefined ? 20 : iconSize}
          color={iconColor === null || iconColor === undefined ? "#344054" : iconColor}
        />
      )}
    </div>
  );
};

export default NormalButton;
