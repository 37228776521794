import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Border, InputBox } from "components/common";
import { NormalButton } from "components/index";
import { useAssignedPermission, usePermission, useAddGroup, useUpdateGroup } from "hooks/account/admin";
import Checkbox from "./Checkbox";
import { Permission } from "types/account";
import "./AdminGroupSave.scss";

const AdminGroupSave = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, group } = location.state;
  const [name, setName] = useState(group?.name ? group?.name : "");

  const { selectedPermissions } = useAssignedPermission();
  const { permissionList, error } = usePermission();
  const { addGroup } = useAddGroup();
  const { updateGroup } = useUpdateGroup();
  const [checkedItems, setCheckedItems] = useState(new Set<string>()); // checkbox

  const submitDisabled = () => {
    const canSubmit = (type === "create" && name === "") || name === "";
    if (canSubmit) return true;
  };

  useEffect(() => {
    if (type === "create") return;
    const initializeCheckbox = selectedPermissions?.reduce((acc: any, item: Permission) => {
      if (item.canEdit) return acc.add(item.code);
    }, new Set());
    setCheckedItems(initializeCheckbox);
  }, [selectedPermissions]);

  const checkedHandler = (id: string, isChecked: boolean) => {
    if (isChecked) {
      checkedItems.add(id);
      setCheckedItems(() => checkedItems);
    } else if (!isChecked && checkedItems.has(id)) {
      checkedItems.delete(id);
      setCheckedItems(() => checkedItems);
    }
  };

  const handleSubmit = () => {
    if (name === "") {
      alert("그룹명을 입력하세요");
      return;
    }

    const permissions = Array.from(checkedItems).map((item) => ({ code: item, canEdit: true }));

    if (type === "create") {
      const canRegisterGroup = type === "create" && name !== "";
      if (canRegisterGroup) addGroup({ name, permissions });
    }
    if (type === "update") {
      if (name !== "") updateGroup({ adminPermissionGroupId: group.id, name, permissions });
    }
  };

  const emptyContent = (
    <tr>
      <td colSpan={2}>{error ? "권한을 가져오지 못했습니다." : "둥록된 그룹 권한이 없습니다."}</td>
    </tr>
  );

  const contentHTML = permissionList?.map((item: string) => (
    <tr key={item}>
      <td>{item}</td>
      <td>
        <Checkbox item={item} checkedHandler={checkedHandler} checked={checkedItems?.has(item)} />
      </td>
    </tr>
  ));

  return (
    <div className="content">
      <div className="content__inner admin-main admin-group-add">
        <h1 className="admin__title title_spacing">관리 그룹 생성</h1>
        <Border width={"100%"} height={1} />
        <div className="input-name-wrap">
          <h2 className="subtitle">관리 그룹명</h2>
          <div className="input-size">
            <InputBox
              type="text"
              name="name"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>

        <h2 className="subtitle admin-list-title">관리자 그룹 권한 설정</h2>
        <table className="admin__table auth-setting-list">
          <thead>
            <tr>
              <th>카테고리 항목</th>
              <th>편집 권한</th>
            </tr>
          </thead>
          <tbody>{permissionList && (permissionList?.length > 0 ? contentHTML : emptyContent)}</tbody>
        </table>
        <div className="btn_wrap admin-group-btn-wrap">
          <NormalButton
            title="취소"
            size="md"
            hierarchy="secondary_gray"
            buttonStyle="box"
            width="120px"
            onClick={() => navigate("/account/admin/group")}
          />
          <NormalButton
            title="저장"
            size="md"
            hierarchy="primary_fill"
            buttonStyle="box"
            width="120px"
            onClick={handleSubmit}
            disable={submitDisabled()}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminGroupSave;

// TODO: scss 리펙토링
