import { useRoutes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { path } from "shared/config/router/path";
import { AUTH_COOKIE } from "constants/index";
import { getCookie } from "shared/utils";
import { useEffect } from "react";
import { pathSetting } from "store/index";

function getLastPath(url: string) {
  var splitUrl: string[] = [];
  splitUrl = url.split("/");
  return splitUrl[2];
}

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(getLastPath(location.pathname));
    dispatch(pathSetting(String(getLastPath(location.pathname))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(pathSetting(String(getLastPath(location.pathname))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  let element = useRoutes(path);
  return element;
};
export default App;
