import { NormalButton } from "components/index";
import ModalPortal from "portals/ModalPortal";
import { useState, useEffect, useRef } from "react";
import { AlertModal, ConfirmModal } from "components/index";
import { PlayVideoList } from "components/demo";
import useDemoList from "hooks/demo/useDemoList";
import useUpdateList from "hooks/demo/useUpdateDemoList";
import { uuidv4 } from "shared/utils/uuid";
import { VideoItem } from "types/demo";

const MAX_ITEM_LENGTH = 10;
const PlayVideoPage = () => {
  const { links = [], existIds = [], refetch } = useDemoList("VIDEO");
  const [videoList, setVideoList] = useState<VideoItem[]>(links);
  const { updateList } = useUpdateList();

  useEffect(() => {
    refetch();
    setVideoList(links);
  }, [links]);

  const modalRef = useRef<HTMLDivElement>(null);
  const hasEmptyValue = videoList.find((item: VideoItem) => item.title === "" || item.url === "");
  const [confirmModal, setConfirmModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [modalText, setModalText] = useState<string[]>([""]);

  // TODO: sort 하기
  const checkExposeValidate = () => {
    const isMinExposed = videoList.filter((item: VideoItem) => item.isExposed).length === 0;
    if (isMinExposed) {
      setConfirmModal(true);
      setModalText(["플레이 영상 최소 노출", '플레이 영상이 최소 1개 이상 "노출" 설정되어야 저장 가능합니다.']);
      return false;
    }
    const isMaxExposed = videoList.filter((item: VideoItem) => item.isExposed).length > 5;
    if (isMaxExposed) {
      setConfirmModal(true);
      setModalText(["플레이 영상 최대 노출", '플레이 영상이 최대 5개 이상 "노출" 설정되어야 저장 가능합니다.']);

      return false;
    }
    return true;
  };

  const handleOnSave = () => {
    const isValidate = checkExposeValidate();
    if (!isValidate) return;

    const listData = videoList.map((item: VideoItem) => {
      if (existIds.includes(item.id)) {
        return {
          id: item.id,
          url: item.url,
          title: item.title,
          isExposed: item.isExposed,
          category: "VIDEO",
        };
      } else {
        return {
          url: item.url,
          title: item.title,
          isExposed: item.isExposed,
          category: "VIDEO",
        };
      }
    });
    updateList({ videoLinks: listData });
    setAlertModal(true);
    setModalText(["저장되었습니다."]);
  };

  const handleAddVideo = () => {
    if (videoList.length === MAX_ITEM_LENGTH) {
      setConfirmModal(true);
      setModalText(["플레이 영상 최대 개수", "플레이 영상은 최대 10개까지 저장이 가능합니다."]);
      return;
    }
    const newITem = { id: uuidv4(), url: "", title: "", category: "VIDEO", isExposed: false };
    setVideoList((prevList: VideoItem[]) => [...prevList, newITem]);
  };

  const handleDeleteVideo = (id: string) => {
    const nextVideoList = videoList.filter((item: VideoItem) => item.id !== id);
    setVideoList(nextVideoList);
    setAlertModal(true);
    setModalText(["삭제되었습니다."]);
  };

  const handleOnChange = ({
    type,
    value,
    id,
  }: {
    type: "url" | "title" | "isExposed";
    value: string | boolean;
    id: string;
  }) => {
    const selectedIndex = videoList.findIndex((item: VideoItem) => item.id === id);
    const nextVideoList: any = [...videoList];
    nextVideoList[selectedIndex][type] = value;
    setVideoList(nextVideoList);
  };

  return (
    <div className="demo">
      <h1 className="title">플레이 영상 관리</h1>
      <PlayVideoList videoList={videoList} handleDeleteVideo={handleDeleteVideo} handleOnChange={handleOnChange} />
      <div className="add-btn-wrapper">
        <NormalButton
          title="영상 추가"
          size="md"
          icon="leading"
          iconType="plus"
          iconColor="#7F56D9"
          hierarchy="primary_line"
          buttonStyle="box"
          width="135px"
          height="46px"
          buttonClassName="add-btn"
          onClick={handleAddVideo}
        />
      </div>
      <div className="save-btn-wrapper">
        <NormalButton
          title="저장"
          size="md"
          hierarchy="primary"
          buttonStyle="box"
          width="120px"
          onClick={handleOnSave}
          disable={!!hasEmptyValue}
        />
      </div>
      {alertModal && (
        <ModalPortal>
          <AlertModal modalRef={modalRef} setIsModalOpen={setAlertModal} text={modalText[0]} />
        </ModalPortal>
      )}

      {confirmModal && (
        <ModalPortal>
          <ConfirmModal
            modalRef={modalRef}
            type="info"
            title={modalText[0]}
            description={modalText[1]}
            confirmButton="확인"
            setIsModalOpen={setConfirmModal}
            confirmStyle="primary"
            handleOnConfirm={() => setConfirmModal(false)}
          />
        </ModalPortal>
      )}
    </div>
  );
};

export default PlayVideoPage;
