import { axios, ResponseDataType } from "apis/http";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { queryKeys } from "react-query/querykey";
import { useNavigate } from "react-router-dom";

type Param = {
  updateId: string;
  title: string;
  summary: string;
  content: string;
  thumbnailImageUrl?: string;
  assignedAt: number;
};

const ERROR_MESSAGE: { [key: string]: string } = {
  // ACCOUNT_ID_ALREADY_EXIST: "아이디가 존재합니다.",
  // NICKNAME_ALREADY_EXIST: "닉네임이 존재합니다.",
};

const putUpdate = (updateContent: Param) => {
  return axios.put<any, null>("/update", updateContent);
};

const useUpdateUpdate = () => {
  const navigate = useNavigate();
  const queryKey = [queryKeys.updateList];
  const queryClient = useQueryClient();

  const { mutate: updateUpdate } = useMutation({
    mutationFn: (updateContent: Param) => putUpdate(updateContent),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      navigate("/news/update");
    },
    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        const errorType = error.response?.data.code;
        console.error(errorType, error);
        if (errorType) alert(ERROR_MESSAGE[errorType]);
      }
    },
  });

  return { updateUpdate };
};

export default useUpdateUpdate;
