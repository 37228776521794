import { useNavigate } from "react-router-dom";
import { NormalButton } from "components/index";
import Pagination from "components/common/pagination/Pagination";
import usePagination from "components/common/pagination/usePagination";
import { useGroup } from "hooks/account/admin";
import { GroupList } from "types/account";

const PAGINATION = {
  pageRange: 10,
  btnRange: 10,
};

const AdminGroupList = () => {
  const navigate = useNavigate();
  const { groupList, error } = useGroup();
  const { page, currentList, setPage, totalPost } = usePagination({ pageRange: PAGINATION.pageRange, list: groupList });
  const contentHTML = currentList.map((group: GroupList) => (
    <tr key={group.id}>
      <td>{group.name}</td>
      <td className="id__column">-</td>
      <td>-</td>
      <td className="btn__wrap">
        <NormalButton
          title="관리"
          size="xs"
          hierarchy="primary_line"
          buttonStyle="box"
          onClick={() => {
            navigate("/account/admin/group/save", { state: { type: "update", group } });
          }}
        />
        <NormalButton title="삭제" size="xs" hierarchy="secondary_gray" buttonStyle="box" />
      </td>
    </tr>
  ));

  const emptyContent = (
    <tr>
      <td colSpan={4}>{error ? "관리 그룹을 가져오지 못했습니다." : "둥록된 관리그룹이 없습니다."}</td>
    </tr>
  );

  return (
    <div className="content">
      <div className="content__inner admin-main admin-group">
        <h1 className="admin__title">관리 그룹 관리</h1>
        <table className="admin__table">
          <thead>
            <tr>
              <th>관리 그룹명</th>
              <th>관리자</th>
              <th>등록된 관리자</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>{totalPost === 0 ? emptyContent : contentHTML}</tbody>
        </table>
        <div className="admin__bottom">
          <div className="paging-wrap">
            <Pagination
              page={page}
              setPage={setPage}
              totalPost={totalPost}
              btnRange={PAGINATION.btnRange}
              pageRange={PAGINATION.pageRange}
            />
          </div>
          <NormalButton
            title="관리 그룹 생성"
            size="md"
            hierarchy="primary"
            buttonStyle="box"
            width="130px"
            onClick={() => {
              navigate("/account/admin/group/save", { state: { type: "create" } });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminGroupList;
