import { useNavigate } from "react-router-dom";
import { NormalButton } from "components/index";
import "./UserInfoModal.scss";
import { setCookie } from "shared";
import { AUTH_COOKIE } from "constants/index";
import { useUser } from "hooks";

type UserInfoModalType = {
  modalRef: React.ForwardedRef<HTMLDivElement>;
  modalOutsideClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const UserInfoModal = ({ modalRef, modalOutsideClick }: UserInfoModalType) => {
  const navigate = useNavigate();
  const { user, clearUser } = useUser();

  const handleLogout = () => {
    setCookie(AUTH_COOKIE, "", null);
    clearUser();
    navigate("/login");
  };

  return (
    <div className="backdrop" ref={modalRef} onClick={(e: React.MouseEvent<HTMLDivElement>) => modalOutsideClick(e)}>
      <div className="user-modal">
        <h2 className="ellipsis">{`${user?.accountId} (${user?.nickname})`}</h2>
        <h3 className="ellipsis">{user?.adminPermissionGroup.name ? user?.adminPermissionGroup.name : "관리자"}</h3>
        <div className="btn-wrapper">
          <NormalButton
            title="관리자 정보"
            size="md"
            hierarchy="quaternary"
            buttonStyle="box"
            width="132px"
            height="40px"
            onClick={() => {
              console.log("click 관리자 정보");
            }}
          />
          <NormalButton
            title="로그아웃"
            size="md"
            hierarchy="secondary_gray"
            buttonStyle="box"
            width="132px"
            height="40px"
            onClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default UserInfoModal;
