import { InputBox, SelectBox } from "components/index";
import { ReactComponent as SearchIcon } from "assets/images/layer/search.svg";

type SearchType = {
  categoryList: { id: string | number; name: string }[];
  keywordType: {
    value: { id: string | number; name: string };
    onClick: any;
    setValue: React.Dispatch<React.SetStateAction<any>>;
  };
  setKeyword: React.Dispatch<React.SetStateAction<any>>;
};

const Search = ({ categoryList, keywordType, setKeyword }: SearchType) => {
  const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };
  return (
    <>
      <SelectBox name="category" data={categoryList} selectData={keywordType} classification />
      <InputBox onChange={handleKeywordChange} placeholder="검색어를 입력해주세요." icon type="text" />
      <SearchIcon />
    </>
  );
};

export default Search;
