import { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GNBState, RootState, nowPageSetting } from "store/index";
import { NormalButton } from "components/index";
import { zemiMenu } from "shared/config/router/path";

import classNames from "classnames";
import Icon from "shared/Icon";
import ModalPortal from "portals/ModalPortal";
import { UserInfoModal } from "components/index";
import "./MainHeader.scss";

const MainHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [infoOpen, setInfoOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const gnb: GNBState = useSelector((state: RootState) => state.gnb);

  const modalOutsideClick = (e: any) => {
    // console.log(modalRef.current, e.target);
    // 왜 반대로 될까? 일단은 모두 닫히게 해두었다.
    // 모두 닫히는 건 false인데 true로 주면 false값이 대입된다 뭐지..
    // if (modalRef.current !== e.target) {
    //   setInfoOpen(false);
    // } else {
    //   setInfoOpen(true);
    // }
    setInfoOpen(true);
  };

  return (
    <div className="main_header">
      <div
        className="logo"
        onClick={() => {
          navigate(`/management/contents/item`);
          dispatch(nowPageSetting(1));
        }}
      />
      <ul className="main_header_menu">
        {zemiMenu.map((data: any, idx: number) => (
          <li
            className={classNames({ on: gnb.nowPage === data.id })}
            onClick={() => {
              navigate(data.link);
              dispatch(nowPageSetting(data.id));
            }}
            key={idx}
          >
            {data.title}
          </li>
        ))}
      </ul>
      <div className="main_header_right">
        <NormalButton
          title="ZEMIVERSE"
          hierarchy="primary"
          onClick={() => {
            window.open(`https://zemiverse.com`, `_blank`);
          }}
          buttonStyle="box"
          size="sm"
        />
        <NormalButton
          title="Studio"
          hierarchy="primary"
          onClick={() => {
            window.open(`https://studio.zemiverse.com`, `_blank`);
          }}
          buttonStyle="box"
          size="sm"
        />
        <div
          className="main_user_info"
          onClick={() => {
            setInfoOpen((prevOpen) => !prevOpen);
          }}
        >
          <p>관리자</p>
          <Icon
            className={infoOpen ? "chevron-down" : "chevron-right"}
            icon={infoOpen ? "chevron-down" : "chevron-right"}
            color="#667085"
            size={20}
          />
          {infoOpen && (
            <ModalPortal>
              <UserInfoModal modalRef={modalRef} modalOutsideClick={modalOutsideClick} />
            </ModalPortal>
          )}
        </div>
      </div>
    </div>
  );
};
export default MainHeader;
