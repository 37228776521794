import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";

// 최신 비밀번호 가져오기
function getDemoPassword() {
  return axios.get<any>(`/demo/password`);
}

const usePassword = () => {
  let queryKey = [queryKeys.demo, "password"];

  const { data: password = "", error } = useQuery({
    queryKey,
    queryFn: () => getDemoPassword(),
    select: (data) => data.data.data,
    retry: false,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("데모용 최신 패스워드를 가져오는데 에러가 발생하였습니다.");
  }

  return { password, error };
};

export default usePassword;
