import { useEffect } from "react";

const useDebounce = (value: string, delay: number, func: any) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      func();
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value]);
};

export default useDebounce;
