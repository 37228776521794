import { axios, ResponseDataType } from "apis/http";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";

type UseDeleteList = {
  refetchList: () => any;
  setAlertModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalText: React.Dispatch<React.SetStateAction<string[]>>;
};

const deleteLinkId = (linkId: { linkId: string }) => {
  return axios.delete<any, null>("/demo/link", {
    headers: {
      "Content-Type": `application/json`,
    },
    data: { ...linkId },
  });
};

const useDeleteList = ({ refetchList, setAlertModal, setConfirmModal, setModalText }: UseDeleteList) => {
  const { mutate: deleteLink } = useMutation({
    mutationFn: (linkId: { linkId: string }) => deleteLinkId(linkId),
    onSuccess: () => {
      setAlertModal(true);
      setModalText(["삭제 완료 되었습니다"]);
      refetchList();
    },
    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        setConfirmModal(true);
        setModalText(["삭제 실패", "파일을 삭제하지 못했습니다."]);
        console.error(error);
      }
    },
  });

  return { deleteLink };
};

export default useDeleteList;
