import "../AlertModal/alertModal.scss";
import LoadingSpinner from "components/common/Loading/LoadingSpinner";

type LoadingModalType = {
  text: string;
};

const LoadingModal = ({ text }: LoadingModalType) => {
  return (
    <div className="backdrop dark-backdrop">
      <div
        className="demo-alert-modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <LoadingSpinner />
        <span>{text}</span>
      </div>
    </div>
  );
};

export default LoadingModal;
