import { axios, ResponseDataType } from "apis/http";
import { isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { setCookie } from "shared";
import { AUTH_COOKIE, AUTH_EXPIRE_DAY } from "constants/index";

type ResponseType = {
  data: {
    success: boolean;
    data: string;
  };
};

// 로그인
export function signIn(data: { accountId: string; password: string }) {
  return axios.post<any, ResponseType>("/adminUser/signIn", data);
}

const ERROR_MESSAGE: { [key: string]: string } = {
  NOT_EXIST: "아이디가 존재하지 않습니다.",
  INVALID_PASSWORD: "패스워드가 일치하지 않습니다.",
  EMPTY_VALUE: "아이디 또는 비밀번호를 입력하세요.",
  DEFAULT: "에러가 발생하였습니다.",
};

const useSignIn = () => {
  const navigate = useNavigate();
  const { mutate } = useMutation({
    mutationFn: (data: { accountId: string; password: string }) => signIn(data),

    onSuccess: ({ data }: ResponseType) => {
      const token = data.data;
      setCookie(AUTH_COOKIE, token, AUTH_EXPIRE_DAY);
      navigate("/");
    },

    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        const errorType = error.response?.data.code || "DEFAULT";
        setCookie(AUTH_COOKIE, "", null);
        alert(ERROR_MESSAGE[errorType]);
      }
    },
  });

  return { mutate };
};

export default useSignIn;
