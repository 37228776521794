import { axios, ResponseDataType } from "apis/http";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { queryKeys } from "react-query/querykey";
import { useNavigate } from "react-router-dom";

export type UpdateAdmin = {
  adminId: string;
  password?: string;
  name?: string;
  nickname?: string;
  department?: string;
  adminPermissionGroupId?: string;
};

const ERROR_MESSAGE: { [key: string]: string } = {
  ACCOUNT_ID_ALREADY_EXIST: "아이디가 존재합니다.",
  NICKNAME_ALREADY_EXIST: "닉네임이 존재합니다.",
};

const putAdmin = (admin: UpdateAdmin) => {
  return axios.put<UpdateAdmin, null>("/adminUser", admin);
};

const useUpdateAdmin = () => {
  const navigate = useNavigate();
  const queryKey = [queryKeys.adminList];
  const queryClient = useQueryClient();

  const { mutate: updateAdmin } = useMutation({
    mutationFn: (admin: UpdateAdmin) => putAdmin(admin),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      navigate("/account/admin");
    },
    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        const errorType = error.response?.data.code;
        console.error(errorType, error);
        if (errorType) alert(ERROR_MESSAGE[errorType]);
      }
    },
  });

  return { updateAdmin };
};

export default useUpdateAdmin;
