import { axios, ResponseDataType } from "apis/http";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { queryKeys } from "react-query/querykey";

type Param = {
  title: string;
  summary: string;
  content: string;
  thumbnailImageUrl: string;
  assignedAt: number;
};

const ERROR_MESSAGE: { [key: string]: string } = {
  // ACCOUNT_ID_ALREADY_EXIST: "아이디가 존재합니다.",
};

const postUpdate = (content: Param) => {
  return axios.post<any, null>("/update", content);
};

const useAddUpdate = () => {
  const queryKey = [queryKeys.updateList];
  const queryClient = useQueryClient();

  const { mutate: addUpdate } = useMutation({
    mutationFn: (content: Param) => postUpdate(content),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        console.error("공지사항을 등록하는데 오류가 발생하였습니다.", error);
        const errorType = error.response?.data.code;
        if (errorType) alert(ERROR_MESSAGE[errorType]);
      }
    },
  });

  return { addUpdate };
};

export default useAddUpdate;
