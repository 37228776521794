import { useEffect, useState } from "react";
// TODO: type 지정하기

// const useSelect = (initialValue: {// id: string; name: string } | undefined) => {
const useSelect = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);
  const _handleChange = (event: any) => {
    setValue(event);
  };

  return {
    value,
    setValue,
    onClick: _handleChange,
  };
};

export default useSelect;
