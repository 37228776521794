const Create = () => {
  return (
    <div className="content create">
      <div className="content__inner">
        <p>크리에이터 관리</p>
      </div>
    </div>
  );
};

export default Create;
