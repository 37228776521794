import { axios } from "apis/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Permission } from "types/account";
import { queryKeys } from "react-query/querykey";
import { useNavigate } from "react-router-dom";

export type AssignGroupAuth = { groupId: string; permissions: Permission[] };
type SetGroupPermission = { adminPermissionGroupId: string; permissions: Permission[] };

// 그룹에 권한 지정 API
export function setGroupPermission(data: SetGroupPermission) {
  return axios.post<any, any>(`/permission/group/assign`, data);
}

export const useSetPermission = () => {
  const queryKey = [queryKeys.groupList];
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate: setPermission } = useMutation({
    mutationFn: (data: SetGroupPermission) => setGroupPermission(data),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      navigate("/account/admin/group");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return { setPermission };
};

export default useSetPermission;
