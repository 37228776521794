import { axios, ResponseDataType } from "apis/http";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { queryKeys } from "react-query/querykey";

type UseUpdatePassword = {
  setSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsValidModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalInfo: React.Dispatch<React.SetStateAction<string[]>>;
};

const patchPassword = (password: { password: string }) => {
  return axios.patch<any, null>("/demo/password", password);
};

const useUpdatePassword = ({ setSuccessModal, setIsValidModalOpen, setModalInfo }: UseUpdatePassword) => {
  const queryKey = [queryKeys.demo, "password"];
  const queryClient = useQueryClient();

  const { mutate: updatePassword } = useMutation({
    mutationFn: (password: { password: string }) => patchPassword(password),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      setSuccessModal(true);
    },
    onError: (error) => {
      if (isAxiosError<ResponseDataType>(error)) {
        const errorType = error.response?.data.code;
        console.error(errorType, error);
        setModalInfo(["서버 에러", "비밀번호를 업데이트하지 못했습니다."]);
        setIsValidModalOpen(true);
      }
    },
  });

  return { updatePassword };
};

export default useUpdatePassword;
