const Template = () => {
  return (
    <div className="content template">
      <div className="content__inner">
        <p>Template</p>
      </div>
    </div>
  );
};

export default Template;
