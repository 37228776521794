import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";
import { GroupList } from "types/account";

// 관리 그룹 목록
export function getAdminGroup() {
  return axios.get<{ data: { list: GroupList[] } }>("/permission/group");
}

const useGroup = () => {
  const queryKey = [queryKeys.groupList];

  const { data: groupList, error } = useQuery({
    queryKey,
    queryFn: () => getAdminGroup(),
    select: (data) => data.data.data.list,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("관리자 계정 리스트를 가져오는데 에러가 발생하였습니다.");
  }

  return { groupList, error };
};

export default useGroup;
