import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";
import { Update } from "types/board";

type Param = {
  page: number;
  limit: number;
  year: number;
  keyword: string;
};

type GetUpdate = {
  data: { list: Update[]; totalCount: number };
};

// 업데이트 게시판 목록
function getUpdateList(page: number, limit: number, queryString: string) {
  return axios.get<GetUpdate>(`/update?page=${page}&limit=${limit}${queryString}`);
}

const getQueryString = (year: number, keyword: string) => {
  if (!keyword) return "";
  return `&year=${year}&search=${keyword}`;
};

const useUpdate = ({ page, limit, year, keyword }: Param) => {
  let queryKey = [queryKeys.updateList, page];
  const queryString = getQueryString(year, keyword);

  if (keyword) {
    queryKey = [queryKeys.updateList, page, `${year}=${keyword}`];
  }

  const { data, error, refetch } = useQuery({
    queryKey,
    queryFn: () => getUpdateList(page, limit, queryString),
    enabled: false,
  });
  if (isAxiosError<ResponseDataType>(error)) {
    console.error("관리자 계정 리스트를 가져오는데 에러가 발생하였습니다.");
  }
  const updateList = data?.data.data.list;
  const totalPost = data?.data.data.totalCount;

  return { updateList, totalPost, error, refetch };
};

export default useUpdate;
