import classNames from "classnames";
import { Hierarchy } from "components/common/NormalButton/NormalButton";
import NormalButton from "components/common/NormalButton/NormalButton";

import "../modal.scss";

type ConfirmModalType = "success" | "warning" | "danger" | "info";

interface ConfirmModalProps {
  type?: ConfirmModalType | string | undefined;
  title: string | undefined;
  description?: string;
  cancel: string;
  confirmButton: string | undefined;
  confirmStyle?: Hierarchy | string | undefined;
}

const ConfirmModal = ({
  modalRef,
  setIsModalOpen,
  type,
  title,
  description,
  cancel,
  confirmButton,
  confirmStyle,
  handleOnConfirm,
}: any) => {
  return (
    <div className="dim dark-backdrop" ref={modalRef} onClick={() => setIsModalOpen(false)}>
      <div className={classNames("modal confirm", type)}>
        <div className="icon_box">
          <div className="icon" />
        </div>

        <p className="title">{title}</p>
        <p className="description">{description}</p>

        <div className="button_box">
          <NormalButton
            title={cancel}
            size="lg"
            buttonStyle="box"
            hierarchy="secondary_gray"
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
          <NormalButton
            title={confirmButton}
            size="lg"
            buttonStyle="box"
            hierarchy={confirmStyle ? confirmStyle : "secondary_gray"}
            onClick={() => {
              handleOnConfirm();
              setIsModalOpen(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
