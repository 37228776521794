import { useState } from "react";

import classNames from "classnames";
import Pagination from "react-js-pagination";
import { useLocation, useNavigate } from "react-router-dom";

import "./faq.scss";
import { PageTop } from "components/board";
import { NormalButton } from "components/index";

const FaqData = [
  {
    id: 0,
    title: "ZEMI Studio FAQ 질문 1",
    description:
      "ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ",
  },
  {
    id: 0,
    title: "ZEMI Studio FAQ 질문 2",
    description:
      "ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ",
  },
  {
    id: 0,
    title: "ZEMI Studio FAQ 질문 3",
    description:
      "ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ZEMI Studio FAQ 답변 내용이 출력 됩니다. ",
  },
];

export interface FaqDataProps {
  data: any;
}

const Faq = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [category, setCategory] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const BoardList = ({ data }: FaqDataProps) => {
    const [open, setOpen] = useState(false);

    const [edit, setEdit] = useState(false);
    const [title, setTitle] = useState(data.title);
    const [description, setDescription] = useState(data.description);

    return (
      <li className={classNames("board__list", { on: open })}>
        <div
          className="list_top"
          onClick={() => {
            if (!edit) {
              setOpen(!open);
            }
          }}
        >
          {edit ? (
            <input
              className={classNames("title", { on: open })}
              type="text"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(e.target.value);
              }}
            />
          ) : (
            <p className={classNames("title", { on: open })}>{title}</p>
          )}
          <div
            className="right"
            onClick={(e) => {
              //
              e.stopPropagation();
            }}
          >
            <NormalButton
              title={edit ? "저장" : "수정"}
              hierarchy="primary_line"
              buttonStyle="box"
              size="xs"
              onClick={() => {
                if (edit) {
                  setOpen(false);
                  setEdit(false);
                } else {
                  setOpen(true);
                  setEdit(true);
                }
              }}
            />
            <NormalButton title="삭제" hierarchy="secondary_gray" buttonStyle="box" size="xs" onClick={() => {}} />
          </div>
        </div>
        {edit ? (
          <textarea
            className="description"
            value={description}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setDescription(e.target.value);
            }}
          />
        ) : (
          <p className="description">{description}</p>
        )}
      </li>
    );
  };

  return (
    <div className="faq">
      <div className="faq__inner">
        <PageTop title="FAQ" />
        <ul className="board">
          {FaqData.map((data, index) => (
            <BoardList data={data} key={index} />
          ))}
          <div className="board__bottom">
            {FaqData.length <= 0 || FaqData.length <= 13 ? null : (
              <>
                <Pagination
                  activePage={page}
                  itemsCountPerPage={10}
                  totalItemsCount={450}
                  pageRangeDisplayed={10}
                  prevPageText={"‹"}
                  nextPageText={"›"}
                  onChange={handlePageChange}
                  innerClass="board__pagination"
                  activeClass="selected"
                  itemClassFirst="normal_button box sm secondary_gray"
                  itemClassPrev="normal_button box sm secondary_gray"
                  itemClassNext="normal_button box sm secondary_gray"
                  itemClassLast="normal_button box sm secondary_gray"
                />
                <div className="mobile_pagination">
                  <NormalButton
                    title="<"
                    size="sm"
                    hierarchy="secondary_gray"
                    buttonStyle="box"
                    onClick={() => {
                      handlePageChange(page - 1);
                    }}
                  />
                  <p className="page">
                    Page {page} of {450 / 10}
                  </p>
                  <NormalButton
                    title=">"
                    size="sm"
                    hierarchy="secondary_gray"
                    buttonStyle="box"
                    onClick={() => {
                      handlePageChange(page + 1);
                    }}
                  />
                </div>
              </>
            )}
            <NormalButton
              title="글쓰기"
              size="md"
              hierarchy="primary"
              icon="false"
              buttonStyle="box"
              onClick={() => {
                navigate(`/customer/faq/write`);
              }}
            />
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Faq;
